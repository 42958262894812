import make_request from "../utils";


class DingtalkUserAPI{
    static async list({filters}) {
        return make_request(`/api/dingtalk/user/`, filters)
    }

    static async get({id}){
        return make_request(`/api/dingtalk/user/${id}/`, null, {method: 'GET'})
    }

    static async sync_finereport_user_async({id}){
        return make_request(`/api/dingtalk/user/${id}/sync_finereport_user_async/`, null, )
    }

    static async sync_finereport_user({id}){
        return make_request(`/api/dingtalk/user/${id}/sync_finereport_user/`, null, )
    }
}

export default DingtalkUserAPI
