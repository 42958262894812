import React, {useEffect, useState} from "react";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import ProductAPI from "../../api/ProductAPI";
import config from 'react-global-configuration';
import ChemicalAPI from "../../api/ChemicalAPI";
import {useDispatch} from "react-redux";
import {endLoading, startLoading} from "../../redux/globalLoading/globalLoadingSlice";
import {GenAutoComplete} from "./base/GenAutoComplete";
import FastTextField from "./base/FastTextField";


const purity_options = config.get('purity_options', [])
const pharm_prefixes = config.get('pharm_prefixes', [])


function isPharmCatNo(catNo){
    return pharm_prefixes.some(prefix=>catNo.startsWith(prefix))
}


export function CatalogField({formik, label}){
    return (
        <GenAutoComplete
            label={label}
            getOptionLabel={option=>option.id?option.prefix + '|' + option.cn_name:''}
            value={formik.values.catalog||{}}
            api={ProductAPI.get_simple_catalogs}
            onChange={(e, value)=>{
                formik.setFieldValue('catalog', value)
                formik.setFieldValue('handler', value?.handler||"")
                formik.setFieldValue('cls_code', value?.cls_code||"")
            }}
        />
    )
}

export function CatNoField({formik, fullWidth, forCreate, ...otherProps}){

    const onGetCatNo =()=>{
        const cls_id = formik.values.catalog?.id
        if(!cls_id){return}
        ProductAPI.get_valid_cat_no({cls_id})
            .then(
                data=> {
                    if(!formik.values.cat_no){
                        formik.setFieldValue('cat_no', data || [])
                    }
                }
            )
    }
    return (
        <Grid container direction={"row"} alignItems={"center"} spacing={1}>
            <Grid item>
                <TextField {...otherProps} disabled={!forCreate}/>
            </Grid>
            <Grid item>
                <Button onClick={onGetCatNo} variant={"contained"} color={"primary"} disabled={!forCreate}>生成货号</Button>
            </Grid>
        </Grid>
    )
}


export function ClsCode({formik, forCreate, ...otherProps}){
    useEffect(()=>{
        if(typeof formik.values?.cat_no?.startsWith !== 'function'){return}
        if(!formik.values.cls_code && isPharmCatNo(formik.values.cat_no)){
            formik.setFieldValue('cls_code', '03021005')
        }
    }, [formik.values.cat_no])
    return (
        <TextField {...otherProps}/>
    )
}


export function StorageCondition({formik, label, name, value}){
    const [storage_conditions, setStorageConditions] = useState([])

    const handleChange = (event, values) =>{
        formik.setFieldValue(name, values)
    }

    const handleBlur = (event) =>{
        const {defaultValue} = event.target
        formik.setFieldValue(name, defaultValue)
    }

    useEffect(()=>{
        const storage_conditions = config.get('storage_conditions', [])
        setStorageConditions(storage_conditions)
    }, [])

    return (
        <Autocomplete
            options={storage_conditions}
            freeSolo
            value={value}
            getOptionLabel={(option) => option}
            renderInput={(params) =>
                <TextField {...params} label={label} variant="outlined" />
            }
            onChange={handleChange}
            onBlur={handleBlur}
        />
    )
}


export function CASField({formik, width, ...otherProps}){
    const dispatch = useDispatch()

    const handleSearch=()=>{
        const {cas} = formik.values
        dispatch(startLoading())
        ChemicalAPI.get_moltext_by_cas(cas).then(data=>{
            if(!data){return}
            formik.setFieldValue('mol_text', data?.moltext)
        }).catch(()=>{}).finally(()=>{
            dispatch(endLoading())
        })
    }
    const disableBtn = !formik.values?.cas || !!formik.values?.mol_text || formik.values?.cas === 'N/A'

    return (
        <Grid container direction="row" alignItems={"center"} spacing={2}>
            <Grid item>
                <FastTextField {...otherProps} fullWidth={true}/>
            </Grid>
            <Grid item>
                <Button
                    disabled={disableBtn}
                    onClick={handleSearch}
                >试试手气</Button>
            </Grid>
        </Grid>
    )
}


export function PurityField({formik, name, label, value}){

    const handleChange = (event, values) =>{
        formik.setFieldValue(name, values)
    }

    const handleBlur = (event) =>{
        const {defaultValue} = event.target
        formik.setFieldValue(name, defaultValue)
    }

    return (
        <Autocomplete
            options={purity_options}
            style={{display: 'inline-flex',}}
            freeSolo
            fullWidth
            value={value}
            getOptionLabel={(option) => option}
            renderInput={(params) =>
                <TextField {...params} label={label} variant="outlined" />
            }
            onChange={handleChange}
            onBlur={handleBlur}
        />)
}