import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import TextSearchField from "../../../components/GenTable/searchFields/GTToolbarSearchField";
import GenTable from "../../../components/GenTable/GenTable";
import DingtalkUserAPI from "../../../api/dingtalk/DingtalkUserAPI";
import SyncFinereportUserBtn from "./actions/SyncFinereportUserBtn";

DingtalkUserTable.propTypes = {
    
};

const columns = [
    {data: "id", title: "ID"},
    {data: "userid", title: "UserID"},
    {data: "hired_date", title: "入职日期"},
    {data: "position", title: "岗位"},
    {data: "name", title: "名称"},
    {data: "mobile", title: "手机号码"},
    {data: "email", title: "邮箱"},
    {data: "active", title: "是否启用"},
    {data: "is_admin", title: "是否管理员", cellType: "checkbox"},
    {data: "is_boss", title: "是否老板", cellType: "checkbox"},
    {data: "is_hide", title: "是否号码隐藏", cellType: "checkbox"},
    {data: "avatar", title: "头像"},
    {data: "created_at", title: "新建日期"},
    {data: "modified_at", title: "修改日期"},
]

function DingtalkUserTable(props) {
    const hotRef = useRef(null);

    return (
        <GenTable
            hotRef={hotRef}
            id={'dingtalk_user_table'}
            hotSettings={{
                readOnly: true,
                stretchH: "all",
            }}
            columns={columns}
            api={DingtalkUserAPI.list}
            actions={[
                SyncFinereportUserBtn,
                TextSearchField('name', '名称', 'contains', '',{sx:{width: '100px'}}),
                TextSearchField('mobile', '手机号码', 'contains', '',{sx:{width: '100px'}}),
            ]}
            perPage={100}
        />
    );
}

export default DingtalkUserTable;