import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Grid, TextField} from "@mui/material";
import ButtonWithModel from "../../base/ButtonWithModel";
import GTAdvanceSearchForm from "../GTAdvanceSearchForm/GTAdvanceSearchForm";
import ActionWrapper from "./ActionWrapper";


GtToolbar.propTypes = {
    table: PropTypes.object,
};

function GtToolbar({table, hotRef}) {
    const {filter} = table

    const handleKeywordChange = ({target:{value}}, ) => {
        filter.setKeyword(value)
    }

    return (
        <Grid container direction="row" spacing={1} alignItems={"center"}>
            <Grid item>
                <TextField
                    variant={"outlined"}
                    onChange={handleKeywordChange}
                    onKeyDown={(e)=>{
                        if (e.key === 'Enter') {
                            table.refreshData()
                            e.preventDefault();
                        }
                    }}
                />
            </Grid>
            <Grid item>
                <Button onClick={()=>table.refreshData()}>Search</Button>
            </Grid>
            <Grid item>
                <ButtonWithModel
                    label="ADV.Search"
                    title="Advance Search"
                >
                    <GTAdvanceSearchForm
                        table={table}
                    />
                </ButtonWithModel>
            </Grid>
            <Divider />
            {table.actions?.map((Component, index)=>(
                <ActionWrapper key={index} Component={Component} table={table} hotRef={hotRef}/>
            ))}
        </Grid>
    );
}

export default GtToolbar;