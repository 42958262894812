import GenTable from "../../../components/GenTable/GenTable";
import SupplierQuotationAPI from "../../../api/SupplierQuotationAPI";
import React from "react";


const columns = [
    {data:'id', title: 'ID', width:40, searchable: true, type:'text'},
    {data:'name', title: '供应商名称', width:70, searchable: true, type:'text'},
    {data:'address', title: '地址', width:40, searchable: true, type:'text'},
    {data:'bank', title: '开户银行', width:40, searchable: true, type:'text'},
    {data:'person', title: '联系人', width:40, searchable: true, type:'text'},
    {data:'account', title: '银行账号', width:100, searchable: true, type:'text'},
    {data:'phone', title: '电话', width:40, searchable: true, type:'text'},
    {data:'created_by', title: '创建人', width:40, searchable: true, type:'text'},
    {data:'created_at', title: '创建日期', width:70, searchable: true, type:'date'},
    {data:'modified_by', title: '修改人', width:40, searchable: true, type:'text'},
    {data:'modified_at', title: '修改日期', width:70, searchable: true, type:'date'},
]

SupplierTable.propTypes = {

};

// TODO actions, DetailDialog部分
function SupplierTable(){
    return (
        <GenTable
            id={'supplier_table'}
            hotSettings={{
                // rowHeights: 80,
                readOnly: true,
                stretchH: "all",
            }}
            columns={columns}
            api={SupplierQuotationAPI.get_supplier_list}

        />
    );
}

export default SupplierTable;