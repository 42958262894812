import React, {useEffect} from 'react';
import Header from "./Header";
import SideMenu from "./SideMenu";
import MainContent from "./MainContent";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import DefaultSpinner from "../../components/base/DefaultSpinner";
import GlobalSnackbar from "../../components/base/GlobalSnackbar";
import {addTab, initialTabs} from "../../redux/tab/tabSlice";
import {getRouteByUrl} from "../../routes";

Home.propTypes = {

};

function Home() {
    const tabs = useSelector((state) => state.tab.tabs)
    const dispatch = useDispatch()
    const location = useLocation()
    const loading = useSelector(({globalLoading}) =>globalLoading.loading)

    useEffect(()=>{
        dispatch(initialTabs({}))
        const {hash} = location
        if(!hash) return
        const url = hash.replace(/^#/, '')
        if(!url){return}
        const [m, route] = getRouteByUrl(url)
        if(!m){return}
        if(!tabs?.length){
            dispatch(addTab({url, title: route.title}))
        }
    }, [])

    return (
        <React.Fragment>
            <Header />
            <DefaultSpinner open={loading} />
            <GlobalSnackbar />
            <SideMenu />
            <MainContent />
        </React.Fragment>
    );
}

export default Home;