import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import GenTable from "../../../components/GenTable/GenTable";
import TextSearchField from "../../../components/GenTable/searchFields/GTToolbarSearchField";
import ExpressAPI from "../../../api/ExpressAPI";
import ExpressDialog from "../dialog/ExpressDialog";
import {NestedJsonRenderer} from "./renderers";

ExpressTable.propTypes = {
    
};

const columns = [
    {data: 'id', title: 'ID', width:30, searchable: true, type: 'numeric', },
    {data: 'express_code', title: '快递单号', width:150, searchable: true, },
    {data: 'voucher_code', title: '关联单号', width:150, searchable: true, },
    {data: 'receive_date', title: '签收日期', width:150, searchable: true, type: 'date', },
    {title: '最新状态编码', width:150, searchable: false, renderer: NestedJsonRenderer('latest_route.opCode')},
    {title: '最新状态信息', width:150, searchable: false, renderer: NestedJsonRenderer('latest_route.remark')},
    {title: '最新状态时间', width:150, searchable: false, renderer: NestedJsonRenderer('latest_route.acceptTime')},
    {title: '最新状态地址', width:150, searchable: false, renderer: NestedJsonRenderer('latest_route.acceptAddress')},
    {data: 'order_no', title: '申请快递订单号', width:150, searchable: true, },
    {data: 'created_at', title: '新增日期', width:150, searchable: true, type: 'date', dateFormat: 'YYYY-MM-DD', correctFormat: true,},
    {data: 'modified_at', title: '更新日期', width:150, searchable: true, type: 'date', dateFormat: 'YYYY-MM-DD', correctFormat: true,},
]

function ExpressTable(props) {
    const hotRef = useRef()
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})

    const handleRecordDbClick = (data)=>{
        setData(data)
        setOpen(true)
    }

    return (
        <React.Fragment>
            <GenTable
                hotRef={hotRef}
                id={'express_table'}
                hotSettings={{
                    rowHeights: 40,
                    readOnly: true,
                }}
                columns={columns}
                api={ExpressAPI.list}
                actions={[
                    TextSearchField('express_code', '快递单号', 'contains', '', {sx:{width: '100px'}}),
                    TextSearchField('voucher_code', '关联单号', 'contains', '', {sx:{width: '100px'}}),
                ]}
                onRecordDbClick={handleRecordDbClick}
                perPage={50}
            />
            <ExpressDialog open={open} handleClose={()=>setOpen(false)} data={data}/>
        </React.Fragment>

    );
}

export default ExpressTable;