import React from 'react';
import DraggableDialog from "../../../../components/base/DraggableDialog";
import MenuItemForm from "./MenuItemForm";

function MenuItemEditDialog(
    {
        open = false,
        handleClose = ()=>{},
        id = null,
        ...props
    }
) {
    return (
        <DraggableDialog
            title={"产品分类信息"}
            handleClose={handleClose} open={open}
            maxWidth={'sm'}
        >
            <MenuItemForm id={id}/>
        </DraggableDialog>
    );
}

export default MenuItemEditDialog;