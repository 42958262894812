import React from 'react';
import {Backdrop, CircularProgress, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import denseTheme from "../../theme/denseTheme";


const theme = createTheme(denseTheme,{
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    zIndex: denseTheme.zIndex.modal + 1,
                    color: '#fff',
                }
            }
        }
    }
})


DefaultSpinner.propTypes = {
};

function DefaultSpinner(props) {
    return (
        <ThemeProvider theme={theme}>
            <Backdrop
                open={props.open} onClick={()=>{}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </ThemeProvider>
    );
}

export default DefaultSpinner;