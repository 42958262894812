import React from 'react';
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Grid,
    Typography
} from '@mui/material'
import fields, {hidden_fields} from "./fields";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import FastTextField from "../../../../components/inputFields/base/FastTextField";
import FormPart from "../../../../components/base/FormPart";
import ProductTagEditor from "./ProductTagEditor";


TextPart.propTypes = {
    formik: PropTypes.object,
    forCreate: PropTypes.bool,
};


function TextPart({formik, forCreate}) {
    return (
        <React.Fragment>
            <FormPart formik={formik} forCreate={forCreate} fields={fields}/>
            <ProductTagEditor formik={formik} />
            <Grid container direction={"row"} spacing={1}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>更多信息</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {hidden_fields.map((value, index) => {
                                    const {data, label, width, component, ...otherProps} = value
                                    const Component = component || FastTextField
                                    return (
                                        <Grid item xs={width || 12} key={index}>
                                            <Component
                                                id={data}
                                                name={data}
                                                label={label}
                                                value={formik.values[data]||''}
                                                onChange={formik.handleChange}
                                                formik={formik}
                                                forCreate={forCreate}
                                                error={formik.touched[value.data] && Boolean(formik.errors[value.data])}
                                                helperText={formik.touched[value.data] && formik.errors[value.data]}
                                                fullWidth
                                                {...otherProps }
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default TextPart;