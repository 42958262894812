import make_request from "../utils";

class GroupManageAPI {
    static async get(id) {
        return make_request(`/api/auth/group/${id}/`, null, {method: 'GET'})
    }
    static async list({filters={}}) {
        return make_request(`/api/auth/group/`, filters)
    }
    static async get_tree() {

    }
}

export default GroupManageAPI
