import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardActions,
    Grid,
    IconButton, List, ListItem,
    ListItemText,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AutoScrollCardContent from "./AutoScrollCardContent";

UserList.propTypes = {
    
};

function UserList({menu}) {

    useEffect(() => {
        menu.refreshUserData()
    }, []);
    const refresh = () => {
        menu.refreshUserData()
    }
    return (
        <Card sx={{height: '100%'}}>
            <Grid container sx={{height: '100%', width: '100%'}}>
                <Grid item xs={12}>
                    <CardActions>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant="h5">用户</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Grid item>
                                        <TextField label={"关键字"}/>
                                    </Grid>
                                    <Grid>
                                        <IconButton onClick={refresh}><SearchIcon/></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
                <Grid item xs={12} sx={{height: 'stretch', overflowY: 'auto'}}>
                    <AutoScrollCardContent>
                        <List>
                            {menu?.users?.map(item=>(
                                <ListItem key={item?.id}>
                                    <ListItemText primary={item?.username}/>
                                    <Switch
                                        edge="end"
                                        checked={menu?.userHasPerm(item?.id)}
                                        onChange={(e)=> {
                                            menu?.toggleUserPerm(item?.id, e.target.checked?1:0)
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AutoScrollCardContent>
                </Grid>
            </Grid>
        </Card>
    );
}

export default UserList;