import React from 'react';
import GenAutoComplete from "../../../../components/inputFields/base/GenAutoComplete";
import MenuManageAPI from "../../../../api/sys/MenuManageAPI";

export function MenuItemParent(
    {
        formik, label, name
    }) {

    const handleChange = (e, value)=>{
        formik.setFieldValue(name, value)
        formik.setFieldValue('parent_id', value?.id||null)
    }

    const api_wrapper = async ({keyword})=>{
        return MenuManageAPI.list({
            filters: {per_page: 5},
            search: keyword
        }).then(data=>data?.data || [])
    }

    return (
        <GenAutoComplete
            label={label}
            getOptionLabel={option=>option.id?`${option.title}|${option.url}`:''}
            value={formik.values.parent||{}}
            api={api_wrapper}
            onChange={handleChange}
        />
    );
}
