import React from 'react';
import PropTypes from 'prop-types';
import {Box, Checkbox, FormControlLabel, IconButton, Stack} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import EditIcon from '@mui/icons-material/Edit';


SupplierRenderer.propTypes = {
    columnData: {
        item: PropTypes.object,
        openEditDialog: PropTypes.func,
    }
};

function SupplierRenderer({columnData:{item, openEditDialog}}) {

    return (
        <Stack>
            <Box>
                <FormControlLabel
                    control={<Checkbox />}
                    label={`(${item?._count})${item?.supplier_name}`}
                    sx={{
                        '.MuiTypography-root' : {
                            fontSize: '0.8rem'
                        }
                    }}
                    // title={columnData?.supplier_name}
                />
            </Box>
            <Box sx={{
                '.MuiIconButton-root': {
                    paddingLeft: '0',
                    paddingRight: '0',
                },
                '.MuiSvgIcon-root': {
                    width: '0.8em',
                    height: '0.8em',
                }
            }}>
                <IconButton color="primary" size="small" aria-label="Download Excel" title={"下载Excel"}>
                    <DownloadIcon />
                </IconButton>
                <IconButton
                    href={!!item?.email?`mailto://${item?.email}`:null}
                    color="primary" size="small" aria-label="Download Excel" title={"发送Email"} disabled={!item?.email}
                >
                    <EmailIcon />
                </IconButton>
                <IconButton
                    href={!!item?.qq?`https://wpa.qq.com/msgrd?v=3&uin=${item?.qq}&site=qq&menu=yes`:null}
                    target={"_blank"}
                    color="primary" size="small" aria-label="Download Excel" title={"打开QQ"} disabled={!item?.qq}>
                    <SmsIcon />
                </IconButton>
                <IconButton color="primary" size="small" aria-label="edit" title={"编辑"} onClick={openEditDialog}>
                    <EditIcon />
                </IconButton>
                <IconButton color="primary" size="small" aria-label="Download Excel" title={"批量关闭"}>
                    <NotInterestedIcon />
                </IconButton>
            </Box>
        </Stack>
    );
}

export default SupplierRenderer;