// @flow
import React from 'react';
import {Grid} from "@mui/material";
import {ColumnType, Value} from "../useFilterTypes";

type Props = {
    type: ColumnType
    value: Value
    handleValueChange: React.ChangeEventHandler
};
export const TypeEditor = (props: Props) => {
    const {type, value, handleValueChange} = props
    return (
        <React.Fragment>
            <Grid item>
                <input type={"text"} value={String(value)} onChange={handleValueChange}/>
            </Grid>
        </React.Fragment>
    )
};

export default TypeEditor