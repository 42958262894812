import make_request from "./utils";


class ChemicalAPI{

    static async list({filters}) {
        return make_request(`/api/mod_chemical/chemical/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_chemical/chemical/${id}/`, null, {method: 'GET'})
    }

    static async create({id, ...data}){
        return make_request(`/api/mod_chemical/chemical/add/`, data, {method: 'POST'})
    }

    static async update({id, ...data}){
        return make_request(`/api/mod_chemical/chemical/${id}/`, data, {method: 'PUT'})
    }

    static async delete({id}) {
        return make_request(`/api/mod_chemical/chemical/${id}/`, null, {method: 'DELETE'})
    }

    static async get_moltext_by_cas(cas){
        return make_request(`/api/mod_chemical/chemical/get_moltext_by_cas/${cas}/`, null, {method: 'GET'})
    }
}

export default ChemicalAPI

