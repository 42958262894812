import make_request from "../utils";

class UserManageAPI {
    static async get(id) {
        return make_request(`/api/auth/user/${id}`, null, {method: "GET"})
    }
    static async list({filters={}}) {
        return make_request(`/api/auth/user/`, filters)
    }

}

export default UserManageAPI
