import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import DefaultSpinner from "../../../../../components/base/DefaultSpinner";
import {Stack, Box, Button, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox,} from "@mui/material";
import DraggableDialog from "../../../../../components/base/DraggableDialog";
import {HotTable} from "@handsontable/react";
import { registerAllModules } from 'handsontable/registry';

registerAllModules()

EditSupplierQuotationDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    supplier: PropTypes.object,
    inquiry: PropTypes.object,
};

const columns = [
    {title: "货号", width: 80},
    {title: "供应商", width: 120},
    {title: "纯度", width: 60},
    {title: "使用量", width: 60},
    {title: "测试量", width: 60},
    {title: "总量", readOnly: true, width: 80, cellType: 'numeric'},
    {title: "供应商报价", width: 60, cellType: "numeric"},
    {title: "货期", width: 60},
    {title: "不能提供", width: 40, cellType: 'checkbox'},
    {title: "供应商报价备注", },
    {title: "品牌"},
    {title: "供应商货号", },
    {title: "税率", },
    {title: "价格有效期", },
    {title: "询单号", readOnly: true},
]

function EditSupplierQuotationDialog(
    {
        open = false,
        handleClose = ()=>{},
        supplier = null
    }) {
    const hot = useRef(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    return (
        <DraggableDialog
            title={`编辑供应商询报价 - ${supplier?.supplier_name || ''}`}
            handleClose={handleClose} open={open}
            maxWidth={'xl'}
        >
            <DefaultSpinner open={loading} />
            <DialogContent>
                <Stack spacing={1}>
                    <Box>
                        <FormGroup sx={{marginLeft: '11px'}} row>
                            <FormControlLabel control={<Checkbox checked/>} label={"仅当前关联产品"} />
                        </FormGroup>
                    </Box>
                    <Box sx={{width: '80vw', height: '50vh'}}>
                        <HotTable
                            id="add_supplier_dialog_table"
                            licenseKey={'non-commercial-and-evaluation'}
                            style={{overflowX: 'scroll'}}
                            ref={hot}
                            settings={{
                                height: '100%',
                                width: '100%',
                                colHeaders: true,
                                manualColumnResize: true,
                                manualColumnMove: true,
                                readOnly: true,
                                columns: columns,
                                stretchH: 'all',
                            }}
                            data={data}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button>关闭询价</Button>
                <Button>保存</Button>
            </DialogActions>
        </DraggableDialog>
    );
}

export default EditSupplierQuotationDialog;