import { createSlice } from '@reduxjs/toolkit'


export const frameSlice = createSlice({
    name: 'frame',
    initialState: {
        sideMenuOpen: true,
        sideMenuWidth: 180,
        user: null,
    },
    reducers: {
        toggleSideMenu: (state)=>{
            state.sideMenuOpen = !state.sideMenuOpen
        }
    }
})

export const { toggleSideMenu } = frameSlice.actions

export default frameSlice.reducer
