import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@mui/material";
import MoleculeEditor from "../../../../components/MoleculeEditor/MoleculeEditor";
import ProductImageEditor from "./ProductImageEditor";


RightPanel.propTypes = {
    formik: PropTypes.object
};

function RightPanel(props) {
    const {formik} = props;

    return (
        <Grid container direction={"column"}>
            <Grid item md={12} style={{height: '400px'}}>
                <MoleculeEditor
                    value={formik.values.mol_text}
                    onChange={(value)=>formik.setFieldValue('mol_text', value)}
                    buttons={{
                        recognize: {hidden: true},
                        miew: {hidden: true},
                    }}
                />
            </Grid>
            <Grid item>
                <ProductImageEditor formik={formik}/>
            </Grid>
        </Grid>
    )
}

export default RightPanel;