import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, DialogContent, DialogActions, Button} from "@mui/material";
import {useFormik} from "formik";
import MenuManageAPI from "../../../../api/sys/MenuManageAPI";
import {endLoading, startLoading} from "../../../../redux/globalLoading/globalLoadingSlice";
import {useDispatch} from "react-redux";
import FormPart from "../../../../components/base/FormPart";
import {MenuItemParent} from "./fieldTypes";

MenuItemForm.propTypes = {
    
};

const fields = [
    {label: "ID", data: 'id', disabled: true, width: 3},
    {label: "父类", data: 'parent', width: 6, component: MenuItemParent},
    {label: "名称", data: 'name', disabled: false, width: 3},
    {label: "标题", data: 'title', disabled: false, width: 12},
    {label: "链接", data: 'url', disabled: false, width: 12},
    {label: "排序优先级", data: 'n_ordering', disabled: false, width: 3},
]

const initialValues = {
    name: '',
    title: '',
    url: '',
    // parent_id: null,
    n_ordering: 99999,
}

function MenuItemForm({id}) {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: values=>{
            let func
            dispatch(startLoading())
            if(forCreate){
                func = MenuManageAPI.create
            }else{
                func = MenuManageAPI.update
            }
            func(values)
                .then(data=>{
                    if(!data){return}
                    formik.setValues(data)
                }).finally(()=>{
                dispatch(endLoading())
            })
        }
    })
    const forCreate = !formik.values?.id

    useEffect(() => {
        if(!id){return}
        dispatch(startLoading())
        MenuManageAPI.get(id).then(data=>{
            formik.setValues(data)
        }).finally(()=>{
            dispatch(endLoading())
        })
    }, [id]);


    return (
        <React.Fragment>
            <DialogContent>
                <Grid container>
                    <Grid item>
                        <FormPart formik={formik} fields={fields}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={formik.submitForm}>{forCreate?"新增":"修改"}</Button>
            </DialogActions>
        </React.Fragment>
    );
}

export default MenuItemForm;