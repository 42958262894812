export const sample_packages = [
    {package:   "1mg", package_qty:   1, package_unit: "mg", src: "指导价", cost: '', price: '', delivery: "7天", expiry: "2024-01-01", website_available: true,},
    {package:  "10mg", package_qty:  10, package_unit: "mg", src: "指导价", cost: 1500, price: 2000, delivery: "7天", expiry: "2024-01-01", website_available: true,},
    {package:  "25mg", package_qty:  25, package_unit: "mg", src: "指导价", cost: 2000, price: 3000, delivery: "7天", expiry: "2024-01-01", website_available: true,},
    {package:  "50mg", package_qty:  50, package_unit: "mg", src: "指导价", cost: 4000, price: 5000, delivery: "7天", expiry: "2024-01-01", website_available: false,},
    {package: "100mg", package_qty: 100, package_unit: "mg", src: "指导价", cost: 6000, price: 7000, delivery: "7天", expiry: "2024-01-01", website_available: true,},
    {package: "250mg", package_qty: 250, package_unit: "mg", src: "指导价", cost: 7000, price: 8000, delivery: "7天", expiry: "2024-01-01", website_available: true,},
    // {package: "1g", package_qty: 1000, package_unit: "mg", src: "指导价", cost: 9000, price: 11000, delivery: "45天", expiry: "2024-01-01", website_available: false,},
];

export const sample_quotations = [
    {package:  "25mg", package_qty:  25, package_unit: "mg", qty: 1, src: "报价", price: 4500, date: "2021-12-05", code: "INQ202112050001",},
    {package:  "15mg", package_qty:  15, package_unit: "mg", qty: 1, src: "报价", price: 2300, date: "2022-02-05", code: "INQ202112050001",},
    {package:  "20mg", package_qty:  20, package_unit: "mg", qty: 1, src: "报价", price: 3000, date: "2023-03-05", code: "INQ202112050001",},
    {package:  "20mg", package_qty:  20, package_unit: "mg", qty: 1, src: "报价", price: 2000, date: "2023-03-05", code: "INQ202112050001",},
    {package:  "50mg", package_qty:  50, package_unit: "mg", qty: 1, src: "报价", price: 7000, date: "2024-01-05", code: "INQ202112050001",},
    {package:  "50mg", package_qty:  50, package_unit: "mg", qty: 1, src: "报价", price: 9000, date: "2023-06-05", code: "INQ202112050001",},
    {package:  "50mg", package_qty:  50, package_unit: "mg", qty: 1, src: "报价", price: 5000, date: "2023-06-05", code: "INQ202112050001",},
    {package: "100mg", package_qty: 100, package_unit: "mg", qty: 1, src: "报价", price: 9000, date: "2024-01-05", code: "INQ202112050001",},
    {package: "100mg", package_qty: 100, package_unit: "mg", qty: 1, src: "报价", price: 6500, date: "2024-01-05", code: "INQ202112050001",},
    {package: "100mg", package_qty: 100, package_unit: "mg", qty: 1, src: "报价", price: 6000, date: "2024-01-05", code: "INQ202112050001",},
];

export const sample_sales_order = [
    {package: "25mg", package_qty: 25, package_unit: "mg", qty: 1, src: "销售价", price: 2800, date: "2023-12-05", code: "SO202112050001",},
    {package: "15mg", package_qty: 15, package_unit: "mg", qty: 4, src: "销售价", price: 1900, date: "2023-12-05", code: "SO202112050001",},
    {package: "20mg", package_qty: 20, package_unit: "mg", qty: 2, src: "销售价", price: 3000, date: "2024-01-05", code: "SO202112050001",},
    {package: "50mg", package_qty: 50, package_unit: "mg", qty: 3, src: "销售价", price: 5800, date: "2023-04-05", code: "SO202112050001",},
    {package: "50mg", package_qty: 50, package_unit: "mg", qty: 2, src: "销售价", price: 5800, date: "2023-06-05", code: "SO202112050001",},
    {package: "100mg", package_qty: 100, package_unit: "mg", qty: 1, src: "销售价", price: 6500, date: "2024-01-05", code: "SO202112050001",},
];

export const supplier_quotations = [
    { supplier_name: "A", purity: null, inq_qty:   50, test_qty: null, pkg_qty:   50, pkg_unit: "mg", qty: 1, orig_price: 3800, price: 3800, delivery: 7, quo_remark: null, is_synthetic: false, currency: "RMB", tax_rate: 13, created_at: "2023-12-05"},
    { supplier_name: "A", purity: null, inq_qty:  100, test_qty: null, pkg_qty:  100, pkg_unit: "mg", qty: 1, orig_price: 6000, price: 6000, delivery: 7, quo_remark: null, is_synthetic: false, currency: "RMB", tax_rate: 13, created_at: "2023-12-05"},
    { supplier_name: "B", purity: null, inq_qty:   50, test_qty: null, pkg_qty:   50, pkg_unit: "mg", qty: 1, orig_price: 4200, price: 4200, delivery: 7, quo_remark: null, is_synthetic: false, currency: "RMB", tax_rate: 13, created_at: "2023-12-05"},
    { supplier_name: "B", purity: null, inq_qty:  100, test_qty: null, pkg_qty:  100, pkg_unit: "mg", qty: 1, orig_price: 6500, price: 6500, delivery: 7, quo_remark: null, is_synthetic: false, currency: "RMB", tax_rate: 13, created_at: "2023-12-05"},
    { supplier_name: "C", purity: null, inq_qty:  100, test_qty: null, pkg_qty:  100, pkg_unit: "mg", qty: 1, orig_price: 7000, price: 7000, delivery: 7, quo_remark: null, is_synthetic: false, currency: "RMB", tax_rate: 13, created_at: "2023-12-05"},
    { supplier_name: "D", purity: null, inq_qty: 5000, test_qty: null, pkg_qty: 5000, pkg_unit: "mg", qty: 1, orig_price: 8000, price: 8000, delivery: 45, quo_remark: null, is_synthetic: true, currency: "RMB", tax_rate: 13, created_at: "2023-12-05"},
];

export const related_inquiries = [
    {code: "JY2024051600045", created_by__name: "张三", package: "10mg", qty: 5, target_price: 5000, inq_remark: "", price: 6000, delivery: "7天", remark: null},
    {code: "JY2024051600045", created_by__name: "张三", package: "25mg", qty: 2, target_price: 5000, inq_remark: "", price: 6000, delivery: "7天", remark: null},
    {code: "JY2024051600045", created_by__name: "张三", package: "50mg", qty: 1, target_price: 5000, inq_remark: "", price: 6000, delivery: "7天", remark: null},
]