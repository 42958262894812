import React, {useState} from "react";
import {
    Column,
    Filter, FilterGroups,
    FilterProps,
    Logic,
    Operator,
    OrderingType,
    OrderMeta,
    SearchMeta,
    Value
} from "./useFilterTypes";
import {produce} from "immer";
import moment from "moment";


const unSearchableType = new Set(['img', 'handsontable', 'password'])

function isNumeric(value: string) {
    return /^-?\d+(\.\d+)?$/.test(value);
}

function isColumnSearchable({searchable=true, type='text'}: Column, value: any = undefined): boolean {
    if(!searchable){return false}
    // 如果字段类型是日期，需要先检验字符串是否符合
    if(type === 'date' && typeof value === 'string'){
        return moment(value, moment.ISO_8601, true).isValid() && /^\d+-\d+$/.test(value)
    }
    // 如果是数字类型，需要先检验字串是否数字
    if(type==='numeric' && typeof value == 'string'){
        return isNumeric(value)
    }
    return !unSearchableType.has(type);
}


function getDefaultSearchOp({searchable=true, type='text', defaultOp}: Column, value: Value): Operator | undefined{
    if(!searchable){return}
    if(!!defaultOp){return defaultOp}
    switch (type) {
        case "checkbox":
            return 'is'
        case "numeric":
            if(typeof value === 'string' && value.match(/^[-+]?\d+(\.\d+)?$/) != null){return}
            if(typeof value !== 'number'){return}
            break
        case "text":
            return 'contains'
    }
    return '='
}


function useFilter({
    initialSearches=[], initialGroups= {}, initialOrdering=[], columns=[],
    initPage=1, initPerPage=50, initLogic='and'
}: FilterProps
){
    const [searches, setSearches]: [SearchMeta[], Function] = useState(initialSearches)
    const [groups, setGroups]: [FilterGroups, Function] = useState(initialGroups)
    const [ordering, setOrdering]: [OrderMeta[], Function] = useState(initialOrdering)
    const [logic, setLogic]: [Logic, React.Dispatch<React.SetStateAction<Logic>>] = useState(initLogic)
    const [perPage, setPerPage]: [number, Function] = useState(initPerPage)
    let page = initPage;
    const [keyword, setFnKeyword] = useState('')
    const searchableColumns = columns.filter((column)=>isColumnSearchable(column))

    const setPage = (i: number)=>{page = i}
    const setKeyword = (n: string)=>{setFnKeyword(n)}

    const assembleFilter = ():Filter=>{
        // 根据searches和groups生成filters
        let finalGroups = Object.values(groups)
        if(!!keyword){
            let fuzzySearches:SearchMeta[] = []
            columns?.forEach((column)=>{
                if(!isColumnSearchable(column, keyword)){return}
                const op = getDefaultSearchOp(column, keyword)
                if(!op){return}
                fuzzySearches.push({
                    field: column.data,
                    op: op,
                    value: keyword
                })
            })
            finalGroups.push({
                groups:[],
                searches: fuzzySearches,
                logic: 'or'
            })
        }
        return {
            searches,
            groups: finalGroups,
            logic,
            page,
            per_page:perPage,
            ordering,
        }
    }

    const cleanGroupFieldSearcher = (field: string, groupName: string='')=>{
        setGroups(produce((prevGroups: FilterGroups)=>{
            if(!prevGroups.hasOwnProperty(groupName)){return}
            prevGroups[groupName].searches =  prevGroups[groupName].searches.filter(item=>item.field!==field)
            if(!prevGroups[groupName].searches?.length && !prevGroups[groupName].groups?.length){
                delete prevGroups[groupName]
            }
        }))
    }

    const setGroupFieldSearcher = (field: string, op: Operator, value: Value, groupName: string='')=>{
        setGroups(produce((prevGroups: FilterGroups)=>{
            if(!prevGroups.hasOwnProperty(groupName)){
                prevGroups[groupName] = {
                    groups: [],
                    searches: [{field, op, value}],
                    logic: 'and'
                }
            }else{
                const index = prevGroups[groupName].searches.findIndex(item=>item.field === field)
                if(index!==-1){
                    prevGroups[groupName].searches[index].op = op
                    prevGroups[groupName].searches[index].value = value
                }else{
                    prevGroups[groupName].searches.push({field, op, value})
                }
            }
        }))
    }

    const cleanFieldSearcher = (field: string)=>{
        setSearches((preSearches: SearchMeta[])=>preSearches.filter((item)=>item.field!==field))
    }

    const setFieldSearcher = (field: string, op: Operator, value: Value) =>{
        setSearches(produce((preSearches: SearchMeta[])=>{
            const index = preSearches.findIndex(item=>item.field === field)
            if(index!==-1){
                preSearches[index].op = op
                preSearches[index].value = value
            }else{
                preSearches.push({field, op, value})
            }
        }))
    }

    const cleanOrdering = ()=>{
        setOrdering([])
    }

    const setFieldOrdering = (field: string, order_type: OrderingType | 'na')=>{
        if(order_type === 'na'){
            setOrdering((preOrdering: OrderMeta[])=>preOrdering.filter((item)=>item.field!==field))
        }else{
            setOrdering(produce((preOrdering: OrderMeta[])=>{
                let index = preOrdering.findIndex(item=>item.field === field)
                if(index!==-1){
                    preOrdering[index].order_type = order_type
                }else{
                    preOrdering.push({field, order_type})
                }
            }))
        }
    }

    return {
        assembleFilter,
        searchableColumns,

        searches,
        cleanFieldSearcher,
        setFieldSearcher,

        groups,
        setGroups,
        cleanGroupFieldSearcher,
        setGroupFieldSearcher,

        ordering,
        cleanOrdering,
        setFieldOrdering,

        keyword,
        setKeyword,

        page,
        setPage,

        perPage,
        setPerPage,

        logic,
        setLogic,
    }
}

// @ts-ignore
export default useFilter;