import Handsontable from "handsontable";

export const NestedJsonRenderer = (field) => (instance, td, row, col, prop, value, cellProperties)=>{
    let row_data = instance.getSourceDataAtRow(row)
    let v = row_data
    for (const f of field.split('.')) {
        if(!row_data.hasOwnProperty(f)){
            v = null
            break
        }
        v = row_data[f]
    }
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties])
    td.setAttribute('title', value)
}