import {Operator} from "../useFilterTypes";

export type OperatorItem = {name: string, value: Operator}

export type OperatorItems = {
    [key: string]: OperatorItem
};


export type TypeOperatorOptions = {
    [key: string]: Operator[]
};

export const operators: OperatorItems = {
    '=': {name:'=', value:'='},
    'in': {name:'in', value:'in'},
    'contains': {name:'contains', value:'contains'},
    'is': {name:'is', value:'is'},
    '>':{name:'>', value:'>'},
    '>=':{name:'≥', value:'>='},
    '<':{name:'<', value:'<'},
    '<=':{name:'≤', value:'<='},
    'is_null':{name:'is NULL', value:'is_null'},
    // 'is_true':{name:'is true', value:'is_true'},
    // 'not_true':{name:'not true', value:'not_true'}
}

export const operator_options: TypeOperatorOptions = {
    'text': ['contains', '=', '>', '>=', '<=', '<', 'is_null', 'is_null', 'in'],
    'numeric': ['=', '>', '>=', '<=', '<', 'is_null', 'is_null'],
    'checkbox': ['is'],
    'date': ['>', '>=', '=', '<=', '<', 'is_null', 'is_null'],
}
