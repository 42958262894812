import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@mui/material";
import {CatalogParentField} from "./fieldTypes";
import FormPart from "../../../components/base/FormPart";

ProductCatalogForm.propTypes = {
    formik: PropTypes.object,
};


const fields = [
    {label: "父类", data: 'parent', component: CatalogParentField, width: 6},
    {label: "品牌前缀", data: 'brand_prefix', },
    {label: "前缀", data: 'prefix', },
    {label: "中文名", data: 'cn_name', },
    {label: "英文名", data: 'en_name', },
    {label: "负责人", data: 'handler', },
    {label: "存货分类编码", data: 'cls_code', },
    // {label: "分类类型", data: 'type_name', },
    {label: "品牌代号", data: 'code_name', },
    {label: "备注", data: 'remark', },
]


function ProductCatalogForm(props) {
    const {formik} = props

    const forCreate = !formik.values.id

    return (
        <Grid container spacing={1}>
            <FormPart formik={formik} fields={fields} forCreate={forCreate}/>
        </Grid>
    );
}

export default ProductCatalogForm;