import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Grid, Typography} from "@mui/material";
import {useLocation, useNavigate, createSearchParams} from "react-router-dom";
import AuthAPI from "./../../api/AuthAPI";
import {setUser} from "../../redux/userInfo/userSlice";
import {popErrorMsg} from "../../redux/errorMsg/errMsgSlice";


UserInfo.propTypes = {
    
};

function UserInfo() {
    let navigate  = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(({userInfo})=>userInfo.user)

    useEffect(()=>{
        let login_url = "/login";
        AuthAPI.get_user()
            .then(data=>{
                if(!data){return}
                if(!data?.id && location.pathname !== login_url){
                    // 如果非登录状态，调到登录页面
                    let path;
                    if(location.pathname!==login_url){
                        const next = location.pathname + location.hash + location.search;
                        console.log(next);
                        path= {
                            pathname: login_url,
                            search: createSearchParams({next: next}).toString(),
                        }
                    }else{
                        path = {pathname: login_url}
                    }
                    navigate(path);
                    return
                }
                dispatch(setUser(data))
            })
            .catch(e=>dispatch(popErrorMsg(e.toString())))
    }, [dispatch])

    return (
        <Grid container justifyContent={"right"}>
            <Grid item>
                {!!user?.id?(
                    <Grid container direction={"row"} >
                        <Grid item>
                            <Typography>欢迎 {user.username}</Typography>
                        </Grid>
                        <Grid item>
                            <Button onClick={()=> {
                                AuthAPI.logout().then(()=>{navigate('/login')})
                            }}>Logout</Button>
                        </Grid>
                    </Grid>
                ):(
                    <Button onClick={()=>navigate("/login")}>Login</Button>
                )}
            </Grid>
        </Grid>
    );
}

export default UserInfo;