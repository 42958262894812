import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import GenTable from "../../../components/GenTable/GenTable";
import TextSearchField from "../../../components/GenTable/searchFields/GTToolbarSearchField";
import SaleBillAPI from "../../../api/u8/SaleBillAPI";
import {EllipsisTextRenderer} from "../../../components/GenTable/renderers";
import {U8AuditStateRenderer} from "../utils/renderers";
import NuoNuoManualPushBtn from "./actions/NuoNuoManualPushBtn";
import NuoNuoManualRefreshBtn from "./actions/NuoNuoManualRefreshBtn";

SalesBillTable.propTypes = {
    
};

const columns = [
    {data: 'autoid', title: 'ID', width: 20, type: 'numeric'},
    {data: 'parent.ddate', title: '发票日期', width: 80, type: 'date'},
    {data: 'parent.iverifystate', title: '发票状态', width: 40, type: 'numeric', renderer: U8AuditStateRenderer},
    {data: 'parent.dverifysystime', title: '发票审核日期', width: 80, type: 'date'},
    {data: 'parent.csbvcode', title: '发票号', width: 120},
    {data: 'parent.cdefine3', title: '税务发票号', width: 80},
    {data: 'parent.cmemo', title: '发票备注', width: 80, renderer: EllipsisTextRenderer},
    {data: 'parent.cmaker', title: '制单人', width: 80},
    {data: 'parent.extra.chdefine17', title: '开票状态', width: 80, renderer: EllipsisTextRenderer},
    {data: 'parent.extra.chdefine19', title: '发票PDF地址', width: 80, renderer: EllipsisTextRenderer},
    {data: 'cinvcode', title: '存货编码', width: 90, renderer: EllipsisTextRenderer},
    {data: 'cbatch', title: '批次', width: 90},
    {data: 'iquantity', title: '数量', width: 60, type: 'numeric'},
    {data: 'inum', title: '瓶数', width: 60, type: 'numeric'},
    {data: 'iunitprice', title: '单价', width: 60, type: 'numeric'},
    {data: 'imoney', title: '不含税合计', width: 60, type: 'numeric'},
    {data: 'isum', title: '含税合计', width: 60, type: 'numeric'},
    {data: 'cbdlcode', title: '发货单号', width: 120},
    {data: 'cbsaleout', title: '出库单号', width: 120},
]

function SalesBillTable(props) {
    const dispatch = useDispatch()

    return (
        <React.Fragment>
            <GenTable
                api={SaleBillAPI.list}
                hotSettings={{
                    rowHeights: 20,
                    stretchH: 'all',
                }}
                columns={columns}
                actions={[
                    NuoNuoManualPushBtn,
                    NuoNuoManualRefreshBtn,
                    TextSearchField('parent.csbvcode', '发票号', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('parent.cdefine3', '税务发票号', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('parent.cmaker', '制单人', 'contains', '',{sx:{width: '150px'}}),
                ]}
                // initialGroups={initialSearchGroup}
            />
        </React.Fragment>
    );
}

export default SalesBillTable;