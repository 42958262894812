import make_request from "./utils";
import store from '../redux/store';
import {cleanUser} from "../redux/userInfo/userSlice";

export default class AuthAPI {
    static login(login_form) {
        return make_request('/api/auth/login/', login_form)
    }

    static logout(){
        return make_request('/api/auth/logout/', null, {"method": "GET"}).then(data=>{
            store.dispatch(cleanUser())
            return data
        })
    }

    static get_user(){
        return make_request('/api/auth/get_user/', null, {'method': 'GET'})
    }
}