import {Grid} from "@mui/material"

import PackageTable from "./PackageTable"
import PackageCharts from "./PackageCharts"


export default function PackageManage({pkgManager}){


    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <PackageTable pkgManager={pkgManager}/>
            </Grid>
            <Grid item xs={6}>
                <PackageCharts pkgManager={pkgManager}/>
            </Grid>
        </Grid>
    )
}