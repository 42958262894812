import React from 'react';
import PropTypes from 'prop-types';
import GenTagsEditor from "../../../../components/GenTagsEditor/GenTagsEditor";
import ProductAPI from "../../../../api/ProductAPI";


ProductTagEditor.propTypes = {
    formik: PropTypes.object
};

function ProductTagEditor({formik}) {


    return (
        <GenTagsEditor
            obj_id={formik?.values?.id}
            label={"标签"}
            api={ProductAPI}
        />
    );
}

export default ProductTagEditor;