import React from 'react';
import {Grid, styled, Toolbar} from "@mui/material";
import {useSelector} from "react-redux";
import {Container} from "./styles";
import GenTabPanel from "./GenTabPanel";


MainContent.propTypes = {

};


const ContainerGrid = styled(Grid)(({theme})=>({
    height: `calc(100% - ${48}px)` // TODO too hard code
}))

function MainContent() {
    const tabs = useSelector((state) => state.tab.tabs)
    const curTab = useSelector((state) => state.tab.curTab)
    const sideMenuOpen = useSelector((state) => state.frame.sideMenuOpen)

    return (
        <Container sideMenuOpen={sideMenuOpen}>
            <Grid item>
                <Toolbar/>
            </Grid>
            <ContainerGrid item>
                {tabs.map((tab, index) =>(
                    <GenTabPanel
                        curTab={curTab}
                        key={tab.id}
                        tab={tab}
                        index={index}
                    />
                ))}
            </ContainerGrid>
        </Container>
    );
}

export default MainContent;