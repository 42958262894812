import make_request from "./utils";


class ProductCatalogAPI{
    static async list({filters}) {
        return make_request(`/api/mod_product/product_catalog/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_product/product_catalog/${id}/`, null, {method: 'GET'})
    }

    static async create({id, ...data}){
        return make_request(`/api/mod_product/product_catalog/add/`, data, {method: 'POST'})
    }

    static async update({id, ...data}){
        return make_request(`/api/mod_product/product_catalog/${id}/`, data, {method: 'PUT'})
    }

    static async delete({id}) {
        return make_request(`/api/mod_product/product_catalog/${id}/`, null, {method: 'DELETE'})
    }

    static async get_inquiry_types(){
        // TODO
    }

    static async get_api_name(query){
        // TODO
    }
}

export default ProductCatalogAPI
