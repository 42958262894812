import make_request from "./utils";


class U8CustomerAPI{
    static async get_customer_simple({keyword}){
        return make_request('/api/u8addon/customer/simple/', {keyword})
    }

    static async get_handlers(customer_id){
        return make_request(`/api/u8addon/customer/${customer_id}/handlers/`,null ,{method: 'GET'})
    }


    static async get_contacts(customer_id){
        return make_request(`/api/u8addon/customer/${customer_id}/contacts/`,null ,{method: 'GET'})
    }
}

export default U8CustomerAPI