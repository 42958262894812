import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ButtonWithModel from "../../../components/base/ButtonWithModel";
import {DialogActions, DialogContent, TextField, Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {popErrorMsg} from "../../../redux/errorMsg/errMsgSlice";
import {endLoading, startLoading} from "../../../redux/globalLoading/globalLoadingSlice";
import ProductAPI from "../../../api/ProductAPI";


ProductDeleteDialog.propTypes = {
    cat_no: PropTypes.string
};

function ProductDeleteDialog({cat_no, product_id}) {
    const [value, setValue] = useState('')
    const dispatch = useDispatch()

    const handleChange=(e)=>{
        const {target} = e
        setValue(target.value)
    }

    const handleDelete=()=>{
        if(!cat_no || value !== cat_no){
            dispatch(popErrorMsg('货号不一致，不能删除'))
            return
        }
        dispatch(startLoading())
        ProductAPI.delete({id: product_id})
            .then(data=>{
                if(!data){return}
                dispatch(popErrorMsg('删除成功'))
            })
            .finally(()=>{dispatch(endLoading())})
    }

    const stopPaste=(e)=>{
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <ButtonWithModel
            label={"删除产品"} title={'是否确认删除'}
            btnProps={{color: 'secondary'}}
        >
            <React.Fragment>
                <DialogContent>
                    <TextField
                        label={'请输入货号'} value={value} onChange={handleChange} color={"secondary"} onPaste={stopPaste}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color={"secondary"}>确认删除</Button>
                </DialogActions>
            </React.Fragment>
        </ButtonWithModel>
    );
}

export default ProductDeleteDialog;