import React from 'react';
import GenTable from "../../../components/GenTable/GenTable";
import ProductAPI from "../../../api/ProductAPI";
import {useDispatch} from "react-redux";
import {addTab} from "../../../redux/tab/tabSlice";
import TextSearchField from "../../../components/GenTable/searchFields/GTToolbarSearchField";
import ActionAddTabBtn from "../../../components/GenTable/ActionAddTabBtn";


ProductList.propTypes = {
    
};


const columns = [
    {data: 'id', title: 'ID', width: 20, type: 'numeric'},
    {data: 'brand_name', title: '品牌', width: 80},
    {data: 'cat_no', title: '货号', width: 80},
    {data: 'en_name', title: '英文名', width: 120},
    {data: 'cn_name', title: '中文名', width: 120},
    {data: 'cas', title: 'CAS', width: 80},
    {data: 'mf', title: '分子式', width: 80},
    {data: 'mw', title: '分子量', width: 80},
    {data: 'img', title: '产品图片', width: 150, type: 'img', searchable: false},
    {data: 'modified_by__username', title: '修改人', width: 80},
    {data: 'created_by__username', title: '新建人', width: 80},
]


const initialSearchGroup = {
    "": {
        searches: [
            {field: 'brand_name', op: '=', value: 'cato'},
        ]
    }
}


function ProductList() {
    const dispatch = useDispatch()

    const onRecordDbClick = (data)=>{
        dispatch(addTab({title:'产品信息', url:`/product/${data?.id}`}))
    }

    return (
        <React.Fragment>
            <GenTable
                api={ProductAPI.list}
                hotSettings={{

                    rowHeights: 85,
                    stretchH: 'all',
                }}
                columns={columns}
                onRecordDbClick={onRecordDbClick}
                actions={[
                    ActionAddTabBtn({label:'New', title:'产品信息', url:'/product_form'}),
                    TextSearchField('brand_name', '品牌', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('cat_no', '货号', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('cas', 'CAS号', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('mf', '分子式', 'contains', '',{sx:{width: '150px'}}),
                ]}
                initialGroups={initialSearchGroup}
            />
        </React.Fragment>
    );
}

export default ProductList;