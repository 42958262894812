import React from "react";
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./pages/frame/Home";
import LoginPage from "./pages/Login/LoginPage";
import Handsontable from "handsontable";


// quick fix for handsontable missing header from "display: none"
// https://github.com/handsontable/handsontable/issues/5322
Handsontable.hooks.add('afterInit', function(){
    const hotForceRender=()=>{if(!this?.isDestroyed){this.render()}}
    const observer = new MutationObserver(hotForceRender)
    const node = this.container.closest('div[role="tabpanel"]')
    if(!node){return}
    observer.observe(node, {attributes: true})
});


function App() {

    return (
        <Router>
            <Routes>
                <Route exact path={"/"} element={<Home />} />
                <Route path={"/login"} element={<LoginPage />} />
            </Routes>
        </Router>
    );
}

export default App;
