import React from 'react';
import PropTypes from 'prop-types';
import Image from "../../../../../components/Image/Image";
import {Box, Typography, Stack, styled} from "@mui/material";


InquiryRenderer.propTypes = {

};

const InqHighlightStack = styled(Stack,{
    shouldForwardProp: (prop) => (prop !== 'inq_status')
})(({inq_status, theme})=>({
    height: '100%',
    backgroundColor: (inq_status===0?'rgba(255,153,0,0.14)':
        inq_status===1?'rgba(4,154,246,0.22)':
            inq_status===2?'rgb(255,255,255)':
                inq_status===3?'rgba(255,167,186,0.49)':null),
}))

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
}));

function InquiryRenderer({rowData}) {
    return (
        <InqHighlightStack direction="row" spacing={2} inq_status={rowData?.status}>
            <Item>
                <Image src={rowData.img} alt="/logo192.png" height={"30px"} width={"100px"}/>
                <br/><b>检测: </b>{rowData?.test}
                <br/><b>询价备注: </b>{rowData?.remark}
            </Item>
            <Item>
                <b>货号: </b>{rowData?.inq_cat_no}
                <br/><b>中文名: </b>{rowData?.cn_name}
                <br/><b>英文名: </b>{rowData?.en_name}
                <br/><b>CAS: </b>{rowData?.cas}
                <br/><b>规格: </b>{rowData?.package}
                <br/><b>数量: </b><Typography variant={"span"} color={rowData?.quantity>1?"red":null}>{rowData?.quantity}</Typography>
                <br/><b>目标价: </b>{rowData?.target_price}
            </Item>
        </InqHighlightStack>
    );
}

export default InquiryRenderer;