import make_request from "./utils";

const essential_fields = [
    'api_name',
    'brand',
    'cat_no',
    'cas',
    'cn_name',
    'en_name',
    'img',
]

function prepare_data({children, ...parent}){
    return {
        ...parent,
        children: children.filter(
            child=> {
                if(!!child.id){return true}
                return essential_fields.filter(field=>{
                    const item = child[field]
                    if(typeof item === 'object'){
                        return false
                    }else{
                        return !!item
                    }
                }).length > 0
            }
        ),
    }
}

class CustomerInquiryAPI{
    static async list({filters}) {
        return make_request(`/api/mod_inquiry/inquiry/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_inquiry/inquiry/${id}/`, null, {method: 'GET'})
    }

    static async create({id, ...inquiry_form}){
        const data = prepare_data(inquiry_form)
        return make_request(`/api/mod_inquiry/inquiry/add/`, data, {method: 'POST'})
    }

    static async update({id, ...inquiry_form}){
        const data = prepare_data(inquiry_form)
        return make_request(`/api/mod_inquiry/inquiry/${id}/`, data, {method: 'PUT'})
    }

    static async delete({id}) {
        return make_request(`/api/mod_inquiry/inquiry/${id}/`, null, {method: 'DELETE'})
    }

    static async submit_inquiry({id}) {
        return {}
    }

    static async upload_inquiry(){
        // TODO
    }

    static async delete_inquiry_detail(inquiry_id, inquiry_detail_id) {
        return make_request(`/api/mod_inquiry/inquiry/${inquiry_id}/children/${inquiry_detail_id}`, null, {method: 'DELETE'})
    }
}


export default CustomerInquiryAPI