import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    Card,
    CardActions,
    Grid,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText, Switch
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AutoScrollCardContent from "./AutoScrollCardContent";


GroupList.propTypes = {
    
};

function GroupList({menu}) {
    // TODO 可能改为树状图更方便管理

    useEffect(() => {
        menu.refreshGroupData()
    }, []);

    const refresh = ()=>{
        menu.refreshGroupData()
    }

    return (
        <Card sx={{height: '100%'}}>
            <Grid container sx={{height: '100%', width: '100%'}}>
                <Grid item xs={12}>
                    <CardActions>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item>
                                <Typography variant="h5">分组</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"}>
                                    <Grid item>
                                        <TextField label={"关键字"}/>
                                    </Grid>
                                    <Grid>
                                        <IconButton onClick={refresh}><SearchIcon/></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
                <Grid item xs={12} sx={{height: 'stretch', overflowY: 'auto'}}>
                    <AutoScrollCardContent>
                        <List>
                            {menu?.groups?.map(item=>(
                                <ListItem key={item?.id}>
                                    <ListItemText primary={item?.name}/>
                                    <Switch
                                        edge="end"
                                        checked={menu?.groupHasPerm(item?.id)}
                                        onChange={(e)=> {
                                            menu?.toggleGroupPerm(item?.id, e.target.checked?1:0)
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AutoScrollCardContent>
                </Grid>
            </Grid>
        </Card>
    );
}

export default GroupList;