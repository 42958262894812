// @flow 
import React from 'react';
import {
    Checkbox,
    Grid,
    Icon, NativeSelect,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import TypeEditor from "./TypeEditor";
import {Column, Operator, OrderingType, OrderMeta, SearchMeta, Value} from "../useFilterTypes";
import {useEffect, useState} from "react";
import { GTTable } from '../types';
import {operator_options, OperatorItem, operators} from "./constants";


type Props = {
    table: GTTable
    column: Column
    groupName?: string
};

export const SearchItem = ({table: {filter}, column:{data, title='', type='text'}, groupName=''}: Props) => {
    const orderType = filter.ordering.find((item: OrderMeta)=>item.field===data)?.order_type
    const op_options: OperatorItem[] = operator_options[type]?.map(value=>operators[value])||[]

    const searchMeta = filter.groups[groupName]?.searches.find((item: SearchMeta)=>item.field===data)

    const [checked, setChecked] = useState(!!searchMeta)
    const [value, setValue]: [Value, Function] = useState(searchMeta?.value || '')
    const [op, setOp]:[Operator, Function] = useState(searchMeta?.op || op_options[0].value)

    const handleBlur = ()=>{
        if(checked){
            filter.setGroupFieldSearcher(data, op, value, groupName)
        }else{
            filter.cleanGroupFieldSearcher(data, groupName)
        }
    }

    const handleOperatorChange = (event: React.ChangeEvent)=>{
        // @ts-ignore
        const {target: { value },} = event
        setOp(value)
    }

    const handleValueChange = (e: React.ChangeEvent)=>{
        const {target} = e
        // @ts-ignore
        const value: Value = target.value
        setValue(value)
    }

    const handleOrderTypeChange = (e: React.MouseEvent, value: OrderingType)=>{
        filter.setFieldOrdering(data, value)
    }

    useEffect(()=>{
        if(checked){return}
        if(!!value){setChecked(true)}
    }, [value])

    return (
        <Grid container direction={'row'} spacing={2} alignItems={"baseline"} justifyContent={"space-between"}>
            <Grid item>
                <Grid container direction={'row'} spacing={1} alignItems={"baseline"} onBlur={handleBlur}>
                    <Grid item>
                        <Checkbox checked={checked} onClick={()=>setChecked(checked=>!checked)}/>
                    </Grid>
                    <Grid item>
                        <Typography>{title}</Typography>
                    </Grid>
                    <Grid item>
                        <NativeSelect value={op} onChange={handleOperatorChange}>
                            {op_options.map((value, index)=>(
                                <option key={index} value={value.value}>{value.name}</option>
                            ))}
                        </NativeSelect>
                    </Grid>
                    <TypeEditor
                        type={type}
                        value={value}
                        handleValueChange={handleValueChange}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <Grid item>
                    <ToggleButtonGroup
                        value={orderType || "na"}
                        exclusive
                        onChange={handleOrderTypeChange}
                    >
                        <ToggleButton value={"na"}>
                            <Icon>N</Icon>
                        </ToggleButton>
                        <ToggleButton value={"asc"}>
                            <Icon>正序</Icon>
                        </ToggleButton>
                        <ToggleButton value={"desc"}>
                            <Icon>倒序</Icon>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </Grid>
    );
};