import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import TabPanel from "../../components/Tab/TabPanel";
import {Grid} from "@mui/material";
import {getRouteByUrl} from "../../routes";

GenTabPanel.propTypes = {
    tab: PropTypes.object,
    index: PropTypes.number,
    curTab: PropTypes.number,
};

function GenTabPanel({tab:{id, title, url, component_props}, index, curTab}) {

    const memo = useMemo(()=>{
        const [m, route] = getRouteByUrl(url)
        if(!m){return}  // TODO return 404 page?
        const Component = route.component
        return (
            <Component {...m.params} {...component_props} title={title} url={url}/>
        )
    }, [url, component_props])

    return (
        <TabPanel value={curTab} index={index} key={id} wrap={'nowrap'}>
            <Grid item xs={12}>
                {memo}
            </Grid>
        </TabPanel>
    );
}

export default GenTabPanel;