import React, {useMemo} from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";



function CheckboxField(
    {
        formik, name, value=false, onChange, label, fullWidth, forCreate, helperText, ...otherProps
    }){

    const handleChange = (event) =>{
        formik.setFieldValue(name, event.target.checked)
    }

    const memoComp = useMemo(()=>(
        <FormControlLabel
            labelPlacement="start"
            label={label}
            control={
                <Checkbox
                    checked={value}
                    onChange={handleChange}
                    {...otherProps}
                />
            }
        />
    ), [label, value])

    return (
        <React.Fragment>
            {memoComp}
        </React.Fragment>
    )
}

export default CheckboxField

