import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {Button, Grid, IconButton} from "@mui/material";
import denseTheme from "../../theme/denseTheme";


GtPaginator.propTypes = {
    table: PropTypes.object.isRequired,
};

function GtPaginator({table}) {
    const theme = denseTheme;
    const [vPage, setVPage] = useState(1);

    useEffect(()=>{
        setVPage(table.curPage)
    }, [table.curPage])

    const onVPageChange = ({target})=>{
        const n = parseInt(target.value);
        if(table.invalidPage(n)){return}
        setVPage(n)
    }

    return (
        <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
                <IconButton
                    onClick={()=>table.gotoPage(1)}
                    disabled={table.curPage === 1}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    onClick={table.prevPage}
                    disabled={table.curPage === 1} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    onClick={table.nextPage}
                    disabled={table.curPage >= table.totalPage}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    onClick={()=>{table.gotoPage(table.totalPage)}}
                    disabled={table.curPage >= table.totalPage}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Grid>
            <Grid item>
                <label >Total records: {table.total} </label>
                <label htmlFor={'per_age'}>Per Page: </label>
                <select
                    id={'per_page'} name={'per_page'}
                    value={table.perPage} onChange={({target})=>table.setPerPage(target.value)}
                >
                    {table.perPageOptions.map(value=>(
                        <option value={value} key={value}>{value}</option>
                    ))}
                </select>
            </Grid>
            <Grid item>
                <label htmlFor={'page'}>Page: </label>
                <input
                    id={'page'} name={'page'} width={50} type="number" style={{width: 80}} min={1}
                    value={vPage} onChange={onVPageChange}
                />
            </Grid>
            <Grid item>
                <Button onClick={()=>table.gotoPage(vPage)}>Go</Button>
            </Grid>
        </Grid>
    );
}

export default GtPaginator;