import React, {useEffect, useState} from 'react';
import {Box, Icon, List, ListItem, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {Drawer} from "./styles";
import {addTab} from "../../redux/tab/tabSlice";
import GeneralAPI from "../../api/GeneralAPI";


SideMenu.propTypes = {

};


function MenuItem(
    {
        item: {title, url, children, component, component_props},
        sx={}
    }){
    const [expanded, setExpanded] = useState(true)
    const dispatch = useDispatch()

    const handleExpand = (e)=>{
        if(children?.length>0){
            e.preventDefault()
            setExpanded(prevState=>!prevState)
            return
        }
        dispatch(addTab({
            title, url, component, component_props
        }))
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleExpand} sx={sx}>
                <ListItemIcon>
                    <Icon sx={{borderRadius: '50%'}}>{title[0]}</Icon>
                </ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
            {expanded?children?.map(item => (
                <MenuItem item={item} key={item.name} sx={{paddingLeft: 6}}/>
            )):null}
        </React.Fragment>
    )
}


function SideMenu() {
    const open = useSelector((state) => state.frame.sideMenuOpen)
    const [menu, setMenu] = useState([])

    useEffect(() => {
        GeneralAPI.menu_tree().then(data=>setMenu(data))
    }, [])

    return (
        <Drawer
            variant="permanent"
            open={open}
        >
            <Toolbar />
            <Box sx={{ overflowX: 'hidden' }}>
                <List>
                    {menu.map(item => (
                        <MenuItem item={item} key={item.name}/>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
}

export default SideMenu;