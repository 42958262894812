import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Button} from "@mui/material";
import FormPart from "../../../components/base/FormPart";
import CustomerInquiryAPI from "../../../api/CustomerInquiryAPI";
import SalesInquiryItemTable from "./SalesInquiryItemTable";
import {ContactField, CustomerField, DiscountField, SalesField} from "./inputFields";
import ExportBtn from "../../../components/ExportBtn/ExportBtn";
import {useInquiry} from "./useInquiry";
import TabPanelDetail from "../../../components/Tab/TabPanelDetail";


const textFields = [
    {data: "code", label: "单据号", disabled: true, width: 2},
    {data: "created_by", label: "创建人", disabled: true, width: 1},
    {data: "customer_name", label: "客户名称", component: CustomerField, width: 2},
    {data: "sales_name", label: "销售人员", component: SalesField, width: 2},
    {data: "contact_name", label: "客户联系人", width: 1, component: ContactField},
    {data: "discount", label: "整单折扣", component: DiscountField, width: 1},
    {data: "remark", label: "备注", width: 3},
]


const InquiryToolbar = ({inquiry})=>{
    const summary = inquiry.summary

    const handleTemplateClick = (e, item)=>{
        CustomerInquiryAPI.export_quotation({inquiry_id: inquiry.formik.values.id, template_id: item.id})
    }

    return (
        <Grid container justifyContent={'space-between'} spacing={1}>
            <Grid item>
                <Typography>
                    <b>计数:</b> {summary.count}
                    | <b>数量合计:</b> {summary.sum_quantity}
                    | <b>报价合计:</b> {summary.sum_offer_price?.toFixed(2)}
                    | <b>平均折扣:</b> {summary.avg_discount?.toFixed(2)}
                    | <b>内部报价情况:</b> {summary.count_closed_inter_quo}/{summary.count_inter_quo}
                </Typography>
            </Grid>
            <Grid item>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button onClick={inquiry.handleSave} color={inquiry.changed===0?"primary":"secondary"}>保存</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={inquiry.handleSubmit} disabled={!inquiry.formik.values.id}>提交</Button>
                    </Grid>
                    <Grid item>
                        <ExportBtn template_type={'mod_inquiry/inquiry/'} onTemplateClick={handleTemplateClick} label={'导出报价单'}/>
                    </Grid>
                    <Grid item>
                        <Button disabled>下推销售订单</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={inquiry.handleDelete} color={"secondary"} disabled={!inquiry.formik.values.id}>删除</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


CustomerInquiryOrder.propTypes = {
    data: PropTypes.object,
};

function CustomerInquiryOrder({id}) {
    const inquiry = useInquiry({inquiry_id: id})

    return (
        <TabPanelDetail
            actions={<InquiryToolbar inquiry={inquiry}/>}
        >
            <Grid container direction={"column"}>
                <FormPart fields={textFields} formik={inquiry.formik} forCreate={!inquiry.formik.values?.id}/>
                <SalesInquiryItemTable formik={inquiry.formik} changed={Boolean(inquiry.changed)} setChanged={inquiry.setChanged}/>
                {/*<SalesActivities customer_id={inquiry.formik.values?.customer_id}/>*/}
            </Grid>
        </TabPanelDetail>
    );
}

export default CustomerInquiryOrder;