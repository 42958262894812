import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, IconButton, Grid, Typography, Card, CardActions, List} from "@mui/material";
import MenuItem from "./MenuItem";
import MenuItemEditDialog from "./MenuItemEditDialog";
import AutoScrollCardContent from "./AutoScrollCardContent";
import SearchIcon from '@mui/icons-material/Search';

MenuTreeList.propTypes = {

};


function MenuTreeList({menu}) {
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)

    useEffect(() => {
        menu.refreshData()
    }, []);

    const openEditDialog = ({id=null})=>{
        setId(id)
        setOpen(true)
    }
    
    const onItemClick = ({id}) => {
        menu.onSelectMenu({id})
    }

    return (
        <Card sx={{height: "100%", }}>
            <CardActions>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant="h5">菜单</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={()=>menu.refreshData()}><SearchIcon/></IconButton>
                        <Button onClick={()=>openEditDialog({})}>新建</Button>
                    </Grid>
                </Grid>
            </CardActions>
            <AutoScrollCardContent>
                <List>
                    {menu?.menu?.map(item=>(
                        <MenuItem
                            item={item} key={item?.id} menu={menu}
                            onItemClick={onItemClick} onItemDBClick={openEditDialog}
                        />
                    ))}
                </List>
            </AutoScrollCardContent>
            <MenuItemEditDialog open={open} id={id} handleClose={()=> {
                setOpen(false)
                menu.refreshData()
            }}/>
        </Card>
    );
}

export default MenuTreeList;