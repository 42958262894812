export const [white, green, red, yellow] = ['#fff', '#deffe0', '#ffe8e8', '#fff9d8']
export const [purple, blue] = ['#e0d0ff', '#cfe4ff']

export const HtCatNoFieldItemStyle = {
    height: '80px',
    '& img': {
        maxHeight: '100%'
    }
}

export const InquiryItemTableStyle = {
    '& td':{
        verticalAlign: 'middle',
    },
    '& td.htMultiCandidate': {
        backgroundColor: '#ffd9a0 !important'
    },
}

export const SalesInquiryItemTableStyle = {
    '& .MuiCardHeader-action': {
        'alignSelf': 'center'
    }
}
