import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField, Toolbar
} from "@mui/material";
import {useFormik} from "formik";
import AuthAPI from "../../api/AuthAPI";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUser} from "../../redux/userInfo/userSlice";
import Header from "../frame/Header";
import GlobalSnackbar from "../../components/base/GlobalSnackbar";


LoginPage.propTypes = {
    user: PropTypes.object,
    setUser: PropTypes.func,
};

function LoginPage() {
    let navigate  = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const formik = useFormik({
        initialValues:{
            username: '',
            password: '',
        },
        onSubmit: values => {
            AuthAPI.login(values)
                .then(data=>{
                    if(!data){return}
                    if(data?.data?.id === null){
                        alert("Login fail")
                        return
                    }
                    dispatch(setUser(data.data));
                    const next_url = searchParams.get("next") || '/';
                    navigate(next_url);
                })
                .catch(e=>alert(e))
        }
    })

    const handleKeyPress = (e)=>{
        if (e.key === 'Enter') {
            formik.submitForm()
            e.preventDefault();
        }
    }
    return (
        <React.Fragment>
            <Header />
            <GlobalSnackbar />
                <Grid item>
                    <Toolbar/>
                </Grid>
                <Grid item sx={{height: '100%'}}>
                    <Grid container justifyContent={"center"} alignItems="center" sx={{height: '100%'}}>
                        <Grid item md={3}>
                            <Card title={"Login"}>
                                <CardContent>
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <TextField
                                                label={"User"} id={'username'} fullWidth
                                                value={formik.values.username} onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={"Password"} id={'password'} type={"password"} fullWidth
                                                value={formik.values.password} onChange={formik.handleChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color={"primary"} variant={"contained"}
                                                onClick={formik.submitForm}
                                            >Login</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
        </React.Fragment>
    );
}

export default LoginPage;