// @flow
import React, {useMemo} from 'react';
import {Card, CardActions, CardContent, Stack} from "@mui/material";

type Props = {
    actions?: React.ReactNode
    children: React.ReactNode
};
export const TabPanelDetail = ({actions=null, children}: Props) => {
    // TODO useMemo cause component doesn't render when window size change
    // const childrenMemo = useMemo(()=>(
    //     <React.Fragment>
    //         {children}
    //     </React.Fragment>
    // ), [children])

    return (
        <Card sx={{height: '100%'}}>
            <Stack sx={{height: '100%', width: '100%'}}>
                {actions?(
                    <CardActions>
                        {actions}
                    </CardActions>
                ):null}
                <CardContent sx={{height: '100%', maxHeight: '100%', overflowY: 'auto'}}>
                    {children}
                </CardContent>
            </Stack>
        </Card>
    );
};

export default TabPanelDetail