
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import {Card, CardHeader, CardContent, Stack, Typography, Button} from "@mui/material"

const columns = [
    {data: "code", title: "单号", readOnly: true},
    {data: "package", title: "规格", readOnly: true},
    {data: "qty", title: "数量", readOnly: true},
    {data: "target_price", title: "目标价", type: "numeric", readOnly: true},
    {data: "extra_test", title: "额外检测", readOnly: true},
    {data: "inq_remark", title: "询价备注", readOnly: true},
    {data: "price", title: "指导价", type: "numeric"},
    {data: "delivery", title: "货期", },
    {data: "quo_remark", title: "备注", },
    {data: "created_by__name", title: "制单人", readOnly: true},
    {data: "control_btn", title: "操作", readOnly: true},
]

function RelatedInquiryTableHeader({pkgManager}){
    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} spacing={1}>
                <Button onClick={()=>{pkgManager?.refreshRelatedInquiries()}}>刷新</Button>
                <Button>转单</Button>
                <Button>刷新价格</Button>
                <Button color={pkgManager?.relatedInquiriesChanged?"secondary":"primary"}>提交报价</Button>
                <Button>保存报价</Button>
            </Stack>
            <Typography sx={{fontWeight: 800}}>相关询价</Typography>
        </Stack>
    )
}

export default function RelatedInquiryTable({pkgManager}){
    return (
        <Card>
            <CardHeader title={<RelatedInquiryTableHeader pkgManager={pkgManager}/>}/>
            <CardContent>
                <HotTable
                    id={"related_inquiry_table"}
                    columns={columns}
                    data={pkgManager?.relatedInquiries}
                    rowHeaders={true}
                    colHeaders={true}
                    height="auto"
                    autoWrapRow={true}
                    autoWrapCol={true}
                    stretchH={"all"}
                    licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                    manualColumnMove
                    manualColumnResize
                />
            </CardContent>
        </Card>
    )
}



