import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, Paper} from "@mui/material";
import useTable from "./useTable";
import GtToolbar from "./GTToolbar/GTToolbar";
import GtPaginator from "./GTPaginator";
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import {AutoSizer} from "react-virtualized";
import Handsontable from "handsontable";
import DefaultSpinner from "../base/DefaultSpinner";
import {ImageRenderer, SimpDateTimeRenderer} from "./renderers";
import {ImageEditor} from "./editors";
import ImageTooltip from "./ImageTooltip";
let _ = require('lodash/core');


registerAllModules();

Handsontable.cellTypes.registerCellType(
    'img',
    {
        renderer: ImageRenderer,
        editor: ImageEditor,
    }
)
Handsontable.cellTypes.registerCellType(
    'date',
    {
        renderer: SimpDateTimeRenderer,
        editor: Handsontable.editors.DateEditor
    }
)
Handsontable.dateFormat = 'YYYY-MM-DD'

GenTable.propTypes = {
    api: PropTypes.func.isRequired,
    hotSettings: PropTypes.object,
    actions: PropTypes.arrayOf(PropTypes.any)
};

function GenTable(
    {
        api,
        columns,
        hotSettings={},
        hotRef,
        actions=[],
        initialSearches=[],
        initialGroups= {},
        onRecordDbClick=()=>{},
    }) {
    const table = useTable({
        api, actions, onRecordDbClick,
        initialSearches,
        initialGroups,
        columns: columns
    })
    const id = _.uniqueId()
    useEffect(()=>{
        table.refreshData()
    }, [])

    const memoTable = useMemo(()=>(
        <AutoSizer>
            {({width, height})=>(
                <HotTable
                    id={id}
                    ref={(ref)=>{
                        table.hot.current = ref
                        if(!hotRef || !!hotRef?.current){return}
                        hotRef.current = ref
                    }}
                    licenseKey={'non-commercial-and-evaluation'}
                    data={table.data}
                    columns={columns}
                    settings={hotSettings}
                    colHeaders
                    manualColumnMove
                    manualColumnResize
                    rowHeaders
                    readOnly
                    rowHeaderWidth={25}
                    width={width}
                    height={height}
                    outsideClickDeselects={false}
                    afterOnCellMouseDown={table.handleMouseDown}
                    afterOnCellMouseOver={table.handleMouseOver}
                    afterOnCellMouseOut={table.handleMouseOut}
                />
            )}
        </AutoSizer>
    ), [table.data])

    return (
        <Paper sx={{height: '100%'}}>
            <DefaultSpinner open={table.loading}/>
            <Grid container spacing={1} direction={"column"}
                  sx={{height: '100%', width: '100%'}}
            >
                <Grid item>
                    <GtToolbar table={table} hotRef={table.hot}/>
                </Grid>
                <Grid item sx={{flexGrow: 1, flex: '1 1 auto'}}>
                    {memoTable}
                </Grid>
                <Grid item>
                    <GtPaginator table={table}/>
                </Grid>
                <Grid item>
                    <ImageTooltip show={!!table.expandedImg} src={table.expandedImg}/>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default GenTable;