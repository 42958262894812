import {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {related_inquiries, sample_packages, sample_quotations, sample_sales_order, supplier_quotations} from "../data";
import {day_past, get_dummy_data, keepObjOrig, normalizePackage} from "../../../../utils/functions";
import {produce} from "immer";

useProductPackageManage.propTypes = {
    cat_no: PropTypes.string,
    cur_package: PropTypes.string,
    target_price: PropTypes.number,
};

function useProductPackageManage({
    cat_no= null,
    cur_package= null,
    target_price = null,
}) {
    const cur_package_qty = normalizePackage(cur_package)
    const [pkgChanged, setPkgChanged] = useState(0);
    const [suppQuoChanged, setSuppQuoChanged] = useState(0)
    const [relatedInquiriesChanged, setRelatedInquiriesChanged] = useState(0)
    // 产品规格
    const [packages, setPackages] = useState([]);
    // 报价没成交的
    const [quotations, setQuotations] = useState([]);
    // 客户产品成交数据
    const [sales_order, setSalesOrders] = useState([]);
    const [supplierQuotations, _setSupplierQuotations] = useState([]);
    const [relatedInquiries, _setRelatedInquiries] = useState([])

    const [prdSupplier, setPrdSupplier] = useState(null)

    const setSupplierQuotations = (data)=>{
        _setSupplierQuotations(data)
        setSuppQuoChanged((d)=>d+1)
    }

    const refreshSupplierQuotations = () =>{
        get_dummy_data(supplier_quotations).then((data) => _setSupplierQuotations(data));
        setSuppQuoChanged(0)
    }

    const addSupplierQuotations = () =>{
        setSupplierQuotations(produce((draft)=>{draft.push({})}))
    }

    const upsertSupplierQuotations = () =>{
        // TODO
        console.log(supplierQuotations)
        setSuppQuoChanged(0)
    }

    const supplierQuotationsSeries = useCallback(
        () =>{
            // TODO 同个供应商，需要区分不同货号报价
            if(!supplierQuotations.length){return []}
            const groupedData = Object.groupBy(supplierQuotations, (item)=>item.supplier_name)
            return Object.entries(groupedData).map(([name, value]) => ({
                name: `供应商: ${name}`, type: 'scatter',
                encode: {x: 0, y: 1, tooltip: [2, 0, 1, 3]},
                data: value.map(item => (
                        [
                            item.pkg_qty, item.price, item.supplier_name, item.created_at,
                            day_past(item.created_at)
                        ]))
            }))
        },
        [supplierQuotations],
    );


    const refreshPackage = () => {
        get_dummy_data(sample_packages).then((data) => {
            setPackages(data.map(keepObjOrig));
        });
        setPkgChanged(0)
    }

    const addPackage = () =>{
        setPackages(produce((draft)=>{
            draft.push({})
        }))
        setPkgChanged((d)=>d+1)
    }

    const upsertPackages = ()=>{
        // TODO
        console.log(packages)
        setPkgChanged(0)
    }

    const resetPkgCost = ()=>{
        setPackages(produce((draft)=>{
            draft.forEach((item)=>{
                item.cost = item?.__orig.cost
            })
        }))
    }

    const resetPkgPrice = ()=>{
        setPackages(produce((draft)=>{
            draft.forEach((item)=>{
                item.price = item?.__orig.price
            })
        }))
    }

    const refreshRelatedInquiries=()=>{
        get_dummy_data(related_inquiries).then((data) => _setRelatedInquiries(data));
        setRelatedInquiriesChanged(0)
    }
    const setRelatedInquiries = (data)=>{
        _setRelatedInquiries(data)
        setRelatedInquiriesChanged((d)=>d+1)
    }

    const loadData = () => {
        // 模拟获取远端数据
        refreshPackage()
        get_dummy_data(sample_quotations).then((data) => setQuotations(data));
        get_dummy_data(sample_sales_order).then((data) => setSalesOrders(data));
        refreshSupplierQuotations()
        refreshRelatedInquiries()
    };

    return {
        cat_no,
        cur_package,
        cur_package_qty,
        target_price,

        packages,
        quotations,
        sales_order,

        setPackages,
        setQuotations,
        setSalesOrders,

        prdSupplier, setPrdSupplier,
        supplierQuotations, setSupplierQuotations,
        relatedInquiries, setRelatedInquiries,

        supplierQuotationsSeries,
        refreshSupplierQuotations,
        addSupplierQuotations,
        upsertSupplierQuotations,

        refreshPackage,
        addPackage,
        resetPkgCost,
        resetPkgPrice,
        upsertPackages,

        refreshRelatedInquiries,

        loadData,

        pkgChanged, setPkgChanged,
        suppQuoChanged, setSuppQuoChanged,
        relatedInquiriesChanged, setRelatedInquiriesChanged,
    };
}

export default useProductPackageManage;