import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import Image from "../../../../components/Image/Image";
import FormPart from "../../../../components/base/FormPart";
import {useFormik} from "formik";

InquiryPrdInfo.propTypes = {
    
};

const fields = [
    {data: "cat_no", label: "货号", width: 12},
    {data: "package", label: "规格", width: 12},
    {data: "price", label: "指导价", width: 12},
    {data: "remark", label: "备注", width: 12},
]

const initialValues = {
    "cat_no": null,
    "remark": null,
}

function InquiryPrdInfo(props) {
    const formik = useFormik({
        initialValues: initialValues
    })
    return (
        <Stack spacing={1}>
            <Image height={"100px"}/>
            <Box>
                <Typography>中文: </Typography>
                <Typography>英文: </Typography>
                <Typography>CAS: </Typography>
                <Typography>规格: </Typography>
                <Typography>数量: </Typography>
                <Typography>纯度: </Typography>
                <Typography>检测: </Typography>
                <Typography>备注: </Typography>
                <Typography>货号: </Typography>
                <Typography>品牌: </Typography>
                <Typography>API: </Typography>
                <IconButton title={"复制Smiles"}>S</IconButton>
                <IconButton title={"复制Moltext"}>M</IconButton>
            </Box>
            <FormPart
                formik={formik}
                fields={fields}
            />
            <Box>
                <Button>提交</Button>
                <Button>保存</Button>
            </Box>
            <Box>
                <Button>新增产品</Button>
            </Box>
        </Stack>
    );
}

export default InquiryPrdInfo;