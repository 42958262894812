import make_request from "./utils";


class ExpressAPI{
    static async list({filters}) {
        return make_request(`/api/mod_express/express/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_express/express/${id}/`, null, {method: 'GET'})
    }

    static async get_attachments({id}){
        return make_request(`/api/mod_express/express/${id}/attachments/`, null, {method: 'GET'})
    }

}

export default ExpressAPI
