import React, {useState} from 'react';
import {Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, MenuProps, Select} from "@mui/material";
import {SearchMeta, Value} from "../useFilterTypes";
import {GTTable} from "../types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps:Partial<MenuProps> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    // getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

type Props = {
    table: GTTable
    field: string
    label: string
    options: any[]
    groupName?: string
    inputProps?: any
};

export function GtSelectSearchBase({table, field, label, options, groupName='', inputProps={}}:Props) {
    const searchMeta = table.filter.groups[groupName]?.searches.find((item: SearchMeta)=>item.field===field)
    const [value, setValue]:[Value[], Function] = useState(searchMeta?.value||[])
    const id = `${field}_GtSelectSearchBase_InputLabel`
    const onSearch=()=>{
        onBlur()
        table.filter.setPage('page', 1)
        table.refreshData()
    }
    // @ts-ignore
    const onChange =(e)=>{
        const {target} = e
        setValue(target.value)
    }
    const onBlur=()=>{
        const {filter} = table
        if(!value?.length){
            filter.cleanGroupFieldSearcher(field, groupName)
        }else{
            filter.setGroupFieldSearcher(field, 'in', value, groupName)
        }
    }
    return (
        <Grid>
            <FormControl size={"small"} {...inputProps}>
                <InputLabel id={id}>{label}</InputLabel>
                <Select
                    id={id}
                    multiple
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            onSearch()
                        }
                    }}
                    value={value || []}
                    MenuProps={menuProps}
                    renderValue={(selected:any[]) => selected.join(', ')}
                >
                    {options?.map(name=>(
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={value.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}



const GtSelectSearchField = (field: string, label: string, options: any[], groupName='', inputProps={sx:{
        width: '200px'
    }})=>(props:any)=>{
    return (
        <GtSelectSearchBase
            field={field}
            label={label}
            options={options}
            inputProps={inputProps}
            groupName={groupName}
            {...props}
        />
    );
}

export default GtSelectSearchField;