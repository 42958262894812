import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {HotTable} from "@handsontable/react";
import {CASEditor, CatNoEditor, ChemImageEditor, PackageEditor} from "./hotUtils/editors";
import {get_api_name, get_inquiry_types} from "./hotUtils/autocomplete";
import Handsontable from "handsontable";
import {EllipsisTextRenderer, ImageRenderer} from "../../../components/GenTable/renderers";
import {CatNoFieldRenderer, getClassNameByData, PackageFieldRenderer} from "./hotUtils/renderers";
import {InquiryItemTableStyle, SalesInquiryItemTableStyle} from "../styles";
import FuzzySearchPrdBtn from "./FuzzySearchPrdBtn";
import config from 'react-global-configuration';
import {afterChangesHandler} from "./hotUtils/afterChangesHandler";
import ImageTooltip from "../../../components/GenTable/ImageTooltip";
import CustomerInquiryAPI from "../../../api/CustomerInquiryAPI";


const cellTypes = {
    'chem_img': {
        renderer: ImageRenderer,
        editor: ChemImageEditor,
    },
    'inq_cat_no': {
        renderer: CatNoFieldRenderer,
        editor: CatNoEditor,
    },
    'inq_package': {
        renderer: PackageFieldRenderer,
        editor: PackageEditor
    }
}

for (let typeName in cellTypes) {
    Handsontable.cellTypes.registerCellType(typeName, cellTypes[typeName])
}

const quotation_status = config.get('quotation_status')
const [yellow, green, blue] = ['#f1ff58', '#5affb0', '#4178ff']

let init_columns = [
    {data: 'id', title: 'ID', width: 40, readOnly: true},
    {data: 'is_valid_quotation', type: 'checkbox', width: 20, backColor: yellow},
    // {data: 'sales_name', title: '销售名称', width: 80, type: 'autocomplete', source: get_sales_name, strict: true, allowEmpty: false},
    {data: 'inquiry_type', title: '询价类型', width: 70, type: 'autocomplete', source: get_inquiry_types, strict: true, backColor: yellow},
    {data: 'api_name', title: 'API名称', width: 90, type: 'autocomplete', source: get_api_name, filter: false, backColor: yellow},
    {data: 'cn_name', title: '中文名', width: 100, type: 'text', renderer: EllipsisTextRenderer, backColor: yellow},
    {data: 'en_name', title: '英文名', width: 100, type: 'text', renderer: EllipsisTextRenderer, backColor: yellow},
    {data: 'cas', title: 'CAS号', width: 80, editor: CASEditor, backColor: yellow},
    {data: 'brand', title: '品牌', width: 50, backColor: yellow},
    {data: 'cat_no', title: '货号', width: 100, type: 'inq_cat_no', backColor: yellow},
    {data: 'package', title: '规格', type: 'inq_package', width: 60, backColor: yellow},
    {data: 'quantity', title: '数量', type: 'numeric', width: 50, backColor: yellow},
    {data: 'remark', title: '询价备注', type: 'text', width: 100, renderer: EllipsisTextRenderer, backColor: yellow},
    {data: 'quotation.cost', title: '成本', type: 'numeric', readOnly: true, width: 80, backColor: blue},
    {data: 'purity', title: '纯度', type: 'text', width: 60, renderer: EllipsisTextRenderer, backColor: yellow},
    {data: 'inter_quo.purity', title: '处理纯度', type: 'text', width: 60, readOnly: true, backColor: green},
    {data: 'quotation.price', title: '市场价', type: 'numeric', readOnly: true, width: 80, backColor: blue},
    {data: 'quotation.delivery', title: '货期', type: 'text', width: 80, backColor: blue},
    {data: 'inter_quo.remark', title: '处理备注', type: 'text', width: 80, readOnly: true, renderer: EllipsisTextRenderer, backColor: green},
    {data: 'inter_quo.cat_no', title: '处理货号', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'inter_quo.package', title: '处理规格', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'inter_quo.is_synthetic', title: '是否定制', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'quotation.offer_price', title: '报价', type: 'numeric', width: 80, backColor: blue},
    {data: 'quotation.remark', title: '报价备注', type: 'text', width: 80, renderer: EllipsisTextRenderer, backColor: blue},
    {data: 'quotation.offer_price_sum', title: '报价小计', type: 'numeric', width: 80, backColor: blue},
    {data: 'quotation.discount', title: '折扣', type: 'numeric', width: 80, backColor: blue},
    {data: 'quotation.price_expiry_date', title: '价格有效期', type: 'date', width: 80, backColor: blue},
    {data: 'inter_quo.price', title: '市场价', type: 'text', width: 80, backColor: green},
    {data: 'inter_quo.delivery', title: '处理货期', type: 'text', width: 80, backColor: green},
    {data: 'is_direct_offer', title: '直接报价', type: 'checkbox', width: 60, backColor: yellow},
    {data: 'inter_quo.handler', title: '策划负责人', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'inter_quo.status', title: '内部状态', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'img', title: '结构式', type: 'chem_img', width: 150, backColor: yellow},
    {data: 'inter_quo.en_name', title: '处理英文名', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'inter_quo.img', title: '处理结构式', type: 'img', width: 80, readOnly: true, backColor: green},
    {data: 'quotation.status_info', title: '报价状态', type: 'autocomplete', source: quotation_status, width: 160, backColor: blue},
    {data: 'inter_quo.not_provide', title: '不能提供', type: 'text', width: 80, readOnly: true, backColor: green},
    {data: 'competitor_info', title: '竞品信息', type: 'text', width: 80},
    {data: 'competitor_price', title: '竞品价格', type: 'text', width: 80},
]

SalesInquiryItemTable.propTypes = {
    formik: PropTypes.object.isRequired,
};

function ItemTableHeader() {
    return (
        <Grid container spacing={1}>
            <Grid item>
                <Typography variant={"h5"}>询价明细</Typography>
            </Grid>
            <Grid item>
                <Grid container>
                    <Typography variant={'subtitle2'} style={{backgroundColor: yellow}}>询价单信息</Typography>
                    <Typography variant={'subtitle2'} style={{backgroundColor: green}}>处理单信息</Typography>
                    <Typography variant={'subtitle2'} style={{backgroundColor: blue}}>报价单信息</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

function SalesInquiryItemTable(props) {
    const hot = useRef()
    const {formik, setChanged} = props
    const [fixedColumnsLeft, setFixedColumnsLeft] = useState(5)
    const [expandedImg, setExpandedImg] = useState('')
    const [columns, setColumns] = useState(init_columns)

    const handleDeleteRows = async (key, options)=>{
        const [{start, end}] = options
        const newChildren = await formik.values.children?.reduce(async (arr, item, index)=> {
            if(index < start.row || index > end.row){return (await arr).concat(item)}
            if(!item?.id){return arr}
            try{
                await CustomerInquiryAPI.delete_inquiry_detail(formik.values.id, item.id);
            }catch (e) {
                return (await arr).concat(item)
            }
            return arr
        }, [])
        formik.setFieldValue('children', newChildren)
    }

    const fixColumnsLeft = (key, options)=>{
        const [{start}] = options
        const instance = hot.current?.hotInstance
        if(!instance){return}
        setFixedColumnsLeft(start.col + 1)
    }

    const handleMouseOver = (e, {row, col})=>{
        const instance = hot.current?.hotInstance
        if(!instance){return}
        const data_type = instance.getDataType(row, col, row, col)
        if(data_type==='img' || data_type==='chem_img'){
            const value = instance.getDataAtCell(row, col)
            setExpandedImg(value)
        }else{
            setExpandedImg('')
        }
    }

    const afterGetColHeader = (col, TH)=>{
        const hotInstance = hot.current?.hotInstance
        if(!hotInstance){return}
        const {columns} = hotInstance.getSettings()
        TH.style.backgroundColor = columns[col]?.backColor
    }
    const persistentStateLoad = (key, valuePlaceholder)=>{
        const {value} = valuePlaceholder
        if(key==='manualColumnMove'){
            if(!value){return}
            if(columns.length !== value.length){return}
            setColumns(prevColumns=>value.map(i=>prevColumns[i]))
        }
    }

    return (
        <Grid item>
            <Card sx={SalesInquiryItemTableStyle}>
                <CardHeader
                    title={<ItemTableHeader/>}
                    action={<FuzzySearchPrdBtn formik={formik} />}
                />
                <CardContent>
                    <Grid container>
                        <Grid item style={{flexGrow: 1}}>
                            <HotTable
                                sx={InquiryItemTableStyle}
                                id="inquiry_item"
                                licenseKey={'non-commercial-and-evaluation'}
                                ref={hot}
                                data={formik.values.children}
                                minSpareRows={1}
                                dateFormat={'YYYY-MM-DD'}
                                height={400}
                                width={'100%'}
                                rowHeights={60}
                                fixedColumnsLeft={fixedColumnsLeft}
                                colHeaders={true}
                                rowHeaders={true}
                                rowHeaderWidth={20}
                                persistentState={true}
                                persistentStateLoad={persistentStateLoad}
                                contextMenu={{
                                    items: {
                                        'deleteRows': {name: 'Delete Rows', callback: handleDeleteRows},
                                        'fixColumnsLeft': {name: 'Fix Columns Left', callback: fixColumnsLeft}
                                    }
                                }}
                                filters={true}
                                dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
                                manualColumnResize={true}
                                manualColumnMove={true}
                                autoRowSize={false}
                                outsideClickDeselects={false}
                                columns={columns}
                                afterChange={(changes, source)=>{
                                    if(source!=='loadData'){
                                        setChanged((prevState)=>prevState+1)
                                        afterChangesHandler(hot, changes)
                                    }
                                }}
                                afterOnCellMouseOver={handleMouseOver}
                                afterOnCellMouseOut={()=>{setExpandedImg('')}}
                                afterGetColHeader={afterGetColHeader}
                                cells={(row)=>{
                                    let cell = {}
                                    const instance = hot.current?.hotInstance
                                    if(!instance){return}
                                    const rowData = instance.getSourceDataAtRow(row)
                                    cell.className = getClassNameByData(rowData)
                                    return cell
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <ImageTooltip show={!!expandedImg} src={expandedImg}/>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default SalesInquiryItemTable;