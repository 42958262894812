import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, IconButton, Stack} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from "@mui/icons-material/Download";

ProductHeaderRenderer.propTypes = {

};

function ProductHeaderRenderer({label, columnData:{unmatchedPrdCount, onAddBtnClick}}) {
    return (
        <Stack>
            <Box>
                <span className={"ReactVirtualized__Table__headerTruncatedText"}>
                    {label}
                </span>
                <span  className={"ReactVirtualized__Table__headerTruncatedText"} style={{color: "red"}}>
                    {unmatchedPrdCount?`(${unmatchedPrdCount})`:''}
                </span>
            </Box>
            <Box sx={{
                '.MuiIconButton-root': {
                    paddingLeft: '0',
                    paddingRight: '0',
                },
                '.MuiSvgIcon-root': {
                    width: '0.8em',
                    height: '0.8em',
                }
            }}>
                <IconButton title={"打包下载"}>
                    <DownloadIcon />
                </IconButton>
                <Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={onAddBtnClick}>
                    增加供应商
                </Button>
            </Box>
        </Stack>
    );
}

export default ProductHeaderRenderer;