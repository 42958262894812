import make_request from "../utils";


class SaleBillAPI{
    static async list({filters}) {
        return make_request(`/api/u8addon/sales_bill/`, filters)
    }

    static async get({id}){
        return make_request(`/api/u8addon/sales_bill/${id}/`, null, {method: 'GET'})
    }

    static async nuo_nuo_gen_invoice({id}){
        return make_request(`/api/u8addon/sales_bill/${id}/nuo_nuo_gen_invoice/`, null, {method: 'POST'})
    }

    static async nuo_nuo_refresh_status({id}){
        return make_request(`/api/u8addon/sales_bill/${id}/nuo_nuo_refresh_status/`, null, {method: 'POST'})
    }

}

export default SaleBillAPI
