import React from 'react';
import PropTypes from 'prop-types';

InquiryHeaderRenderer.propTypes = {

};

function InquiryHeaderRenderer({label, columnData: {quotableInquiry, quotedInquiry, totalInquiry}}) {
    return (
        <div>
            <span>{label}</span>
            <br/><span>可报价/已报价/总数</span>
            <br/><span>{quotableInquiry||0}/{quotedInquiry||0}/{totalInquiry||0}</span>
        </div>
    );
}

export default InquiryHeaderRenderer;