import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Editor} from "ketcher-react";
import 'ketcher-react/dist/index.css'
import config from "react-global-configuration";
import {RemoteStructServiceProvider} from "ketcher-core";


const URL_PREFIX = config.get('url_prefix', '')
const defaultStructServiceProvider = new RemoteStructServiceProvider(`${URL_PREFIX}/api/ketcher/v2`)


MoleculeEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    structServiceProvider: PropTypes.object,
    buttons: PropTypes.object,
};

function MoleculeEditor({value, onChange, buttons, structServiceProvider = defaultStructServiceProvider}) {
    const [editorReady, setEditorReady] = useState(false)
    const ref = useRef()

    const handleChange = async ()=>{
        if(typeof onChange !== 'function'){return}
        const ketcher = ref?.current
        if(!ketcher){return}
        const smiles = await ketcher.getSmiles()
        if(!smiles){
            onChange(null)
        }else{
            const struct = ketcher.editor.struct()
            let moltext
            window.ketcher = ketcher
            if(struct.isRxn()){
                moltext = await ketcher.getRxn()
            }else{
                moltext = await ketcher.getMolfile()
            }
            onChange(moltext)
        }
    }

    const waitForEditor = ()=>{
        if(editorReady){return}
        const ketcher = ref?.current
        if(typeof ketcher !== 'undefined'){
            setEditorReady(true)
        }else{
            setTimeout(waitForEditor, 100);
        }
    }

    useEffect( ()=>{
        if(!editorReady){return}
        const ketcher = ref?.current
        if(!ketcher){return}
        ketcher.getSmiles().then(smiles=>{
            if(!smiles && !value){return}
            ketcher.getMolfile().then(moltext=>{
                if(moltext===value || !value){return}
                ketcher.setMolecule(value)
            })
        })
    }, [value, editorReady])


    useEffect(()=>{
        waitForEditor()
    }, [])

    const onInit = (ketcher)=>{
        ref.current = ketcher
        ketcher.editor.subscribe('change', operations=>{
            console.log(operations)
            handleChange()
        })
    }


    return (
        <div onBlur={handleChange} style={{height: 'inherit'}}>
            <Editor
                staticResourcesUrl={process.env.PUBLIC_URL}
                structServiceProvider={structServiceProvider}
                buttons={buttons}
                errorHandler={()=>{}}
                onInit={onInit}
            />
        </div>
    );
}

export default MoleculeEditor;