import React from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/material";

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,

    width: PropTypes.string,
    height: PropTypes.string,
};

const ExpandImg = styled('img')(({theme})=>({
    maxHeight: '100%',
    maxWidth: '100%',
}))

function Image({src, alt= 'N/A', width = '100%', height = '200px'}) {
    return (
        <div style={{width, height, textAlign: "center"}}>
            <ExpandImg src={src} alt={alt}/>
        </div>
    );
}

export default Image;