import {createSlice} from '@reduxjs/toolkit'

export const tabSlice = createSlice({
    name: 'tab',
    initialState: {
        tabs: [],
        curTab: 0,
    },
    reducers: {
        initialTabs: (state, {payload})=>{
            state.tabs = JSON.parse(localStorage.frameTabs || '[]')
        },
        addTab: (state, {payload: {component_props={}, title, url}})=>{
            const index = state.tabs.findIndex((tab)=>tab.url === url)
            if(index!==-1){
                state.curTab = index
                return
            }
            state.tabs.push({id: Math.random().toString(36).slice(2), component_props, title, url})
            state.curTab = state.tabs.length - 1
            const tab = state.tabs[state.curTab]
            if(tab?.url){window.history.pushState(null, '', `/#${tab.url}`)}
            localStorage.frameTabs = JSON.stringify(state.tabs)
        },
        removeTab: (state, {payload})=>{
            if(state.curTab >= state.tabs.length - 1){
                state.curTab = state.tabs.length - 2
            }
            state.tabs = state.tabs.filter((item, index)=>index !== payload)
            const tab = state.tabs[state.curTab]
            if(tab?.url){window.history.pushState(null, '', `/#${tab.url}`)}
            localStorage.frameTabs = JSON.stringify(state.tabs)
        },

        setActiveTab: (state, {payload})=>{
            if(payload < state.tabs.length){
                state.curTab = payload
            }else{
                state.curTab = state.tabs.length - 1
            }
            const tab = state.tabs[state.curTab]
            if(tab?.url){window.history.pushState(null, '', `/#${tab.url}`)}
        }
    },
})

export const {
    initialTabs,
    addTab, removeTab, setActiveTab
} = tabSlice.actions

export default tabSlice.reducer