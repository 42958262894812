import Handsontable from "handsontable";

export const JsonParser = (instance, td, row, col, prop, value, cellProperties)=>{
    let ret = value
    try {
        ret = JSON.parse(value)
        if(typeof ret !== 'string'){
            ret = JSON.stringify(ret)
        }
    }catch (e) {}
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, ret, cellProperties]);
}

export function getColoredClassName(rowData){
    let className = 'htCellBGWhite'
    if(rowData?.status === "SUCCESS"){
        className = 'htCellBGGreen'
    // }else if(rowData?.status === "SUCCESS"){
    //     className = 'htCellBGPurple'
    }else if (rowData?.status === "FAILURE"){
        className = 'htCellBGRed'
    }else if(rowData?.status === "STARTED"){
        className = 'htCellBGBlue'
    }else if(rowData?.status === "PENDING"){
        className = 'htCellBGYellow'
    }
    return className
}