import React from 'react';
import PropTypes from 'prop-types';
import {Stack, Box, IconButton, Checkbox, Chip, FormControlLabel, FormGroup, styled} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import NotInterestedIcon from "@mui/icons-material/NotInterested";

CellRenderer.propTypes = {
    rowData: PropTypes.object,
    columnData: {
        openEditDialog: PropTypes.func,
    }
};


const CellHighlightStack = styled(Stack, {
    shouldForwardProp: (prop)=>(['supp_inq_status', 'isDefaultSupplier'].indexOf(prop)===-1)
})(({supp_inq_status, isDefaultSupplier=false, theme})=>({
    height: '100%',
    backgroundColor: (supp_inq_status===0?'rgba(255,153,0,0.21)':
        supp_inq_status===1?'rgba(4,154,246,0.22)':
            supp_inq_status===2?'rgba(0,255,50,0.3)':
                supp_inq_status===3?'rgba(167,167,167,0.49)':null),
    ...(
        isDefaultSupplier?{
            borderLeft: 'solid 8px #f0d800'
        }:{}
    )
}))

const color_map = {
    "-1": "error",
    "1": "success",
    "10": "primary",
    "20": "info",
}

function CellRenderer({rowData, dataKey, columnData:{openEditDialog, item}}) {
    const supp_inq_idx = rowData?.supplier_inquiries?.findIndex(item=>item.supplier_id === dataKey)
    const supp_inq = rowData?.supplier_inquiries?rowData?.supplier_inquiries[supp_inq_idx]:null
    const supp_inq_status = supp_inq?.status

    const isDefaultSupplier = !!rowData?.default_supplier_id && rowData?.default_supplier_id === item?.id

    return (
        <CellHighlightStack supp_inq_status={supp_inq?.status} isDefaultSupplier={isDefaultSupplier}>
            <Box>
                <FormGroup sx={{marginLeft: '11px'}}>
                    <FormControlLabel control={<Checkbox checked={!!supp_inq}/>} label="要询" />
                </FormGroup>
            </Box>
            <Box>
                <b>状态: </b>{(supp_inq_status===0?'待导出':
                supp_inq_status===1?'待报价':
                    supp_inq_status===2?'已报价':
                        supp_inq_status===3?'不能提供':null)}
            </Box>
            <Box>
                <IconButton variant="contained" title={"编辑"} disabled={!supp_inq} onClick={openEditDialog}>
                    <EditIcon />
                </IconButton>
                <IconButton variant="contained" title={"批量关闭"} disabled={!supp_inq}>
                    <NotInterestedIcon />
                </IconButton>
            </Box>
            <Box>
                {supp_inq?.match_reasons?.sort((a, b)=>(a?.level - b?.level))?.map((item)=>(
                    <Chip label={item?.label} size={"small"} color={color_map[item?.level||"30"]||"default"}/>
                ))}
            </Box>
        </CellHighlightStack>
    );
}

export default CellRenderer;