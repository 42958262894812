import React from 'react';
import PropTypes from 'prop-types';
import GenTable from "../../../components/GenTable/GenTable";
import TextSearchField from "../../../components/GenTable/searchFields/GTToolbarSearchField";
import {EllipsisTextRenderer} from "../../../components/GenTable/renderers";
import SalesOrderAPI from "../../../api/u8/SalesOrderAPI";

SalesOrderTable.propTypes = {

};

const columns = [
    {data: 'isosid', title: 'ID', width: 20, type: 'numeric'},
    {data: 'parent.ddate', title: '销售日期', width: 80, type: 'date'},
    {data: 'parent.dverifydate', title: '销售审核日期', width: 80, type: 'date'},
    {data: 'parent.csocode', title: '销售订单号', width: 120},
    {data: 'parent.ccusname', title: '客户名称', width: 80},
    {data: 'parent.cmemo', title: '订单备注', width: 80, renderer: EllipsisTextRenderer},
    {data: 'parent.cmaker', title: '制单人', width: 80},
    {data: 'cinvcode', title: '存货编码', width: 90, renderer: EllipsisTextRenderer},
    {data: 'cfree1', title: '规格', width: 90},
    {data: 'iquantity', title: '数量', width: 60, type: 'numeric'},
    {data: 'iunitprice', title: '单价', width: 60, type: 'numeric'},
    {data: 'isum', title: '含税合计', width: 60, type: 'numeric'},
    {data: 'cmemo', title: '行备注', width: 120},
]

function SalesOrderTable(props) {

    return (
        <React.Fragment>
            <GenTable
                api={SalesOrderAPI.list}
                hotSettings={{
                    rowHeights: 20,
                    stretchH: 'all',
                }}
                columns={columns}
                actions={[
                    TextSearchField('parent.csocode', '销售单号', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('parent.ccusname', '客户名称', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('parent.cmaker', '制单人', 'contains', '',{sx:{width: '150px'}}),
                ]}
                // initialGroups={initialSearchGroup}
            />
        </React.Fragment>
    );
}

export default SalesOrderTable;