import React from "react"
import ReactEcharts from "echarts-for-react";

import {tooltipFormatter} from "../utils/formatters"
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography} from "@mui/material";


export default function PackageCharts(
    {pkgManager}
){
    const onChartReady = (chart)=>{
        chart.on('resize', function () {
            
        })
    }
    return (
        <Stack>
            <Stack direction={"row"} sx={{textAlign: 'center'}}>
                <Typography variant={"h6"}>供应商: {pkgManager?.prdSupplier}</Typography>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel value="linear" control={<Radio />} label="线性" />
                    <FormControlLabel value="logarithmic" control={<Radio />} label="对数" />
                    <FormControlLabel disabled value="curve" control={<Radio />} label="非线性" />
                </RadioGroup>
            </Stack>
            <ReactEcharts
                onChartReady={onChartReady}
                option={{
                    legend: {},
                    // tooltip: {},
                    tooltip: {
                        backgroundColor: "rgba(255,255,255,0.7)",
                        formatter: tooltipFormatter,
                    },
                    dataset: [
                        {
                            dimensions: [
                                { name: "src", type: "ordinal" },
                                { name: "package_qty" },
                                { name: "price" },
                            ],
                            source: pkgManager?.packages || [],
                        },
                        {
                            dimensions: [
                                { name: "src", type: "ordinal" },
                                { name: "package_qty" },
                                { name: "price" },
                                { name: "day_past" },
                            ],
                            source: pkgManager?.quotations || [],
                        },
                        {
                            dimensions: [
                                { name: "src", type: "ordinal" },
                                { name: "package_qty" },
                                { name: "price" },
                                { name: "day_past" },
                            ],
                            source: pkgManager?.sales_order || [],
                        },
                        {
                            dimensions: [
                                { name: "src", type: "ordinal" },
                                { name: "package_qty" },
                                { name: "cost" },
                            ],
                            source: pkgManager?.packages || [],
                        },
                        {
                            source: [[pkgManager?.cur_package_qty,  pkgManager?.target_price]]
                        },
                        {
                            dimensions: [
                                { name: "supplier_name", type: "ordinal" },
                                { name: "pkg_qty" },
                                { name: "price" },
                            ],
                            source: pkgManager?.supplierQuotations || [],
                        },
                    ],
                    xAxis: {
                        type: "value",
                        name: "规格(mg)",
                        axisLabel: { formatter: "{value} mg" },
                    },
                    yAxis: { type: "value", name: "价格(CNY)" },
                    dataZoom: [
                        { type: "inside" },
                        {
                            id: "dataZoomX",
                            type: "slider",
                            xAxisIndex: [0],
                            filterMode: "filter",
                        },
                        {
                            id: "dataZoomY",
                            type: "slider",
                            yAxisIndex: [0],
                            filterMode: "empty",
                        },
                    ],
                    // 时间远近颜色深浅
                    visualMap: [
                        {
                            show: false,
                            dimension: 3,
                            seriesIndex: 1,
                            min: -365,
                            max: 0,
                            inRange: {
                                opacity: [0.2, 0.8],
                            },
                        },
                        {
                            show: false,
                            dimension: 3,
                            seriesIndex: 2,
                            min: -365,
                            max: 0,
                            inverse: true,
                            inRange: {
                                opacity: [0.2, 0.8],
                            },
                        },
                        {
                            show: false,
                            dimension: 3,
                            seriesIndex: 3,
                            min: -365,
                            max: 0,
                            inverse: true,
                            inRange: {
                                opacity: [0.2, 0.8],
                            },
                        },
                        {
                            show: false,
                            dimension: 3,
                            seriesIndex: 4,
                            min: -365,
                            max: 0,
                            inverse: true,
                            inRange: {
                                opacity: [0.2, 0.8],
                            },
                        },
                    ],
                    series: [
                        {
                            type: "line",
                            color: "#ffe900",
                            name: "指导价",
                            datasetIndex: 0,
                            encode: { x: "package_qty", y: "price" },
                            markArea: {
                                itemStyle: {
                                    color: "rgba(255, 173, 177, 0.4)",
                                },
                                data: [
                                    [
                                        {
                                            name: "当前规格",
                                            xAxis: pkgManager?.cur_package_qty - 1,
                                        },
                                        {
                                            xAxis: pkgManager?.cur_package_qty + 1,
                                        },
                                    ],
                                ],
                            },
                        },
                        {
                            type: "scatter",
                            color: "#ffa673",
                            name: "报价",
                            datasetIndex: 1,
                            symbolSize: 20,
                            encode: { x: "package_qty", y: "price" },
                        },
                        {
                            type: "scatter",
                            color: "#06a401",
                            name: "成交价",
                            datasetIndex: 2,
                            encode: { x: "package_qty", y: "price" },
                        },
                        {
                            type: "line",
                            color: "#08789e",
                            name: "成本",
                            datasetIndex: 3,
                            encode: { x: "package_qty", y: "cost" },
                        },
                        {
                            type: "scatter",
                            symbol: 'path://M23.6 2c-3.363 0-6.258 2.736-7.599 5.594-1.342-2.858-4.237-5.594-7.601-5.594-4.637 0-8.4 3.764-8.4 8.401 0 9.433 9.516 11.906 16.001 21.232 6.13-9.268 15.999-12.1 15.999-21.232 0-4.637-3.763-8.401-8.4-8.401z'                    ,
                            color: "#9e1500",
                            name: "目标价",
                            datasetIndex: 4,
                            encode: { x: "package_qty", y: "cost" },
                        },
                        ...(pkgManager?pkgManager.supplierQuotationsSeries():[])
                    ],
                }}
            />
        </Stack>
    )
}