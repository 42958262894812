import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Backdrop, Button, CircularProgress, DialogActions, DialogContent, Typography} from "@mui/material";
import ButtonWithModel from "../../../../components/base/ButtonWithModel";
import {useDispatch} from "react-redux";
import {popErrorMsg} from "../../../../redux/errorMsg/errMsgSlice";
import DingtalkUserAPI from "../../../../api/dingtalk/DingtalkUserAPI";

SyncFinereportUserBtn.propTypes = {
    
};

function SyncFinereportUserBtn(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(false)
    const dispatch = useDispatch()

    const onOpen = ()=>{
        setBtnDisabled(false)
        setLoading(false)
        const {hotRef} = props
        const instance = hotRef.current.hotInstance
        let [[row_start, , row_end, ]] = instance.getSelected()
        if(row_start===-1){
            row_start =0
        }
        const size = row_end - row_start + 1
        let rows = [...Array(size).keys()].map(i=>instance.getSourceDataAtRow(row_start+i))
        const s = new Set([])
        rows = rows.filter((value, index, self) => (
            s.has(value?.id)?false:(s.add(value?.id) && true)
        ) )
        setData(rows)
    }

    const onSync = async ()=>{
        // 一个个ID调用API
        let err_msgs = []
        setLoading(true)
        for (let idx in data){
            const item = data[idx]
            if(!item?.id){continue}
            await DingtalkUserAPI.sync_finereport_user({id: item?.id})
                .catch(e=>{err_msgs.push(item?.name+": "+e)})
        }
        if (err_msgs.length>0){
            dispatch(popErrorMsg(err_msgs.join('\n')))
        }
        setLoading(false)
        setBtnDisabled(true)
    }

    return (
        <ButtonWithModel
            label={'同步用户到BI'}
            title={'同步用户到BI'}
            maxWidth={'lg'}
            onOpen={onOpen}
        >
            <React.Fragment>
                <Backdrop open={loading} onClick={() => {
                }}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <DialogContent>
                    <Typography>将同步以下用户({data.length})</Typography>
                    {data.map(item => (
                        <Typography>{item?.userid} / {item?.name}</Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={onSync} disabled={btnDisabled}>确认同步</Button>
                </DialogActions>
            </React.Fragment>
        </ButtonWithModel>
    );
}

export default SyncFinereportUserBtn;