import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import TabPanelDetail from "../../../../components/Tab/TabPanelDetail";
import {Table, AutoSizer, Column} from "react-virtualized";
import 'react-virtualized/styles.css'
import NoContentRenderer from "./utils/NoContentRenderer";
import InquiryRenderer from "./utils/InquiryRenderer";
import {Box, Stack} from "@mui/material";
import CellRenderer from "./utils/CellRenderer";
import ProductRenderer from "./utils/ProductRenderer";
import SupplierRenderer from "./utils/SupplierRenderer";
import './InquiryOrderSupplierSelection.css';
import ProductHeaderRenderer from "./utils/ProductHeaderRenderer";
import InquiryOrderHeader from "./utils/InquiryOrderHeader";
import InquiryHeaderRenderer from "./utils/inquiryHeaderRenderer";
import UseInquiryOrderSupplierSelection from "./useInquiryOrderSupplierSelection";
import AddSupplierDialog from "./utils/AddSupplierDialog";
import EditSupplierQuotationDialog from "./utils/EditSupplierQuotationDialog";

InquiryOrderSupplierSelection.propTypes = {

};


function InquiryOrderSupplierSelection(props) {
    const table = useRef(null);
    const [inqColumnWidth, setInqColumnWidth] = useState(240)
    const [prdColumnWidth, setPrdColumnWidth] = useState(180)
    const [suppColumnWidth, setSuppColumnWidth] = useState(140)
    const [rowHeight, setRowHeight] = useState(180)
    const [overscanColumnCount, setOverscanColumnCount] = useState(0)
    const [overscanRowCount, setOverscanRowCount] = useState(0)
    const [scrollToColumn, setScrollToColumn] = useState(0)
    const [scrollToRow, setScrollToRow] = useState(0)

    const [openAddSupplierDialog, setOpenAddSupplierDialog] = useState(false)
    const [openEditSupplierQuotation, setOpenEditSupplierQuotation] = useState(false);

    const selection = UseInquiryOrderSupplierSelection()

    const rowCount = selection.data.length
    const rowGetter = ({index}) => selection.data[index]

    useEffect(() => {
        selection.refreshData()
    }, []);

    const totalInquiry = selection.data?.length
    const quotableInquiry = selection.data?.filter((item)=>item.status===1)?.length
    const quotedInquiry = selection.data?.filter((item)=>(item.status===2 || item.status===3))?.length
    const unmatchedPrdCount = selection.data?.filter((item)=>(item.cat_no === null))?.length

    const tableWidth = inqColumnWidth + prdColumnWidth + selection.columns.length * suppColumnWidth

    const handleOpenEditDialog = () =>{
        setOpenEditSupplierQuotation(true)
    }

    return (
        <TabPanelDetail>
            <Stack sx={{height: '100%'}}>
                <InquiryOrderHeader
                    totalInquiry={totalInquiry} quotableInquiry={quotableInquiry} quotedInquiry={quotedInquiry}
                    unmatchedPrdCount={unmatchedPrdCount}
                />
                <AddSupplierDialog open={openAddSupplierDialog} handleClose={()=>{
                    setOpenAddSupplierDialog(false)
                }}/>
                <EditSupplierQuotationDialog open={openEditSupplierQuotation} handleClose={()=>{
                    setOpenEditSupplierQuotation(false)
                }}/>
            <Box sx={{flexGrow: 1}}>
            <Box sx={{height: '100%'}}>
            <AutoSizer>
                {({width, height}) => (
                    <Table
                        ref={table}
                        headerHeight={80}
                        rowHeight={rowHeight}
                        noRowsRenderer={NoContentRenderer}
                        overscanRowCount={overscanRowCount}
                        overscanColumnCount={overscanColumnCount}
                        rowGetter={rowGetter}
                        rowCount={rowCount}
                        scrollToIndex={scrollToRow}
                        scrollToColumn={scrollToColumn}
                        // sort={this._sort}
                        // sortBy={sortBy}
                        // sortDirection={sortDirection}
                        height={height}
                        // width={tableWidth>width?tableWidth:width}
                        width={width}
                    >
                        <Column
                            label="ID"
                            cellDataGetter={({rowData}) => rowData.id}
                            dataKey="index"
                            width={35}
                        />
                        <Column
                            label="询价信息"
                            dataKey={""}
                            columnData={{quotableInquiry, quotedInquiry, totalInquiry}}
                            headerRenderer={InquiryHeaderRenderer}
                            cellRenderer={InquiryRenderer}
                            width={inqColumnWidth}
                            // maxWidth={200}
                        />
                        <Column
                            label="产品信息"
                            dataKey={""}
                            columnData={{unmatchedPrdCount, onAddBtnClick: ()=>{setOpenAddSupplierDialog(true)}}}
                            headerRenderer={ProductHeaderRenderer}
                            cellRenderer={ProductRenderer}
                            width={prdColumnWidth}
                            // maxWidth={180}
                        />
                        {selection.columns.map((item)=>(
                            <Column
                                label={item?.supplier_name}
                                dataKey={item?.supplier_id}
                                headerRenderer={SupplierRenderer}
                                cellRenderer={CellRenderer}
                                width={suppColumnWidth}
                                columnData={{item, openEditDialog: handleOpenEditDialog}}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
            </Box>
            </Box>
            </Stack>
        </TabPanelDetail>
    );
}

export default InquiryOrderSupplierSelection;