import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Stack, styled} from "@mui/material";
import useProductPackageManage from "./components/useProductPackageManage";
import SupplierQuotation from "./components/SupplierQuotation";
import RelatedInquiryTable from "./components/RelatedInquiryTable";
import PackageManage from "./components/PackageManage";
import QuoGuideline from "./components/QuoGuideline";
import TabPanelDetail from "../../../components/Tab/TabPanelDetail";
import InquiryPrdInfo from "./components/InquiryPrdInfo";

InternalQuotationDialog.propTypes = {
    id: PropTypes.string,
};

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

function InternalQuotationDialog({id}) {
    const pkgManager = useProductPackageManage({
        cat_no: 'C4X-14482',
        cur_package: '50mg'
    })

    useEffect(() => {
        pkgManager?.loadData()
    }, []);

    return (
        <TabPanelDetail
            actions={<QuoGuideline pkgManager={pkgManager}/>}
        >
        <Grid container direction={"row"} spacing={1}>
            <Grid item xs={2}>
                <InquiryPrdInfo />
            </Grid>
            <Grid item xs={10}>
                <Stack>
                    <Item>
                        <SupplierQuotation pkgManager={pkgManager}/>
                    </Item>
                    <Item>
                        <PackageManage pkgManager={pkgManager}/>
                    </Item>
                    <Item>
                        <RelatedInquiryTable pkgManager={pkgManager}/>
                    </Item>
                </Stack>
            </Grid>
        </Grid>
        </TabPanelDetail>
    );
}

export default InternalQuotationDialog;