import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import MenuManageAPI from "../../../../api/sys/MenuManageAPI";
import GroupManageAPI from "../../../../api/sys/GroupManageApi";
import UserManageAPI from "../../../../api/sys/UserManageAPI";
import {useDispatch} from "react-redux";
import {endLoading, startLoading} from "../../../../redux/globalLoading/globalLoadingSlice";

useMenuTree.propTypes = {

};

function useMenuTree(props) {
    const dispatch = useDispatch()
    const [menu, setMenu] = useState([])
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([])
    const [group_set, setGroupSet] = useState(new Set())
    const [user_set, setUserSet] = useState(new Set())
    const [selectedMenuId, setSelectedMenuId] = useState(null);

    useEffect(() => {
        if(selectedMenuId == null){return}
        updateMenuGroupsUsers({id: selectedMenuId})
    }, [selectedMenuId]);


    const refreshData = ()=>{
        dispatch(startLoading())
        MenuManageAPI.get_tree().then(data=>{
            setMenu(data)
        }).finally(()=>dispatch(endLoading()))
    }

    const refreshGroupData = ()=>{
        dispatch(startLoading())
        GroupManageAPI.list({}).then(data=>{
            setGroups(data.data)
        }).finally(()=>dispatch(endLoading()))
    }
    const refreshUserData = ()=>{
        dispatch(startLoading())
        UserManageAPI.list({filters:{per_page: 500}}).then(data=>{
            setUsers(data.data)
        }).finally(()=>dispatch(endLoading()))
    }

    const onSelectMenu = ({id})=>{
        setSelectedMenuId(id)
    }

    const updateMenuGroupsUsers = ({id}) =>{
        if(id==null){return}
        dispatch(startLoading())
        // 获取拥有菜单权限得用户和分组
        MenuManageAPI.getUsersWithPerm({id}).then(data=>{
            setUserSet(prevState => new Set(data.map(item=>item?.id)))
        })
        MenuManageAPI.getGroupsWithPerm({id}).then(data=>{
            setGroupSet(prevState => new Set(data.map(item=>item?.id)))
        }).finally(()=>dispatch(endLoading()))
    }

    const groupHasPerm = (id)=>{
        return group_set.has(id)
    }

    const userHasPerm = (id) => {
        return user_set.has(id)
    }

    const toggleGroupPerm = (group_id, assign_type = 1) => {
        dispatch(startLoading())
        MenuManageAPI.toggleGroupAssign({id: selectedMenuId, group_id, assign_type}).then(
            ()=>{updateMenuGroupsUsers({id: selectedMenuId})}
        )
            .finally(()=>dispatch(endLoading()))
    }
    const toggleUserPerm = (user_id, assign_type = 1) => {
        dispatch(startLoading())
        MenuManageAPI.toggleUserAssign({id: selectedMenuId, user_id, assign_type}).then(
            ()=>{updateMenuGroupsUsers({id: selectedMenuId})}
        )
            .finally(()=>dispatch(endLoading()))
    }

    const deleteMenuItem = (id)=>{
        dispatch(startLoading())
        MenuManageAPI.delete(id).then(()=>{
            refreshData()
        })
            .finally(()=>dispatch(endLoading()))
    }


    return {
        refreshData,
        refreshGroupData,
        refreshUserData,
        updateMenuGroupsUsers,
        groupHasPerm,
        userHasPerm,

        toggleGroupPerm,
        toggleUserPerm,
        deleteMenuItem,

        onSelectMenu,

        selectedMenuId,
        menu,
        groups,
        users,

    }
}

export default useMenuTree;