import make_request from "../utils";


class SalesOrderAPI{
    static async list({filters}) {
        return make_request(`/api/u8addon/sales_order/`, filters)
    }

    static async get({id}){
        return make_request(`/api/u8addon/sales_order/${id}/`, null, {method: 'GET'})
    }


}

export default SalesOrderAPI
