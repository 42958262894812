import store from "../../redux/store";
import {Grid, styled} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

const sideMenuWidth = store.getState().frame.sideMenuWidth

const openedMixin = (theme) => ({
    width: sideMenuWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(12)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(14)} + 1px)`,
    },
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: sideMenuWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export const Container = styled(
    Grid, { shouldForwardProp: (prop) => prop !== 'sideMenuOpen' }
    )(({theme, sideMenuOpen})=>({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...(sideMenuOpen &&{
        marginLeft: sideMenuWidth,
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    }),
    ...(!sideMenuOpen && {
        marginLeft: `calc(${theme.spacing(14)} + 1px)`,
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    })
}))