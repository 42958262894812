import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";

InquiryOrderHeader.propTypes = {

};

function InquiryOrderHeader(
    {quotableInquiry=0, quotedInquiry=0, totalInquiry=0, unmatchedPrdCount=0}
) {
    return (
        <Grid container justifyContent={"space-between"}>
            <Grid item>
                <FormGroup sx={{marginLeft: '11px'}} row>
                    <FormControlLabel control={<Checkbox/>} label={`未匹产品(${unmatchedPrdCount})`} />
                    <FormControlLabel control={<Checkbox/>} label={`可报价(${quotableInquiry})`} />
                </FormGroup>
            </Grid>
            <Grid item>
                <Typography variant={"h6"}>单号: YJ321346540313 业务员: XXX 制单人: XXX</Typography>
            </Grid>
        </Grid>
    );
}

export default InquiryOrderHeader;