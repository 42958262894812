import make_request from "./utils";

class TagAPI{
    static async list({filters, keyword=null}){
        let query = ""
        if(keyword){
            query = `?${(new URLSearchParams({"search": keyword})).toString()}`
        }
        return make_request(`/api/mod_tag/tag/?${query}`, filters)
    }
}

export default TagAPI;
