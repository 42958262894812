import React from 'react';
import PropTypes from 'prop-types';

NoContentRenderer.propTypes = {
    
};

function NoContentRenderer(props) {
    return (
        <div>No Data</div>
    );
}

export default NoContentRenderer;