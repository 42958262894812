import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {endLoading, startLoading} from "../../../../redux/globalLoading/globalLoadingSlice";
import ProductAPI from "../../../../api/ProductAPI";
import RightPanel from "./RightPanel";
import TextPart from "./TextPart";
import {useFormik} from "formik";
import * as yup from "yup";
import PackageCostPricePanel from "../PackageCostPricePanel";
import ProductDeleteDialog from "../ProductDeleteDialog";
import TabPanelDetail from "../../../../components/Tab/TabPanelDetail";


const initialValues = {
    cat_no: '',
    en_name: '',
    cn_name: '',
    cls_code: '',
    mw: '',
    mf: '',
    cas: 'N/A',
    storage: '2℃ ~ 8℃',
    purity: '>95%',
    raw_material: '',
    smiles: '',
    ghs_icon01: 'NA',
    ghs_icon02: 'NA',
    ghs_icon03: 'NA',
    remark: '',
    handler: '',
    website_available: true,
    brand: 'cato',
    mass_day: '3',
    mass_unit: '年',
    company: '',
    mol_text: '',
    confirmed: false,
    catalog: {},
}

const validationSchema = yup.object({
    cat_no: yup.string().required('请输入存货编码'),
    en_name: yup.string().required('请输入产品英文名'),
    cn_name: yup.string().required('请输入产品中文名'),
    // cas: yup.string().required('请输入产品cas号'),
    cls_code: yup.string().required('请输入存货大类编码')
})


function ProductToolbar({formik}){
    const disabled = !formik.values?.id

    const handleCopy = ()=>{
        formik.setFieldValue('id', null)
        formik.setFieldValue('cat_no', '')
    }

    return (
        <Grid container direction={"row"} spacing={1} justifyContent={"space-between"}>
            <Grid item>
                <Grid container direction={"row"} spacing={1}>
                    <Grid item>
                        <ProductDeleteDialog cat_no={formik.values.cat_no} product_id={formik.values?.id}/>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleCopy} disabled={disabled}>复制产品</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button onClick={formik.submitForm}>保存</Button>
            </Grid>
        </Grid>
    )
}


ProductBaseInfo.propTypes = {
    data: PropTypes.object,
    forCreate: PropTypes.bool,
    setForCreate: PropTypes.func,
};

function ProductBaseInfo({id}) {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            dispatch(startLoading())
            let func
            if(forCreate){
                func = ProductAPI.create
            }else {
                func = ProductAPI.update
            }
            func(values)
                .then(data=>{
                    if(!data){return}
                    formik.setValues(data)
                })
                .catch(e=>alert(e))
                .finally(()=>{dispatch(endLoading())})
        }
    })
    const forCreate = !formik.values?.id

    useEffect(()=>{
        if(!id){return}
        dispatch(startLoading())
        ProductAPI.get({id})
            .then(data=> {
                if(!data){return}
                formik.setValues(data)
            })
            .finally(()=>{dispatch(endLoading())})

    }, [id])

    return (
        <TabPanelDetail
            actions={<ProductToolbar formik={formik}/>}
        >
            <Grid container direction={"column"}>
                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <TextPart formik={formik} forCreate={forCreate} />
                        </Grid>
                        <Grid item md={8}>
                            <RightPanel formik={formik} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PackageCostPricePanel product_id={formik.values.id} cat_no={formik.values.cat_no} purity={formik.values.purity}/>
                </Grid>
            </Grid>
        </TabPanelDetail>
    );
}

export default ProductBaseInfo;