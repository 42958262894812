import React from 'react';
import PropTypes from 'prop-types';
import {Grid, styled} from "@mui/material";
import MenuTreeList from "./components/MenuTreeList";
import useMenuTree from "./hooks/useMenuTree";
import GroupList from "./components/GroupList";
import UserList from "./components/UserList";

MenuItemManagePage.propTypes = {
    
};

const StretchGrid = styled(Grid)({
    height: "inherit"
})

function MenuItemManagePage() {
    const menu = useMenuTree()

    return (
        <Grid container direction={"row"} spacing={2} sx={{height: '100%'}} alignItems="stretch">
            <StretchGrid item md={6}>
                <MenuTreeList menu={menu}/>
            </StretchGrid>
            <StretchGrid item md={3}>
                <GroupList menu={menu}/>
            </StretchGrid>
            <StretchGrid item md={3}>
                <UserList menu={menu}/>
            </StretchGrid>
        </Grid>
    );
}

export default MenuItemManagePage;