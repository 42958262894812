import React from 'react';
import PropTypes from 'prop-types';
import UploadPanel from "../../../../components/UploadTask/UploadPanel";
import ChemicalXlsxAPI from "../../../../api/ChemicalXlsxAPI";

ChemicalXlsxImportBtn.propTypes = {

};

function ChemicalXlsxImportBtn(props) {
    return (
        <UploadPanel
            title={"上传Excel"}
            label={"导入"}
            api={ChemicalXlsxAPI.async_auto_extract_xlsx_chem}
            task_name={'mod_chemical.chem_xlsx.utils.extract_xlsx_chem'}
            maxFileSize={96000000}
        />
    );
}

export default ChemicalXlsxImportBtn;