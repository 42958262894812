export function tooltipFormatter(params) {
    // 历史报价/销售浮窗
    const data = params.data;
    let ret = [];
    let columns = [];
    // 根据 params.seriesName 判断输出内容
    switch (params.seriesName) {
        case "指导价":
            columns = [
                { key: "package", name: "规格" },
                { key: "price", name: "指导价" },
            ];
            break;
        case "报价":
            columns = [
                { key: "date", name: "报价日期" },
                { key: "code", name: "询单号" },
                { key: "package", name: "规格" },
                { key: "qty", name: "数量" },
                { key: "price", name: "报价" },
            ];
            break;
        case "成交价":
            columns = [
                { key: "date", name: "销售日期" },
                { key: "code", name: "询单号" },
                { key: "package", name: "规格" },
                { key: "qty", name: "数量" },
                { key: "price", name: "含税单价" },
            ];
            break;
        default:
            columns = [
                { key: "pkg_qty", name: "规格数量"},
                { key: "price", name: "价格"},
            ];
    }
    for (const column of columns) {
        let v = data[column.key];
        ret.push("<b>" + column.name + "</b>: " + (v ? v : "N/A"));
    }

    return (
        '<div align="left" style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">' +
        ret.join("<br/>")
    );
}