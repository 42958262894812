import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DefaultSpinner from "../../../components/base/DefaultSpinner";
import {DialogContent, Grid, styled, Typography} from "@mui/material";
import DraggableDialog from "../../../components/base/DraggableDialog";
import ExpressAPI from "../../../api/ExpressAPI";
import Image from "../../../components/Image/Image";
import moment from 'moment';
import ExpressOrderViewer from "./ExpressOrderViewer";


ExpressDialog.propTypes = {
    
};


const StyledDiv = styled('div')(({theme})=>({
    'ul': {
        'padding-inline-start': 0,
        listStyle: 'none'
    },
    'li': {
        height: "80px"
    },
    'li > div' : {
        display: "inline-block",
        verticalAlign: "top",
    },
    'li.cur': {
        fontWeight: 800,
    },
    'li > div.date' : {
        width: "20%"
    },
    'li > div.text' : {
        width: "80%"
    },
    'div.title > div': {
        display: "inline-block",
    },
    'div.title > div.date': {
        width: "20%"
    },
}))

function RouterItemBase({item, index}) {
    const dt = moment(item?.acceptTime)
    return (
        <li className={(index===0)?"cur":""}>
            <div className={"date"}>
                {dt.format('YYYY-MM-DD')}<br/>{dt.format('hh:mm:ss')}
            </div>
            <div className={"text"}>
                [{item?.acceptAddress}]{item?.remark}
            </div>
        </li>
    )
}

function ExpressRoutes({data}){
    const router = data?.router

    return (
        <StyledDiv>
            <div className={'title'}>
                <div className={"date"}><b>时间</b></div>
                <div><b>地点和跟踪进度</b></div>
            </div>
            <ul className={'content'}>
                {router?.slice(0)?.reverse()?.map((item, index)=>(
                    <RouterItemBase item={item} index={index}/>
                ))}
            </ul>
        </StyledDiv>
    )
}

function ExpressDialog(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        const {data: {id}} = props;
        if(!id){return}
        ExpressAPI.get({id}).then(data=>{
            setData(data)
        })
    }, [props.data.id]);

    return (
        <DraggableDialog
            title={`快递信息 ${data?.express_code} 相关业务单号: ${data?.voucher_code}`}
            handleClose={props.handleClose} open={props.open}
            fullWidth
            maxWidth={'xl'}
        >
            <DefaultSpinner open={loading} />
            <DialogContent>
                <Grid container>
                    <Grid item md={3}>
                        <ExpressOrderViewer data={props.data}/>
                    </Grid>
                    <Grid item md={6}>
                        <ExpressRoutes data={data}/>
                    </Grid>
                    <Grid item md={3}>
                        <Typography title={data?.created_at}>
                            数据新增日期: {moment(data?.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                        <Typography title={data?.modified_at}>
                            数据更新日期: {moment(data?.modified_at).local().format('YYYY-MM-DD HH:mm:ss')}
                        </Typography>
                        <Typography>签收图片:</Typography><br/>
                        <Image src={data?.file_path} height={"300px"}/>
                    </Grid>
                </Grid>
            </DialogContent>
        </DraggableDialog>
    );
}

export default ExpressDialog;