import { configureStore } from '@reduxjs/toolkit'
import globalLoadingReducer from './globalLoading/globalLoadingSlice'
import errMsgReducer from './errorMsg/errMsgSlice'
import tabReducer from './tab/tabSlice'
import frameReducer from './frame/frameSlice'
import userReducer from './userInfo/userSlice'


export default configureStore({
    reducer: {
        globalLoading: globalLoadingReducer,
        errMsg: errMsgReducer,
        tab: tabReducer,
        frame: frameReducer,
        userInfo: userReducer,
    },
})