import make_request from "./utils";


class ProductAPI{

    static async list({filters}) {
        return make_request(`/api/mod_product/product/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_product/product/${id}/`, null, {method: 'GET'})
    }

    static async create({id, ...data}){
        return make_request(`/api/mod_product/product/add/`, data, {method: 'POST'})
    }

    static async update({id, ...data}){
        return make_request(`/api/mod_product/product/${id}/`, data, {method: 'PUT'})
    }

    static async delete({id}) {
        return make_request(`/api/mod_product/product/${id}/`, null, {method: 'DELETE'})
    }

    static async get_packages({id}){
        return make_request(`/api/mod_product/product/${id}/packages/`, null, {method: 'GET'})
    }

    static async get_valid_cat_no({cls_id}){
        // TODO 对接接口：根据产品分类，获取可用货号
        return []
    }

    static async get_simple_catalogs(){
        // TODO move to catalog api
    }

    static async upsert_product_packages(){

    }

    static async get_supplier_quotation(){
        // TOD Deprecated
        return {data: []}
    }

    static async delete_product_package(package_id){

    }

    static async get_tags({id}){
        return make_request(`/api/mod_product/product/${id}/tags/`, null, {method: 'GET'})
    }

    static async add_tags({id, tags}){
        if(!tags){return}
        return make_request(`/api/mod_product/product/${id}/tags/`, {tags}, {method: 'PUT'})
    }

    static async remove_tags({id, tags}){
        if(!tags){return}
        return make_request(`/api/mod_product/product/${id}/tags/`, {tags}, {method: 'DELETE'})
    }
}

export default ProductAPI
