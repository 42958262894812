import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Box, Grid, IconButton, Tab, Tabs, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {removeTab, setActiveTab} from "../../redux/tab/tabSlice";
import sx from "./theme";


TabItem.propTypes = {
    title: PropTypes.string
};

function TabItem({tab: {title}, index}) {
    const dispatch = useDispatch()

    const handleClose = () =>{
        dispatch(removeTab(index))
    }

    return (
        <Grid container>
            <Grid item>
                <Typography>{title}</Typography>
            </Grid>
            <Grid item>
                <IconButton aria-label="delete" size="small" onClick={handleClose} autoFocus={false}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </Grid>
        </Grid>
    );
}


function TabsBar() {
    const tabs = useSelector((state) => state.tab.tabs)
    const curTab = useSelector((state) => state.tab.curTab)
    const dispatch = useDispatch()

    return (
        <Box sx={sx}>
            <Tabs
                onChange={(e, v)=>dispatch(setActiveTab(v))}
                value={curTab}
                aria-label="Tabs where selection follows focus"
                selectionFollowsFocus
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor={"secondary"}
            >
                {tabs.map((tab, index)=>(
                    <Tab
                        component={"div"}
                        label={<TabItem index={index} tab={tab}/>}
                        key={tab.id}
                        onAuxClick={(e)=>{
                            if(e.button === 1){
                                dispatch(removeTab(index))
                            }
                        }}
                    />
                ))}
            </Tabs>
        </Box>
    );
}

export default TabsBar;