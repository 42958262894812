import React from "react";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import {historyNumericRenderer} from "./cellHistoryRenderers";

import {Box, Button, Typography, Stack, styled} from "@mui/material";
import {produce, setAutoFreeze} from "immer";
import {arr_delivery} from "../../../../utils/constants";

// register Handsontable's modules
registerAllModules();

const columns = [
    { data: "package", title: "规格"},
    { data: "cost", title: "成本", type: "numeric", renderer: historyNumericRenderer},
    { data: "price", title: "市场价", type: "numeric", renderer: historyNumericRenderer},
    { data: "delivery", title: "货期", type: "autocomplete", source: arr_delivery},
    {
        data: "expiry",
        title: "价格有效期",
        type: "date",
        dateFormat: "YYYY-MM-DD",
        correctFormat: true,
        width: 100,
    },
    { data: "website_available", title: "网站显示", type: "checkbox"},
];

const StyledHotTable = styled(HotTable)(({theme})=>({
    'td>i': {
        fontSize: "0.6rem"
    },
    'td.alignRight': {
        textAlign: 'end',
    },
    '.highlightRed': {
        backgroundColor: '#ff7a7a'
    },
    '.highlightBlue': {
        backgroundColor: '#62c2ff'
    },
    '.highlightGreen': {
        backgroundColor: '#00ff9f'
    },
}))

export default function PackageTable({ pkgManager }) {
    const handleHotChange = (changes, reason) => {
        setAutoFreeze(false)
        if (reason === "loadData" || !changes) {return}
        pkgManager?.setPackages(produce((draft) => {
            changes.forEach(([row, prop , oldValue, newValue]) => {
                if (oldValue === newValue) {return}
                const r = draft[row]
                r[prop] = newValue
            });
        }));
        pkgManager?.setPkgChanged((prev) => prev + 1);
    }

    return (
        <Stack spacing={1}>
            <Typography variant="h6">成交率: 8%; 客户数: 3</Typography>
            <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={1}>
                    <Button onClick={()=>pkgManager?.refreshPackage()}>刷新</Button>
                    <Button
                        onClick={()=>{pkgManager?.upsertPackages()}}
                        color={pkgManager?.pkgChanged?"secondary":"primary"}
                    >保存</Button>
                    <Button onClick={()=>{pkgManager?.addPackage()}}>新增</Button>
                    <Button onClick={()=>{pkgManager?.resetPkgCost()}}>还原成本</Button>
                    <Button onClick={()=>{pkgManager?.resetPkgPrice()}}>还原价格</Button>
                    <Button >计算市场价</Button>
                </Stack>
                <Button color="error">删除</Button>
            </Stack>
            <Box>
                <StyledHotTable
                    id={"product_package_table"}
                    columns={columns}
                    data={pkgManager?.packages}
                    rowHeaders={true}
                    colHeaders={true}
                    height="auto"
                    width={"100%"}
                    autoWrapRow={true}
                    autoWrapCol={true}
                    stretchH={"all"}
                    beforeChange={handleHotChange}
                    licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                    manualColumnMove
                    manualColumnResize
                />
            </Box>
        </Stack>
    );
}
