import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import GenTable from "../../../components/GenTable/GenTable";
import TaskLogAPI from "../../../api/TaskLogAPI";
import ChemicalXlsxImportBtn from "./actions/ChemicalXlsxImportBtn";
import {getColoredClassName, JsonParser} from "./renderers";

ChemicalXlsxTaskTable.propTypes = {

};

const columns=[
    {data: 'id', title: 'ID', width: 30, searchable: true, type: 'numeric', },
    {data: 'task_id', title: '任务ID', width: 260, searchable: true, type: 'text', },
    {data: 'info__created_by__username', title: '任务创建人', width: 60, searchable: true, type: 'text', },
    {data: 'status', title: '任务状态', width: 60, searchable: true, type: 'text', },
    {data: 'date_created', title: '开始时间', width: 80, searchable: true, type: 'date', },
    {data: 'date_done', title: '完成时间', width: 80, searchable: true, type: 'date', },
    {data: 'result', title: '任务结果', width: 250, searchable: true, type: 'text', renderer: JsonParser},
    {data: 'task_name', title: '任务名称', width: 250, searchable: true, type: 'text', },
    {data: 'task_args', title: '任务位置参数', width: 250, searchable: true, type: 'text', renderer: JsonParser},
    {data: 'task_kwargs', title: '任务关键字参数', width: 250, searchable: true, type: 'text', renderer: JsonParser},

]

const defaultSearches = [
    {field: 'task_name', op: '=', value: 'mod_chemical.chem_xlsx.utils.extract_xlsx_chem'},
]

const download_file = (url) =>{
    const link = document.createElement('a');
    link.target = "_blank";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function ChemicalXlsxTaskTable(props) {
    const hotRef = useRef();

    const onRecordDbClick = (data)=>{
        // 双击下载处理后的文件
        if(data.status !== "SUCCESS"){return}
        console.log(data);
        let url = '/media/' + JSON.parse(data.result)
        // use delay to prevent cell selection acting weird.
        setTimeout(()=>download_file(url), 50)
    }

    return (
        <GenTable
            id={'chemical_xlsx_task_table'}
            hotRef={hotRef}
            hotSettings={{
                rowHeights: 20,
                readOnly: true,
                cells: (row)=>{
                    let cell = {}
                    const instance = hotRef.current?.hotInstance
                    if(!instance){return}
                    const rowData = instance.getSourceDataAtRow(row)
                    cell.className = getColoredClassName(rowData)
                    return cell
                }
            }}
            columns={columns}
            api={TaskLogAPI.get_list}
            onRecordDbClick={onRecordDbClick}
            actions={[
                ChemicalXlsxImportBtn,
            ]}
            initialSearches={defaultSearches}
            perPage={50}
        />
    );
}

export default ChemicalXlsxTaskTable;