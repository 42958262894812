import {Button, Stack} from "@mui/material"
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import {useRef} from "react";
import {arr_currency} from "../../../../utils/constants";
import {handleHotChange} from "../../../../utils/functions";

registerAllModules()

const columns = [
    {data: "status", title: "状态", readOnly: true, width: 20},
    {data: "match_tags", title: "匹配规则", readOnly: true, width: 40},
    {data: "supplier_name", title: "供应商名称", readOnly: true, width: 120},
    {data: "purity", title: "纯度"},
    {data: "inq_qty", title: "规格数量", type: "numeric"},
    {data: "pkg_unit", title: "单位"},
    {data: "qty", title: "数量", type: "numeric"},
    {data: "test_qty", title: "检测量", type: "numeric"},
    {data: "pkg_qty", title: "总量", type: "numeric"},
    {data: "orig_price", title: "供应商报价", type: "numeric"},
    {data: "delivery", title: "货期", type: "numeric"},
    {data: "is_synthetic", title: "定", type: "checkbox", width: 20},
    {data: "quo_remark", title: "备注", },
    {data: "supp_brand", title: "供应商品牌", },
    {data: "supp_cat_no", title: "供应商货号", },
    {data: "currency", title: "币种", type: "dropdown", width: 60, source: arr_currency},
    {data: "tax_rate", title: "税率", type: "numeric"},
    {data: "expiry", title: "报价有效期", width: 100, type: "date", dateFormat: "YYYY-MM-DD", correctFormat: true,},
    {data: "best_supp", title: "最优供应商", type: "checkbox"},
    // {data: "control_btn", title: "操作", readOnly: true},
]

export default function SupplierQuotation(
    {
        pkgManager
    }
){
    const hot = useRef(null);

    const setPrdSupplier = () =>{
        const instance = hot.current?.hotInstance
        if(!instance){return}
        console.log(instance)
        let [[row_start,]] = instance.getSelected()
        console.log(row_start)
        pkgManager?.setPrdSupplier(instance.getSourceDataAtRow(row_start)?.supplier_name)
    }

    return (
        <Stack spacing={1}>
            <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={1}>
                    <Button onClick={()=>pkgManager?.refreshSupplierQuotations()}>刷新</Button>
                    <Button
                        onClick={()=>pkgManager?.upsertSupplierQuotations()}
                        color={pkgManager?.suppQuoChanged?"secondary":"primary"}
                    >保存</Button>
                    <Button onClick={()=>pkgManager?.addSupplierQuotations()}>新增</Button>
                    <Button >复制</Button>
                    <Button onClick={()=>setPrdSupplier()}>选择供应商</Button>
                </Stack>
                <Button color={"error"}>删除</Button>
            </Stack>
            <HotTable
                ref={hot}
                id={"supplier_quotation_table"}
                columns={columns}
                data={pkgManager?.supplierQuotations}
                rowHeaders={true}
                colHeaders={true}
                height="auto"
                autoWrapRow={true}
                autoWrapCol={true}
                stretchH={"all"}
                afterChange={handleHotChange(pkgManager?.setSupplierQuotations)}
                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                fixedColumnsStart={3}
                outsideClickDeselects={false}
                manualColumnMove
                manualColumnResize
            />
        </Stack>
    )
}