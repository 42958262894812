import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import DefaultSpinner from "../../../../../components/base/DefaultSpinner";
import DraggableDialog from "../../../../../components/base/DraggableDialog";
import {
    DialogContent, DialogActions, Stack, Box,
    FormControlLabel, Checkbox, TextField, FormGroup, Button
} from "@mui/material";
import {HotTable} from "@handsontable/react";

AddSupplierDialog.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
};

const columns = [
    // {data: "", title: "id", cellType: 'checkbox'},
    {data: "", title: "匹配规则"},
    {data: "supplier_id", title: "供应商ID"},
    {data: "supplier_name", title: "供应商"},
    {data: "", title: "等级评分"},
    {data: "", title: "产品类型"},
    {data: "", title: "供应商货号"},
    {data: "", title: "产品类型"},
    {data: "", title: "性质"},
    {data: "", title: "开发专员"},
    {data: "", title: "采购专员"},
]

const sampleData = [
    {supplier_id: 1, supplier_name: "上海氟德化工有限公司", status: 0, qq: "451656621"},
    {supplier_id: 2, supplier_name: "广州清瑞生物科技有限责任公司", status: 1, qq: "451656621"},
    {supplier_id: 3, supplier_name: "北京新康哌森医药科技有限公司", status: 2, qq: "451656621"},
    // {supplier_id: 4, supplier_name: "南京望知星医药科技有限公司",   status: 0, qq: "451656621"},
    // {supplier_id: 5, supplier_name: "深圳恒丰万达药物研发有限公司",  status: 1, qq: "451656621"},
    {supplier_id: 6, supplier_name: "泗水县万盈生物科技中心",      status: 2, email: "andrew.yip@cato-chem.com"},
    {supplier_id: 7, supplier_name: "南京轩冕化工科技有限公司",     status: 3, },
    // {supplier_id: 8, supplier_name: "上海泰坦科技股份有限公司", status: 2, },
]


function AddSupplierDialog({
    handleClose= ()=>{},
    open= false,
}) {
    const [loading, setLoading] = useState(false)
    const hot = useRef(null)
    const [data, setData] = useState([ ]);

    useEffect(() => {
        setData(sampleData)
    }, []);

    return (
        <DraggableDialog
            title={"增加供应商询价"}
            handleClose={handleClose} open={open}
            maxWidth={'lg'}
        >
            <DefaultSpinner open={loading} />
            <DialogContent>
                <Stack spacing={1}>
                    <Box>
                        <FormGroup sx={{marginLeft: '11px'}} row>
                            <FormControlLabel control={<Checkbox checked/>} label={"推荐供应商"} />
                            <TextField label={"关键字"}/>
                            <TextField label={"等级评分"}/>
                            <TextField label={"品牌"}/>
                            <TextField label={"性质"}/>
                        </FormGroup>
                    </Box>
                    <Box sx={{width: 'lg', height: '50vh'}}>
                        <HotTable
                            id="add_supplier_dialog_table"
                            licenseKey={'non-commercial-and-evaluation'}
                            style={{overflowX: 'scroll'}}
                            ref={hot}
                            settings={{
                                height: '100%',
                                width: '100%',
                                colHeaders: true,
                                manualColumnResize: true,
                                manualColumnMove: true,
                                readOnly: true,
                                columns: columns,
                                stretchH: 'all',
                            }}
                            data={data}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button>新增</Button>
            </DialogActions>
        </DraggableDialog>
    );
}

export default AddSupplierDialog;
