import make_request from "./utils";


class ChemicalXlsxAPI{

    static async async_auto_extract_xlsx_chem({file}) {
        const form = new FormData()
        form.append('file', file)
        return make_request(
            `/api/mod_chemical/chemical/async_auto_extract_xlsx_chem/`, null, {
                body: form,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
            })
    }

}

export default ChemicalXlsxAPI

