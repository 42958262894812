import * as React from 'react';
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {addTab} from "../../redux/tab/tabSlice";

type Props = {
    label: string
    title: string
    url: string
};

export const ActionBtnWrapper = ({label, title, url}: Props) => {
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(addTab( {title, url}))
    }

    return (
        <Button onClick={handleClick}>{label}</Button>
    );
};

export const ActionAddTabBtn = ({label, title, url}:Props)=>(props:any)=>{
    return (
        <ActionBtnWrapper label={label} title={title} url={url} {...props}/>
    )
}

export default ActionAddTabBtn;


