import React, {useMemo, useState} from 'react';
import {GTTable} from "../types";
import {Operator, SearchMeta, Value} from "../useFilterTypes";
import {Grid} from "@mui/material";
import { TextField } from '@mui/material';


type Props = {
    table: GTTable
    field: string
    label: string
    op: Operator
    groupName?: string
    children: React.ReactElement
    inputProps: any
}

function GtToolbarSearchField({table, field, label, op='=', groupName='', children, inputProps={}}:Props) {
    const searchMeta = table.filter.groups[groupName]?.searches.find((item: SearchMeta)=>item.field===field)
    const [value, setValue]:[Value, Function] = useState(searchMeta?.value||'')

    // @ts-ignore
    const handleChange = (e)=>{
        const {target} = e
        setValue(target.value)
    }

    const commitSearchValue = ()=>{
        const {filter} = table
        if(!!value){
            filter.setGroupFieldSearcher(field, op, value, groupName)
        }else{
            filter.cleanGroupFieldSearcher(field, groupName)
        }
    }

    const handleSearch = ()=>{
        commitSearchValue()
        table.filter.setPage(1)
        table.refreshData()
    }

    const childrenWithProps = useMemo(()=>React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(
                child, {
                    ...inputProps,
                    onChange: handleChange,
                    onBlur: commitSearchValue,
                    onKeyPress: ((event:KeyboardEvent) => {if(event.key === 'Enter'){handleSearch()}}),
                    value: value||'',
                    label: label,
                });
        }
        return child;
    }), [label, field, value]);

    return (
        <Grid>
            {childrenWithProps}
        </Grid>
    );
}

const TextSearchField = (field: string, label: string, op: Operator='=', groupName='', inputProps: any={}) => (props:any)=>{

    return (
        <GtToolbarSearchField
            field={field}
            label={label}
            op={op||'='}
            groupName={groupName}
            inputProps={inputProps}
            {...props}
        >
            <TextField />
        </GtToolbarSearchField>
    )
}

export default TextSearchField