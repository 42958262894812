import React from 'react';
import UploadPanel from "../../../../components/UploadTask/UploadPanel";
import SupplierQuotationAPI from "../../../../api/SupplierQuotationAPI";

SuppQuotationImportBtn.propTypes = {
    
};

function SuppQuotationImportBtn() {
    return (
        <UploadPanel
            title={"上传供应商报价信息"}
            label={"导入"}
            api={SupplierQuotationAPI.upload}
            task_name={'enq_product_page.worker.tasks.upload_supplier_quotation'}
        />
    );
}

export default SuppQuotationImportBtn;