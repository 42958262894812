import make_request from "../utils";


class MenuManageAPI{
    static async get(id){
        return make_request(`/api/general/menu/${id}/`, null, {"method": "GET"})
    }
    static async delete(id){
        return make_request(`/api/general/menu/${id}/`, null, {"method": "DELETE"})
    }
    static async list({filters, search=null}){
        let query = ""
        if(search){
            query = `?${(new URLSearchParams({"search": search})).toString()}`
        }
        return make_request(`/api/general/menu/${query}`, filters)
    }

    static async create({id, ...data}){
        return make_request(`/api/general/menu/add/`, data, {method: 'POST'})
    }

    static async update({id, ...data}){
        return make_request(`/api/general/menu/${id}/`, data, {method: 'PATCH'})
    }

    static async get_tree() {
        return make_request(`/api/general/menu/tree/`, null, {method: 'GET'})
    }

    static async getUsersWithPerm({id}){
        return make_request(`/api/general/menu/${id}/get_users/`)
    }

    static async getGroupsWithPerm({id}){
        return make_request(`/api/general/menu/${id}/get_groups/`)
    }

    static async toggleGroupAssign({id, group_id, assign_type}){
        if(!id || !group_id){return}
        return make_request(`/api/general/menu/${id}/assign_groups/`, {group_ids: [group_id], assign_type})
    }

    static async toggleUserAssign({id, user_id, assign_type}){
        if(!id || !user_id){return}
        return make_request(`/api/general/menu/${id}/assign_users/`, {user_ids: [user_id], assign_type})
    }
}

export default MenuManageAPI