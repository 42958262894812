import make_request from "../utils";


class PurchaseBillAPI{
    static async list({filters}) {
        return make_request(`/api/u8addon/purchase_bill/`, filters)
    }

    static async get({id}){
        return make_request(`/api/u8addon/purchase_bill/${id}/`, null, {method: 'GET'})
    }


}

export default PurchaseBillAPI
