import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ButtonWithModel from "../../../components/base/ButtonWithModel";
import {DialogActions, DialogContent, Typography, Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {endLoading, startLoading} from "../../../redux/globalLoading/globalLoadingSlice";
import ProductAPI from "../../../api/ProductAPI";


PackageDeleteDialog.propTypes = {
    hotRef: PropTypes.object,
};

function PackageDeleteDialog(props) {
    const {hotRef} = props
    const [open, setOpen] = useState(0)
    const [disabled, setDisabled] = useState(false)
    const [items, setItems] = useState([])
    const dispatch = useDispatch()

    const handleDelete=()=>{
        dispatch(startLoading())
        Promise.all(
            items.filter(v=>!!v?.id).map((value => ProductAPI.delete_product_package(value.id)))
        ).finally(()=> {
            dispatch(endLoading())
            setDisabled(true)
        })
    }

    useEffect(()=>{
        const instance = hotRef.current?.hotInstance
        if(!instance) {return}
        const selected = instance.getSelected()
        const rows = new Set()
        selected?.forEach(([startRow, , endRow, ]) => {
            const s = Math.min(startRow, endRow)
            const e = Math.max(startRow, endRow)
            for(let i=s; i<=e; i++){
                rows.add(i)
            }
        })
        setItems(Array.from(rows).reduce((prev, cur)=>(
            prev.concat(instance.getSourceDataAtRow(cur))
        ), []))
    }, [open])

    return (
        <ButtonWithModel
            label={"删除规格"} title={'是否确认删除'} onOpen={()=>setOpen(prev=>prev+1)}
            btnProps={{color: "secondary"}}
        >
            <React.Fragment>
                <DialogContent>
                    {items.map(value=>(
                        <Typography>{value?.cat_no} - {value?.package}</Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} disabled={disabled} color={"secondary"}>确认删除</Button>
                </DialogActions>
            </React.Fragment>
        </ButtonWithModel>
    );
}

export default PackageDeleteDialog;