

//TODO 对接接口

import make_request from "./utils";

class InternalQuotationAPI{
    static async list({filters}) {
        return make_request(`/api/mod_inquiry/inter_quo/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_chemical/inter_quo/${id}/`, null, {method: 'GET'})
    }

    static async create({id, ...data}){
        return make_request(`/api/mod_chemical/inter_quo/add/`, data, {method: 'POST'})
    }

    static async update({id, ...data}){
        return make_request(`/api/mod_chemical/inter_quo/${id}/`, data, {method: 'PUT'})
    }

    static async delete({id}) {
        return make_request(`/api/mod_chemical/inter_quo/${id}/`, null, {method: 'DELETE'})
    }

    static async get_related_supplier_quotations(){
        return []
    }

    static async upsert_related_supplier_quotations(){

    }

    static async export_list(){

    }
}

export default InternalQuotationAPI
