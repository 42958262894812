import {produce, setAutoFreeze} from "immer";


const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export async function get_dummy_data(data, max_delay=0) {
    // 模拟获取远端数据
    await sleep(Math.floor(Math.random() * max_delay));
    return data
}

export function price2cost(price){
    const cost = (-(1531.43+258*1.7143-price)+((1531.43+258*1.7143-price)**2-4*1.7143*(-258*price))**0.5)/(2*1.7143)
    return cost.toFixed(0)
}

export function cost2price(cost){
    const price = (1072/(cost+258)+1.2)*cost/0.7
    return price.toFixed(0)
}

export function normalizePackage(pkg){
    if(typeof pkg !== "string"){return}
    const m = pkg.match(/^(\d+(\.\d+)?)\s*([muμMKk]?[GLgl])$/)
    if(!m){return}
    const [, qty, , unit] = m
    let ratio = null
    switch (unit) {
        case 'g':
            ratio = 1000
            break
        case 'mg':
            ratio = 1
            break
        case 'ml':
            ratio = 1000
            break
        case 'l':
            ratio = 1000000
            break
        case 'kg':
            ratio = 1000000
            break
        case 'ug':
            ratio = 0.001
            break
        case 'μg':
            ratio = 0.001
            break
        case 'μl':
            ratio = 1
            break
        case 'ul':
            ratio = 1
            break
    }
    if(!ratio){return}
    return ratio * Number(qty)
}


export function day_past(d){
    const today = new Date()
    return (today - Date.parse(d)) / (1000 * 3600 * 24)
}

export function keepObjOrig(item) {
    item["__orig"] = Object.entries(item).filter(([k])=>k!=="__orig").reduce((a, [k, v])=>({...a, [k]: v}), {})
    return item;
}


export const handleHotChange = (setValue) => (changes, reason)=>{
    setAutoFreeze(false)
    if (reason === "loadData" || !changes) {return}
    setValue(produce((draft) => {
        changes.forEach(([row, prop , oldValue, newValue]) => {
            if (oldValue === newValue) {return}
            const r = draft[row]
            r[prop] = newValue
        });
    }));
}