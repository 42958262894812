import make_request from "./utils";


class CustomerAPI{
    static async list({filters}) {
        return make_request(`/api/mod_customer/customer/`, filters)
    }

    static async get({id}){
        return make_request(`/api/mod_customer/customer/${id}/`, null, {method: 'GET'})
    }

    static async create({id, ...data}){
        return make_request(`/api/mod_customer/customer/add/`, data, {method: 'POST'})
    }

    static async update({id, ...data}){
        return make_request(`/api/mod_customer/customer/${id}/`, data, {method: 'PUT'})
    }

    static async delete({id}) {
        return make_request(`/api/mod_customer/customer/${id}/`, null, {method: 'DELETE'})
    }

    static async get_customer_simple(){
        // TODO
    }

    static async get_customer_contacts_simple(){
        // TODO
    }
}

export default CustomerAPI
