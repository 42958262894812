import Handsontable from "handsontable";

export function historyNumericRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    const rowData = hotInstance.getSourceDataAtRow(row)
    Handsontable.renderers.BaseRenderer.apply(this, [hotInstance, td, row, column, prop, value, cellProperties]);

    td.innerText = '';
    const info = hotInstance.rootDocument.createElement('i');
    const value_n = hotInstance.rootDocument.createTextNode(value);
    const oldValue = rowData?.__orig[prop]
    info.innerText = oldValue?`${oldValue}/`:''

    td.appendChild(value_n)
    td.insertBefore(info, value_n)
    td.classList.add('alignRight')

    td.title = `新: ${value}; 原: ${oldValue}`
    if(value > oldValue){
        td.classList.add('highlightRed')
    }else if(value < oldValue){
        td.classList.add('highlightBlue')
    }
    return td
}

