import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {get_dummy_data} from "../../../../utils/functions";
import sampleSize from "lodash/sampleSize";

UseInquiryOrderSupplierSelection.propTypes = {

};


const match_reasons = [
    {label: "售后", level: -1},
    {label: "人工", level: 1},
    {label: "采购", level: 1},
    {label: "报价", level: 1},
    {label: "CAS", level: 1},
    {label: "货号", level: 1},
    {label: "API", level: 10},
    {label: "子结构", level: 20},
    {label: "相似结构", level: 20},
]

// 0 待导出 1 待报价 2 已报价 3 无法提供
const sample_supplier_data = [
    {supplier_id: 1, supplier_name: "上海氟德化工有限公司", status: 0, qq: "451656621"},
    {supplier_id: 2, supplier_name: "广州清瑞生物科技有限责任公司", status: 1, qq: "451656621"},
    {supplier_id: 3, supplier_name: "北京新康哌森医药科技有限公司", status: 2, qq: "451656621"},
    // {supplier_id: 4, supplier_name: "南京望知星医药科技有限公司",   status: 0, qq: "451656621"},
    // {supplier_id: 5, supplier_name: "深圳恒丰万达药物研发有限公司",  status: 1, qq: "451656621"},
    {supplier_id: 6, supplier_name: "泗水县万盈生物科技中心",      status: 2, email: "andrew.yip@cato-chem.com"},
    {supplier_id: 7, supplier_name: "南京轩冕化工科技有限公司",     status: 3, },
    // {supplier_id: 8, supplier_name: "上海泰坦科技股份有限公司", status: 2, },
]

// status 0 提交 1 可报 2 已报 3 无法提供
const sample_data = [
    {"id": 1,  "status": 0, "inq_cat_no": "C4X-1332", "cat_no": "C4X-1332", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 5, default_supplier_id: 1},
    {"id": 2,  "status": 1, "inq_cat_no": "C4X-1332", "cat_no": "C4X-1332", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "100mg", "quantity": 1, default_supplier_id: 2},
    {"id": 3,  "status": 2, "inq_cat_no": null,       "cat_no": null      , "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1, default_supplier_id: 3},
    {"id": 4,  "status": 3, "inq_cat_no": "C4X-1336", "cat_no": "C4X-1336", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1, default_supplier_id: 4},
    {"id": 5,  "status": 0, "inq_cat_no": "C4X-1337", "cat_no": "C4X-1337", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1, default_supplier_id: 5},
    {"id": 6,  "status": 0, "inq_cat_no": "C4X-1338", "cat_no": "C4X-1338", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 2, default_supplier_id: 6},
    {"id": 7,  "status": 1, "inq_cat_no": "C4X-1339", "cat_no": "C4X-1339", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 3, default_supplier_id: 7},
    {"id": 8,  "status": 2, "inq_cat_no": "C4X-1332", "cat_no": "C4X-1332", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1, default_supplier_id: 8},
    {"id": 9,  "status": 0, "inq_cat_no": "C4X-1332", "cat_no": "C4X-1332", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "100mg", "quantity": 1, default_supplier_id: 9},
    {"id": 10,  "status": 0, "inq_cat_no": null,       "cat_no": null      , "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1, default_supplier_id: 10},
    {"id": 11, "status": 0, "inq_cat_no": "C4X-1336", "cat_no": "C4X-1336", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 12, "status": 0, "inq_cat_no": "C4X-1337", "cat_no": "C4X-1337", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 13, "status": 0, "inq_cat_no": "C4X-1338", "cat_no": "C4X-1338", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 3},
    {"id": 14, "status": 0, "inq_cat_no": "C4X-1339", "cat_no": "C4X-1339", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 15, "status": 0, "inq_cat_no": "C4X-1332", "cat_no": "C4X-1332", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 16, "status": 0, "inq_cat_no": "C4X-1332", "cat_no": "C4X-1332", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "100mg", "quantity": 1},
    {"id": 17, "status": 0, "inq_cat_no": null,       "cat_no": null      , "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 18, "status": 0, "inq_cat_no": "C4X-1336", "cat_no": "C4X-1336", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 19, "status": 0, "inq_cat_no": "C4X-1337", "cat_no": "C4X-1337", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 20, "status": 0, "inq_cat_no": "C4X-1338", "cat_no": "C4X-1338", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
    {"id": 21, "status": 0, "inq_cat_no": "C4X-1339", "cat_no": "C4X-1339", "en_name": "Xxxxxxxx EP Impurity A", "cn_name": "XXXX EP 杂质A", "cas": "12345-50-0", "img": "/logo192.png", "package": "50mg", "quantity": 1},
]


function UseInquiryOrderSupplierSelection(props) {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    const setRowSupplierInq = (index, supplier_inquiries) =>{
        setData(draft=>{
            draft[index].supplier_inquiries = supplier_inquiries
            return draft
        })
    }

    const addSuppliers2Columns = (supplier_inquiries)=>{
        // TODO 匹配多的放前面
        setColumns((draft)=>{
            supplier_inquiries.map((value)=>{
                const index = draft.findIndex(item=>item?.supplier_id === value?.supplier_id)
                if(index === -1){
                    draft.push({...value, _count: 1})
                }else{
                    draft[index]._count = draft[index]._count + 1;
                }
            })
            return draft.sort((a, b)=>(b._count - a._count))
        })
    }

    const refreshData = ()=>{
        get_dummy_data(([].concat(...Array(5).fill(sample_data)))).then(value => {
            setData(value)
            // get each related supplier inquiry, insert into data
            value.forEach((item, index)=>{
                get_dummy_data(sampleSize(sample_supplier_data, 3)).then(data=>(
                    data.map((item=>{
                        item["match_reasons"] = sampleSize(match_reasons, 2)
                        return item
                    }))
                )).then(supplier_inquiries=>{
                    setRowSupplierInq(index, supplier_inquiries)
                    addSuppliers2Columns(supplier_inquiries)
                })
            })
        })
    }

    return {
        data,
        columns,
        refreshData,
    };
}

export default UseInquiryOrderSupplierSelection;