import React from 'react';
import {Grid} from "@mui/material";
import {GTTable} from "../types";

type Props = {
    Component: React.ComponentClass<any, any>
    table: GTTable
    hotRef: React.RefObject<any>
};
export const ActionWrapper = ({Component, table, hotRef}: Props) => {
    return (
        <Grid item>
            <Component table={table} hotRef={hotRef}/>
        </Grid>
    );
};

export default ActionWrapper