import React from 'react';
import PropTypes from 'prop-types';
import ProductAPI from "../../../../../api/ProductAPI";
import GenAutoComplete from "../../../../../components/inputFields/base/GenAutoComplete";
import {Stack, Box, Chip, styled} from "@mui/material";

ProductRenderer.propTypes = {

};

const PrdHighlightStack = styled(Stack, {
    shouldForwardProp: (prop)=> prop !== 'matchedPrd'
})(({matchedPrd, theme})=>({
    height: '100%',
    ...(!matchedPrd && {
        backgroundColor: 'rgba(255,115,0,0.33)',
    }),
}))

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
}));


function ProductRenderer({rowData, dataKey}) {

    return (
        <PrdHighlightStack direction="column" spacing={2} matchedPrd={!!rowData?.cat_no}>
            <Item>
                <GenAutoComplete
                    label={"货号"}
                    getOptionLabel={option=>option.cat_no?option?.cat_no:''}
                    value={rowData?.cat_no}
                    api={ProductAPI.list}
                />
                <b>中文名: </b>{rowData?.cn_name}
                <br/><b>英文名: </b>{rowData?.en_name}
                <br/><b>CAS: </b>{rowData?.cas}
                <br/><b>规格: </b>{rowData?.package}
                <br/><b>批次: </b>{rowData?.batch}
                <br/><b>库存: </b>{rowData?.stock_qty}
            </Item>
            <Item>
                <Chip label={"潜力备货"} size={"small"} color={"primary"}/>
            </Item>
        </PrdHighlightStack>
    );
}

export default ProductRenderer;