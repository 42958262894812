const sx = {
    width: '100%',
    borderBottom: 1,
    borderColor: 'divider',
    '& .MuiTabs-root': {
        minHeight: 30,
        '& .MuiTabScrollButton-root': {
            fontSize: '0.8rem'
        }
    },
    '& .MuiTab-root': {
        backgroundColor: 'white',
        minHeight: 28,
        padding: '2px 4px',
        '& > .MuiGrid-container': {
            alignItems: 'end',
            justifyContent: 'space-between',
            '& .MuiTypography-root': {
                fontSize: '0.8rem'
            },
            '&  .MuiIconButton-sizeSmall': {
                fontSize: '0.8rem'
            }
        },
        borderTop: 1,
        borderLeft: 1,
        borderRight: 1,
        borderRadius: '5px 5px 0 0',
        borderColor: 'grey'
    }
}

export default sx