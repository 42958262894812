import React, {useRef, useState} from 'react';
import useFilter from "./useFilter"
import CellCoords from "handsontable/3rdparty/walkontable/src/cell/coords";
import {GTTable, useTableProps } from './types';



function useTable(
    {
        api,
        initPage=1, initPerPage=50,
        perPageOptions=[50, 100, 1000],
        actions=[],
        onRecordDbClick= ()=>{},
        initialSearches=[],
        initialGroups={},
        columns=[],
    }:useTableProps
    ):GTTable{
    const hot: any = useRef(null)
    const [data, setData]:[any[], Function] = useState([])
    const [curPage, setCurPage] = useState(initPage)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [dbClickedData, setDbClickedData] = useState({})

    const [expandedImg, setExpandedImg] = useState('')
    const filter = useFilter({
        initialSearches,
        initialGroups,
        columns, initPage, initPerPage, perPageOptions
    })
    const totalPage = Math.ceil(total / filter.perPage)

    const refreshData = (from_start = false)=>{
        setLoading(true)
        if(from_start){filter.setPage(1)}
        const filters = filter.assembleFilter()
        api({filters}).then(data=>{
            setData(data.data)
            setTotal(data.total)
            setCurPage(data.page)
        }).finally(()=>{
            setLoading(false)
        })
    }

    const invalidPage = (n: number)=>{
        return n > Math.ceil(total / filter.perPage) || n < 1
    }

    const gotoPage = (n: number) => {
        if(invalidPage(n)){return}
        filter.setPage(n)
        refreshData()
    }

    const nextPage = ()=>{gotoPage(curPage+1)}

    const prevPage = ()=>{gotoPage(curPage-1)}

    const handleMouseDown = (event: Event, coords: CellCoords, td: HTMLTableCellElement)=>{
        let now = new Date().getTime();
        // check if dbl-clicked within 1/5th of a second. change 200 (milliseconds) to other value if you want
        // @ts-ignore
        if(!(td.lastClick && now - td.lastClick < 200)) {
            // @ts-ignore
            td.lastClick = now;
            return; // no double-click detected
        }

        // double-click code goes here
        const instance = hot.current?.hotInstance;
        if(!instance){return}
        const s = instance.getSelected()
        if(s===undefined){
            return false
        }
        const data = instance.getSourceDataAtRow(s[0][0])
        if(data===null){
            return false
        }
        onRecordDbClick(data)
    }

    const handleMouseOver = (e: Event, {row, col}:{row: number, col: number})=>{
        const instance = hot.current?.hotInstance
        if(!instance){return}
        const data_type = instance.getDataType(row, col, row, col)
        if(data_type==='img'){
            const value = instance.getDataAtCell(row, col)
            setExpandedImg(value)
        }else{
            setExpandedImg('')
        }
    }

    const handleMouseOut = ()=>{setExpandedImg('')}

    return {
        hot,
        data,
        loading,
        refreshData,

        filter,

        curPage,

        totalPage,
        total,
        gotoPage,
        nextPage,
        prevPage,
        invalidPage,

        perPage : filter.perPage,
        perPageOptions,
        setPerPage: filter.setPerPage,

        actions,

        dbClickedData, setDbClickedData,

        handleMouseDown,

        expandedImg,
        handleMouseOver,
        handleMouseOut
    }
}

export default useTable