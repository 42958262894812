import make_request from "./utils";


// TODO 未实际对接接口

export default class SupplierQuotationAPI{
    static async list({filters}){
        return make_request('/api/supplier/quotation', filters)
    }

    static async bulk_upsert(items){
        return make_request('/api/supplier/quotation/bulk_upsert', items)
    }

    static async revoke(id){
        return make_request(`/api/supplier/quotation/${id}/revoke`)
    }

    static async upload({file}){
        const form = new FormData()
        form.append('file', file)
        return make_request(`/api/supplier/quotation/upload`, null, {
            body: form,
            headers: {
                'Accept': 'application/json',
            },
        })
    }

    // 获取供应商查询列表
    static async get_supplier_list({filters}){
        return make_request('/api/mod_supplier/supplier/', filters)
    }
}