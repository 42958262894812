import React, {useState} from 'react';
import GenTable from "../../../components/GenTable/GenTable";
import ProductCatalogAPI from "../../../api/ProductCatalogAPI";
import ProductCatalogDialog from "../dialog/ProductCatalogDialog";
import ActionAddDialogBtn from "../../../components/GenTable/ActionAddDialogBtn";


const columns = [
    {data: 'id', title: 'ID', width:50, searchable: true, type:'text'},
    {data: 'handler', title: '负责人', width:50, searchable: true, type:'text'},
    {data: 'prefix', title: '货号前缀', width:100, searchable: true, type:'text'},
    {data: 'en_name', title: '英文名', width:120, searchable: true, type:'text'},
    {data: 'cn_name', title: '中文名', width:120, searchable: true, type:'text'},
    {data: 'atc_code', title: 'ATC Code', width:100, searchable: true, type:'text'},
    {data: 'cls_code', title: '分类编码', width:100, searchable: true, type:'text'},
    {data: 'remark', title: '备注', width:100, searchable: true, type:'text'},
    {data: 'code_name', title: '品牌代号', width:100, searchable: true, type:'text'},
    {data: 'type_name', title: '分类类型', width:100, searchable: true, type:'text'},
    {data: 'modified_by', title: '修改人', width:60, searchable: true, type:'text'},
    {data: 'modified_at', title: '修改日期', width:80, searchable: true, type:'date'},
]


function ProductCatalogTable() {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})

    const handleRecordDbClick = (data)=>{
        setData(data)
        setOpen(true)
    }

    return (
        <React.Fragment>
            <GenTable
                id={'product_catalog_table'}
                hotSettings={{
                    rowHeights: 35,
                    readOnly: true,
                    stretchH: 'all',
                }}
                columns={columns}
                api={ProductCatalogAPI.list}
                actions={[
                    ActionAddDialogBtn({component:ProductCatalogDialog})
                ]}
                onRecordDbClick={handleRecordDbClick}
            />
            <ProductCatalogDialog open={open} handleClose={()=>setOpen(false)} data={data}/>
        </React.Fragment>
    );
}

export default ProductCatalogTable;