import React, {useMemo} from "react";
import {TextField} from "@mui/material";
import PropTypes from "prop-types";


FastTextField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    forCreate: PropTypes.bool,
    label: PropTypes.string,
};


function FastTextField(props){
    const {name, value, error, helperText, forCreate, formik, ...otherProps} = props
    const memoTextField = useMemo(()=>(
        <TextField name={name} value={value} error={error} helperText={helperText} fullWidth {...otherProps} />
    ), [name, value, error, helperText])
    return (
        <React.Fragment>
            {memoTextField}
        </React.Fragment>
    )
}

export default FastTextField