// @flow
import React, {useState} from 'react';
import {Button} from "@mui/material";


type Props = {
    label?: string
    component: React.ElementType
};

const ActionAddDialogBtnWrapper=({label='New', component}: Props)=>{
    const [open, setOpen] = useState(false)
    const Component = component || (()=>(<div/>))

    return (
        <React.Fragment>
            <Component open={open} handleClose={()=>setOpen(false)}/>
            <Button onClick={()=>setOpen(true)}>{label}</Button>
        </React.Fragment>
    );
}

export const ActionAddDialogBtn = ({label='New', component}: Props)=>(props:any) => {

    return (
        <ActionAddDialogBtnWrapper label={label} component={component} {...props}/>
    );
};

export default ActionAddDialogBtn