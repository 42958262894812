import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton, ListItemButton, ListItemText} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

MenuItem.propTypes = {
    
};

function MenuItem({
    item: {id, title, url, children, n_ordering},
    menu,
    sx={},
    onItemDBClick=()=>{},
    onItemClick=({id})=>{},
}) {
    const [expanded, setExpanded] = useState(true)
    const handleExpand = (e)=>{
        if(children?.length>0){
            e.preventDefault()
            setExpanded(prevState=>!prevState)
        }
    }

    useEffect(() => {
        console.log(menu?.selectedMenuId)
    }, [menu?.selectedMenuId]);

    return (
        <React.Fragment>
            <ListItemButton
                sx={sx} selected={id===menu?.selectedMenuId}
                onClick={()=>onItemClick({id})} onDoubleClick={()=>onItemDBClick({id})}
            >
                <ListItemText primary={title} secondary={`${n_ordering || 'N/A'}:${url}`}/>
                <IconButton onClick={()=>onItemDBClick({id})}><EditIcon /></IconButton>
                <IconButton onClick={()=>menu.deleteMenuItem(id)}><DeleteIcon /></IconButton>
                {children?.length>0?expanded ? <ExpandLess onClick={handleExpand}/> : <ExpandMore onClick={handleExpand}/>:null}
            </ListItemButton>
            {expanded?children?.map(item => (
                <MenuItem
                    item={item} key={item.name} menu={menu}
                    sx={{paddingLeft: 10}}
                    onItemClick={onItemClick} onItemDBClick={onItemDBClick}
                />
            )):null}
        </React.Fragment>
    );
}

export default MenuItem;