import React from 'react';
import {Grid} from "@mui/material";


TabPanel.propTypes = {

};

function TabPanel({ children, value, index, ...props }) {
    const hidden = value !== index

    return (
        <Grid
            container
            role="tabpanel"
            id={`frame-tabpanel-${index}`}
            aria-labelledby={`frame-tab-${index}`}
            sx={{display: hidden?'none':'', width: '100%', height: '100%', p: 1}}
            {...props}
        >
            {children}
        </Grid>
    );
}

export default TabPanel;