import React from 'react';
import PropTypes from 'prop-types';
import GenTable from "../../../components/GenTable/GenTable";
import TextSearchField from "../../../components/GenTable/searchFields/GTToolbarSearchField";
import {EllipsisTextRenderer} from "../../../components/GenTable/renderers";
import PurchaseBillAPI from "../../../api/u8/PurchaseBillAPI";

PurchaseBillTable.propTypes = {

};

const columns = [
    {data: 'id', title: 'ID', width: 20, type: 'numeric'},
    {data: 'parent.dpbvdate', title: '销售日期', width: 80, type: 'date'},
    {data: 'parent.dverifydate', title: '销售审核日期', width: 80, type: 'date'},
    {data: 'parent.cpbvbilltype', title: '发票类型', width: 80},
    {data: 'parent.cpbvcode', title: '采购发票号', width: 120},
    {data: 'parent.ccusname', title: '供应商名称', width: 80},
    {data: 'parent.cpbvmemo', title: '订单备注', width: 80, renderer: EllipsisTextRenderer},
    {data: 'parent.cpbvmaker', title: '制单人', width: 80},
    {data: 'cinvcode', title: '存货编码', width: 90, renderer: EllipsisTextRenderer},
    {data: 'cfree1', title: '规格', width: 90},
    {data: 'cbatch', title: '批次', width: 90},
    {data: 'iquantity', title: '数量', width: 60, type: 'numeric'},
    {data: 'inum', title: '瓶数', width: 60, type: 'numeric'},
    {data: 'isum', title: '含税合计', width: 60, type: 'numeric'},
    {data: 'cmemo', title: '行备注', width: 120},
    {data: 'extra.cbdefine72', title: '来源ID', width: 120},
]

function PurchaseBillTable(props) {

    return (
        <React.Fragment>
            <GenTable
                api={PurchaseBillAPI.list}
                hotSettings={{
                    rowHeights: 20,
                    stretchH: 'all',
                }}
                columns={columns}
                actions={[
                    TextSearchField('parent.cpbvcode', '销售单号', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('parent.ccusname', '客户名称', 'contains', '',{sx:{width: '150px'}}),
                    TextSearchField('parent.cpbvmaker', '制单人', 'contains', '',{sx:{width: '150px'}}),
                ]}
                // initialGroups={initialSearchGroup}
            />
        </React.Fragment>
    );
}

export default PurchaseBillTable;