import {createTheme} from '@mui/material/styles';

const denseTheme = createTheme({
    palette:{
        secondary: {
            main: '#e00000'
        },
    },
    spacing: 4,
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    height: '1.4rem'
                },
                sizeSmall: {
                    marginLeft: 4,
                    marginRight: 4,
                    padding: 4,
                },
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    height: '1.25em'
                },
                sizeSmall: {
                    marginLeft: 4,
                    marginRight: 4,
                    padding: 5,
                },
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            }
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                variant: 'outlined',
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.9rem'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                inputSizeSmall: {
                    height: '0.9rem'
                },
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&.MuiOutlinedInput-input': {
                        padding: '4px 0 4px'
                    }
                }
            }
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            }
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: 'small'
            },
        },
        MuiCheckbox: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                root: {
                    padding: 4
                }
            },
        },
        MuiMenuItem: {
            defaultProps: {
                dense: true
            },
            styleOverrides: {
                root:{
                    paddingTop: 2,
                    paddingBottom: 2,
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '5px 10px'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '4px 8px'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '4px 8px'
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root:{
                    minHeight: '32px',
                    '&$expanded': {
                        minHeight: '32px',
                    }
                },
                content: {
                    margin: '5px 0',
                    '&$expanded': {
                        padding: '5px 0',
                        margin: '0px'
                    }
                },
                expandIcon: {
                    padding: '5px'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h5:{
                    fontSize: '1.2rem',
                    fontWeight: 800,
                },
                h6:{
                    fontSize: '1.0rem',
                    fontWeight: 800
                },
                body1:{
                    fontSize: '0.8rem',
                    fontWeight: 400
                }
            }
        },
        MuiDropzoneArea:{
            styleOverrides: {
                root:{
                    minHeight: '100px'
                }
            }
        }
    }
});

export default denseTheme