

export const arr_currency = [
    'RMB',
    'USD',
    'EUR',
    'GBP',
    'CAD',
    'JPY',
]

export const arr_unit = [
    'mg',
    'g',
    'mL',
    'μg',
    'μl',
    'kg',
    'L',
    'ton',
]

export const arr_delivery = [
    '现货',
    '7天',
    '15天',
    '30天',
    '60天',
    '90天',
    '180天',
    '360天',
]