import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, Chip, TextField} from "@mui/material";
import TagAPI from "../../api/TagAPI";

GenTagsEditor.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    limitTags: PropTypes.number,
    obj_id: PropTypes.number,
    api: PropTypes.object,
    allowClearTags: PropTypes.bool,
};

function GenTagsEditor(
    {
        label,
        placeholder = "Input Tags",
        limitTags = 5,
        obj_id = null,
        api = null,
        allowClearTags = false,
    }
) {
    /*
    标签编辑器
    */
    const [value, setValue] = useState([])
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        // 初始化现有标签
        refreshData()
    }, [obj_id]);

    const refreshData = () => {
        if(!obj_id || typeof api?.get_tags !== 'function'){return}
        api.get_tags({id: obj_id}).then(data=>{
            setValue(data)
        })
    }

    useEffect(() => {
        // 输入变化的时候，刷新选项
        let func = defaultGetTagOptions
        let active = true;
        (async () => {
            const data = await func({keyword: inputValue});
            console.log(inputValue)
            if (active) {
                setOptions(data || []);
            }
        })();
        return () => {
            active = false;
        };
    }, [inputValue]);

    const defaultGetTagOptions = async({keyword})=>{
        return TagAPI.list({
            filters: {
                per_page: 5,
            },
            keyword: keyword
        }).then(data=>data.data)
    }

    const onInputChange = (event, value, reason)=>{
        if ((reason === 'input')) {
            setInputValue(value)
        }
    }

    const onChange = (event, newValue, reason, details)=>{
        const option = details?.option
        if(!obj_id || typeof api?.get_tags !== 'function'){return}
        if(reason === 'selectOption' && option){
            // perform add tags
            api.add_tags({id: obj_id, tags: [option.name]}).then(()=>{
                refreshData()
            })
        }else if(reason === 'removeOption' && option){
            // perform remove tags
            api.remove_tags({id: obj_id, tags: [option.name]}).then(()=>{
                refreshData()
            })
        }else if(reason === 'createOption' && option){
            // perform create tags
            api.add_tags({id: obj_id, tags: [option]}).then(()=>{
                refreshData()
            })
        }else if(reason === 'clear' && allowClearTags){
            // perform clear tags
        }
    }

    return (
        <Autocomplete
            multiple
            freeSolo
            limitTags={limitTags}
            value={value}
            options={options}
            onInputChange={onInputChange}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value)=>option.id === value.id}
            renderInput={(params) => (
                <TextField {...params} label={`${label}(${value?.length})`} placeholder={placeholder} />
            )}
            onChange={onChange}
            renderTags={(value, getTagProps) =>(
                value.map((option, index) => (
                    <Chip color="primary" label={option.name} {...getTagProps({index})} />
                ))
            )}
        />
    );
}

export default GenTagsEditor;