import React, {useEffect, useRef, useState} from 'react';
import 'handsontable/dist/handsontable.full.css';
import PropTypes from 'prop-types';
import {Button, Grid, IconButton, Typography} from "@mui/material";
import {HotTable} from "@handsontable/react";
import {CostEditor, PriceEditor} from "./editors";
import config from "react-global-configuration";
import PackageDeleteDialog from "./PackageDeleteDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import {EllipsisTextRenderer} from "../../../components/GenTable/renderers";
import ProductAPI from "../../../api/ProductAPI";


const delivery_options = config.get('delivery_options', [])
const purity_options = config.get('purity_options', [])

const columns = [
    {data:'id', title: 'ID', width:20, searchable: true, type:'text', readOnly: true},
    {data:'brand', title: '品牌', width:30, searchable: true, type:'text', readOnly: true},
    {data:'cat_no', title: '货号', width:50, searchable: true, type:'text', readOnly: true},
    {data:'package', title: '规格', width:50, searchable: true, type:'text'},
    {data:'website_available', title: '网站显示规格', width:20, searchable: true, type:'checkbox'},
    {data:'purity', title: '纯度', width:50, searchable: true, type:'autocomplete', source: purity_options},
    {data:'cost', title: '成本', width:50, searchable: true, type:'numeric', editor: CostEditor},
    {data:'price', title: '价格', width:50, searchable: true, type:'numeric', editor: PriceEditor},
    {data:'delivery', title: '货期', width:50, searchable: true, type:'autocomplete', source: delivery_options},
    {data:'price_expiry_date', title: '价格有效期', width:50, searchable: true, type:'date', },
    {data:'remark', title: '备注', width:50, searchable: true, type:'text', renderer: EllipsisTextRenderer},
    {data:'modified_at', title: '修改日期', width:50, searchable: true, type:'date', readOnly: true},
    {data:'created_by', title: '修改人', width:50, searchable: true, type:'text', readOnly: true},
]


PackageTable.propTypes = {
    cat_no: PropTypes.string.isRequired,
    purity: PropTypes.string,
};

function PackageTable(props) {
    const {product_id, cat_no, purity, onPackagesChange} = props
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [changedRows, setChangedRows] = useState([])
    const [changed, setChanged] = useState(0)
    const hot = useRef()

    useEffect(()=>{
        if(!cat_no){
            setData([])
            return
        }
        handleRefresh()
    }, [cat_no])

    useEffect(() => {
        if(typeof onPackagesChange !== 'function'){return}
        onPackagesChange(data)
    }, [data])

    const handleAdd = ()=>{
        setData(data=>data.concat([{
            cat_no: cat_no,
            brand: 'cato',
            purity: purity,
            website_available: true,
        }]))
        setChangedRows(data=>data.map(value => value+1))
    }

    const handleSave = ()=>{
        const {cat_no} = props
        if(!cat_no){return}
        let changedRowsSet = new Set(changedRows)
        const diff = data.filter((value, index)=>{
            if(!!value.package){return true}
            if(changedRowsSet.has(index)){return true}
        })
        setLoading(true)
        ProductAPI.upsert_product_packages({
            id: product_id,
            cat_no: cat_no,
            packages: diff
        }).then(value=>{
            if(!value){return}
            setData(value)
            setChangedRows([])
            setChanged(0)
        }).finally(()=>{
            setLoading(false)
        })
    }

    const handleHotChange = (changes, reason) => {
        if(reason==='loadData' || !changes){return}
        setChangedRows(data=> {
            const set = new Set(data)
            changes.forEach(([row, , oldValue, newValue]) => {
                if(oldValue===newValue){return}
                if(set.has(row)){return}
                set.add(row)
            });
            return [...data, ...Array.from(set)]
        })
        setChanged(prev=>prev+1)
    }

    const handleRefresh = ()=>{
        ProductAPI.get_packages({id: product_id})
            .then(data=>setData(data))
            .catch(()=>{})
    }

    return (
        <React.Fragment>
            <Typography variant={'h5'}>产品规格</Typography>
            <Grid container direction={"row"} spacing={1}>
                <Grid item>
                    <Button onClick={handleAdd}>新增</Button>
                </Grid>
                <Grid item>
                    <PackageDeleteDialog hotRef={hot}/>
                </Grid>
                <Grid item>
                    <Button
                        color={"secondary"} disabled={!changed || loading}
                        onClick={handleSave}
                    >保存
                    </Button>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleRefresh}>
                        <RefreshIcon/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item style={{flexGrow: 1}}>
                    <HotTable
                        id="hot"
                        licenseKey={'non-commercial-and-evaluation'}
                        style={{overflowX: 'scroll'}}
                        ref={hot}
                        settings={{
                            height: 200,
                            width: '100%',
                            stretchH:"all",
                            colHeaders: true,
                            manualColumnResize: true,
                            manualColumnMove: true,
                            outsideClickDeselects: false,
                            columns:columns,
                        }}
                        data={data}
                        dateFormat={'YYYY-MM-DD'}
                        afterChange={handleHotChange}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default PackageTable;