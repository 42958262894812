import React from "react";
import {Chip, Stack, Box, Typography} from "@mui/material"


export default function QuoGuideline({pkgManager}){
    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"}><Typography variant={"h6"}>单号: </Typography></Stack>
            <Stack direction={"row"}>
                <Chip label="供应商报价比历史高" color="error"/>
                <Chip label="成交率低" color="warning"/>
                <Chip label="产品成本比供应商低" color="warning"/>
                <Chip label="单次优惠" color="primary"/>
            </Stack>
        </Stack>
    )
}