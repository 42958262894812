import React from 'react';
import {Grid, Typography} from "@mui/material";

const legends = [
    {label: '未提交', className: 'htCellBGYellow'},
    {label: '直接报价', className: 'htCellBGGreen'},
    {label: '已报价', className: 'htCellBGBlue'},
    {label: '已退单', className: 'htCellBGRed'},
    {label: '规格不一致', className: 'htCellBGPurple'},
]

ColorLegend.propTypes = {
    
};

function ColorLegend() {
    return (
        <Grid container spacing={1}>
            {legends.map(item=>(
                <Typography
                    key={item.label} variant={'subtitle2'} className={item.className}
                >{item.label}</Typography>
            ))}
        </Grid>
    );
}

export default ColorLegend;