import React from 'react';
import {
    DialogActions,
    DialogContent,
    Grid, Button
} from "@mui/material";
import {SearchItem} from "./SearchItem";
import { GTTable } from '../types';
import {Column} from "../useFilterTypes";


type Props = {
    table: GTTable
    handleClose?: Function
};

function GTAdvanceSearchForm({table, handleClose=()=>{}}:Props) {
    
    const handleSearch = () => {
        table.refreshData()
        handleClose()
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Grid container direction={"row"}>
                    {table.filter.searchableColumns.map((column: Column, index:number )=>(
                        <Grid item md={12}>
                            <SearchItem
                                key={index}
                                table={table}
                                column={column}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>table.filter.cleanOrdering()}>Clean Ordering</Button>
                <Button color="secondary" onClick={handleSearch}>Search</Button>
                <Button onClick={()=>handleClose()}>OK</Button>
            </DialogActions>
        </React.Fragment>
    );
}

export default GTAdvanceSearchForm;