import React from 'react';
import {AppBar, Grid, IconButton, Toolbar, Typography} from "@mui/material";
import TabsBar from "../../components/Tab/TabsBar";
import {ReactComponent as Logo} from '../../logo.svg'
import {useDispatch} from "react-redux";
import {toggleSideMenu} from "../../redux/frame/frameSlice";
import MenuIcon from '@mui/icons-material/Menu';
import UserInfo from "./UserInfo";

Header.propTypes = {

};

function Header() {
    const dispatch = useDispatch()

    return (
        <AppBar sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar  variant="dense">
                <Grid container>
                    <Grid item xs={2}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={()=>dispatch(toggleSideMenu())}
                                edge="start"
                                sx={{marginRight: '32px',}}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Grid sx={{width: '48px'}}>
                                <Logo/>
                            </Grid>
                            <Grid item>
                                <Typography>Chemhost</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{alignSelf:'end'}} xs={8}>
                        <TabsBar />
                    </Grid>
                    <Grid item xs={2}>
                        <UserInfo />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default Header;