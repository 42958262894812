import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {Backdrop, CircularProgress, Typography} from "@mui/material";
import ExpressAPI from "../../../api/ExpressAPI";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


ExpressOrderViewer.propTypes = {
    data: PropTypes.array,
};

function ExpressOrderViewer(props) {
    const [loading, setLoading] = useState(true)
    const [attachments, setAttachments] = useState([])
    const [numPages, setNumPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)

    const pdfPath = attachments.filter((value)=>(value?.file.endsWith('.pdf')))[0]

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        const {data: {id}} = props;
        if(!id){return}
        setLoading(true)
        ExpressAPI.get_attachments({id}).then(data=>{
            setAttachments(data)
            setLoading(false)
        })
    }, [props.data.id]);

    return (
        <div>
            <Backdrop open={loading} onClick={()=>{}}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            {(pdfPath) ? (
                <React.Fragment>
                    <a href={pdfPath?.file} target="_blank">点击下载</a>
                    <Document file={pdfPath?.file} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                </React.Fragment>
            ):(<Typography>无面单记录</Typography>)}
        </div>
    );
}

export default ExpressOrderViewer;
