import React, {useEffect, useState} from "react";
import {ContactField, CustomerField, DiscountField, SalesField} from "./inputFields";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {endLoading, startLoading} from "../../../redux/globalLoading/globalLoadingSlice";
import CustomerInquiryAPI from "../../../api/CustomerInquiryAPI";


type Field = {
    data: string
    label: string
    disabled?: boolean
    width?: number
    component?: React.ElementType
    default?: string
}

type Values = {
    [key: string]: string
}

const textFields:Field[] = [
    {data: "code", label: "单据号", disabled: true, width: 2},
    {data: "created_by", label: "创建人", disabled: true, width: 1},
    {data: "customer_name", label: "客户名称", component: CustomerField, width: 2},
    {data: "sales_name", label: "销售人员", component: SalesField, width: 2},
    {data: "contact_name", label: "客户联系人", width: 1, component: ContactField},
    {data: "discount", label: "整单折扣", component: DiscountField, width: 1},
    {data: "remark", label: "备注", width: 3},
]
const initialValues = textFields.reduce((dict:Values, item)=> {
    dict[item.data] = item.default || ''
    return dict
}, {})


type Props = {
    inquiry_id?: number
}

// @ts-ignore
export const useInquiry=({inquiry_id}:Props)=>{
    const [changed, setChanged] = useState(0)
    const [summary, setSummary] = useState({})
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            id: null,
            ...initialValues,
            children: []
        },
        onSubmit: () => {},
        onReset: values => {
            values['children'] = []
        }
    })
    const forCreate = !formik.values?.id

    useEffect(()=>{
        if(!inquiry_id){return}
        dispatch(startLoading())
        setChanged(0)
        CustomerInquiryAPI.get({id: inquiry_id})
            .then(data=> {
                if(!data){return}
                formik.setValues(data)
            })
            .finally(()=>{dispatch(endLoading())})
    },[inquiry_id])

    const handleSave=()=>{
        let func
        if(forCreate){
            func = CustomerInquiryAPI.create
        }else{
            func = CustomerInquiryAPI.update
        }
        dispatch(startLoading())
        func(formik.values)
            .then(data=>{
                if(!data){return}
                formik.setValues(data)
                setChanged(0)
            })
            .finally(()=>{dispatch(endLoading())})
    }

    const handleDelete=()=>{
        if(!formik.values.id){return}
        dispatch(startLoading())
        CustomerInquiryAPI.delete(formik.values.id)
        // .then(()=>handleClose())  TODO close current tab
            .finally(()=>dispatch(endLoading()))
    }

    useEffect(()=>{
        const {children} = formik.values
        // @ts-ignore
        const validDiscounts = children?.map(item=>parseFloat(item.quotation?.discount))?.filter(item=>!!item)
        setSummary({
            count: children?.length - 1,
            // @ts-ignore
            sum_quantity: children?.map(item=>item.quantity).filter(item=>!!item).reduce((a, b)=>parseInt(a)+parseInt(b), 0),
            // @ts-ignore
            sum_offer_price: children?.map(item=>item.quantity * item.quotation?.offer_price).filter(item=>!!item).reduce((a,b)=>a+b, 0),
            // @ts-ignore
            count_inter_quo: children?.filter(item=>!!item?.inter_quo?.id).length,
            // @ts-ignore
            count_closed_inter_quo: children?.filter(item=>item?.inter_quo?.status==='已报价').length,
            avg_discount: validDiscounts?.reduce((a,b)=>(a+b), 0) / validDiscounts?.length
        })
    }, [changed, formik.values.id])


    return {
        formik,
        changed,
        setChanged,
        summary,
        handleSave,
        handleDelete,
    }
}

export default useInquiry