import Handsontable from "handsontable";


export const U8AuditStateRenderer = (instance, td, row, col, prop, value, cellProperties)=>{
    let ret = ''
    switch (value) {
        case 0:
            ret = "开立"
            break;
        case 1:
            ret = "提交"
            break;
        case 2:
            ret = "审核"
            break;
    }
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, ret, cellProperties])
}